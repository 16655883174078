<nscf-breadcrumb *ngIf="data.breadcrumb && isDesktop" [data]="data.breadcrumb" [isDesktop]="isDesktop"></nscf-breadcrumb>
<div class="container tw-mb-8 slg:tw-mt-[80px]">
  <div class="row">
    <div class="col-12 col-lg-8 mx-auto">
      <h1 class="guide__title">{{ data?.title }}</h1>
      <p class="guide__description">{{ data?.chapo }}</p>
    </div>
    <div class="col-12 col-sm-12 col-lg-6 guide__cell" *ngFor="let chapter of data?.chapters">
      <nscf-guide-chapter [chapter]="chapter"> </nscf-guide-chapter>
    </div>
  </div>
</div>
<nscf-breadcrumb *ngIf="data.breadcrumb && !isDesktop" [data]="data.breadcrumb" [isDesktop]="isDesktop"></nscf-breadcrumb>
