@if (data?.isActive) {
  <div class="tw-max-w-[1110px] tw-mx-auto tw-box-content tw-min-w-[1px] tw-w-full">
    <div class="newsletter">
      <div class="newsletter__picture tw-bg-shiny-yellow tw-relative smd:tw-w-[440px] smd:tw-min-w-[400px]">
        <picture>
          <source
            media="(min-width: 992px)"
            srcset="assets/backgrounds/newsletter-fairy.webp 1x, assets/backgrounds/newsletter-fairy-2x.webp 2x"
          />
          <img
            loading="lazy"
            src="assets/backgrounds/newsletter-fairy-mobile.webp"
            class="tw-mx-auto tw-w-auto tw-h-[7.8125rem] smd:tw-h-[90%] smd:tw-bottom-0 smd:tw-max-w-none smd:tw-absolute smd:tw-object-cover"
            alt="Exemples de newsletter"
          />
        </picture>
      </div>

      <div class="newsletter__content">
        <h2 class="tw-text-xl smd:tw-text-3xl"><strong>L'Éclaireuse</strong> : Le guide pour transformer votre maison !</h2>
        <p class="tw-my-4 smd:tw-my-6 tw-text-xs smd:tw-text-lg">
          Des conseils pratiques, des actus utiles et des solutions pour mieux vivre chez vous.
        </p>

        <div>
          @if (isSuccessfullySubscribed) {
            <div class="submitted-message">
              <p>Votre inscription est bien enregistrée. Merci et à bientôt !</p>
            </div>
          } @else {
            <form class="newsletter__form" [formGroup]="emailFG">
              <mat-form-field class="tw-grow">
                <mat-label>Votre adresse mail*</mat-label>
                <input matInput [formControl]="emailFG.controls.email" />
                @if (emailFG.controls.email.hasError('required')) {
                  <mat-error> L'adresse email est obligatoire </mat-error>
                }
                @if (emailFG.controls.email.hasError('pattern')) {
                  <mat-error> Entrez une adresse email valide </mat-error>
                }
              </mat-form-field>
              <button
                effy-button
                class="newsletter__button"
                [color]="buttonStyle"
                [disabled]="isLoading"
                [size]="buttonSize"
                (click)="subscribeNewsletter()"
              >
                Je m'abonne
              </button>
            </form>
          }
        </div>
        <p class="tw-text-xs tw-font-600 tw-my-4 smd:tw-mb-6">Un seul email par mois et la possibilité de se désabonner à tout moment !</p>
        <div class="social-network">
          <p class="social-network-text">
            Suivez nous également<br />
            sur nos réseaux sociaux
          </p>
          <div class="social-network-icons">
            @for (item of newsletterNetworkConfig; track $index) {
              <a [href]="item.href" target="_blank">
                <img [src]="item.src" width="36" height="36" [attr.alt]="item.alt" loading="lazy" />
              </a>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}
