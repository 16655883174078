import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { ILink } from '../../interfaces/link';
import { IRteText } from '../../interfaces/rte-text';
import { Parcours } from '../../utils/parcours/parcours.utils';
import { IFormParcours } from '../form-parcours/form-parcours';

export const TypeNameBlocTopPageForm = 'ParagraphTopPageForm';

export interface IBlocTopPageForm {
  __typename: string;
  title: string;
  enableTrustPilot: boolean;
  desktopDescription: IRteText;
  mobileDescription: IRteText;
  image: IFieldMediaImage;
  formParcours: IFormParcours;
  parcours: Parcours;
  hasTwoQuestions: boolean;
  cta: ILink | null;
}
