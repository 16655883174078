import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LiseretComponent } from '../../liseret/liseret.component';
import { CardsDiscoveryCategorieComponent } from './cards-discovery-categorie/cards-discovery-categorie.component';
import { IDiscoveryCategorie } from './discovery-categorie';

@Component({
  selector: 'nscf-discovery-categorie',
  templateUrl: './discovery-categorie.component.html',
  styleUrls: ['./discovery-categorie.component.scss'],
  standalone: true,
  imports: [LiseretComponent, NgClass, CardsDiscoveryCategorieComponent],
  host: {
    class: 'container discovery-categorie',
  },
})
export class DiscoveryCategorieComponent {
  @Input()
  data: IDiscoveryCategorie;
}
