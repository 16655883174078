import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { EffyLinkComponent, EffyLinkSize, EffyLinkStyle } from '@effy-tech/angular-common';
import { Observable } from 'rxjs';
import { map, share, take } from 'rxjs/operators';
import { DeviceManagerService, DeviceType } from '../../../../services/device-manager/device-manager.service';
import { IColorCardsDiscovery } from '../discovery-categorie';
import { ICardsDiscoveryCategorie } from './cards-discovery-categorie';

@Component({
  selector: 'nscf-cards-discovery-categorie',
  templateUrl: './cards-discovery-categorie.component.html',
  styleUrls: ['./cards-discovery-categorie.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass, AsyncPipe, EffyLinkComponent, MatIcon],
  host: {
    ngSkipHydration: 'true',
  },
})
export class CardsDiscoveryCategorieComponent implements OnInit {
  @Input()
  cards: ICardsDiscoveryCategorie;

  @Input()
  color: IColorCardsDiscovery;

  private readonly deviceManagerService = inject(DeviceManagerService);
  isDesktop$: Observable<boolean> = this.deviceManagerService.deviceType$().pipe(
    map((deviceType: DeviceType) => deviceType.isDesktop),
    take(1),
    share()
  );
  linkStyle = EffyLinkStyle;
  linkSize = EffyLinkSize;

  linkText$: Observable<string> = this.isDesktop$.pipe(map((isDesktop: boolean) => this.computeLinkText(isDesktop)));

  classColor: 'white' | 'blue';

  @HostBinding('class') get HeadingClass(): string {
    return `cards-discovery-categorie cards-discovery-categorie--${this.classColor}`;
  }

  ngOnInit(): void {
    this.classColor = this.color === IColorCardsDiscovery.BLUE ? 'blue' : 'white';
  }

  private computeLinkText(isDesktop: boolean): string {
    return isDesktop ? 'En savoir plus' : 'Lire';
  }
}
