@if (data) {
  @if (data.title) {
    <nscf-title [id]="'anchor_' + data.id" [selector]="data.isFirstTitle ? 'h1' : 'h2'" [title]="data.title" class="faq__title" />
    <div class="liseret"></div>
  }
  <nscf-rte [data]="data.chapo" class="faq__chapo" />
  <effy-collapse *ngFor="let content of data.content" [title]="content.question" [size]="size()">
    <nscf-rte *ngIf="content.answer" [data]="content.answer" class="faq__rte" />
  </effy-collapse>
}
