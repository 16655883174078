<div *ngIf="data" class="medias">
  <div class="container">
    @if (data.title) {
      <nscf-title
        [id]="'anchor_' + data.id"
        [selector]="data.isFirstTitle ? 'h1' : 'h2'"
        [title]="data.title"
        class="text-center"
      ></nscf-title>
      <nscf-liseret class="medias__liseret"></nscf-liseret>
    }
    <nscf-rte [data]="data.chapo" class="medias__chapo"></nscf-rte>
  </div>
  <div [ngClass]="{ 'medias__carousel-wrapper--centered': data.cards.length < 4 }" class="medias__carousel-wrapper">
    <nscf-carousel *ngIf="data.display === 'carrousel'" [cardHeight]="410" [hidden]="!isBrowser" [mediasCarouselItems]="data.cards">
      <ng-template let-slide="slide" let-stepNumber="stepNumber">
        <nscf-medias-card [slide]="slide" [stepNumber]="stepNumber"></nscf-medias-card>
      </ng-template>
    </nscf-carousel>
    <nscf-medias-cardification
      *ngIf="data.display === 'cardification'"
      [cards]="data.cards"
      [filters]="data.filters"
    ></nscf-medias-cardification>
  </div>
</div>
