import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LazyLoadStyleService {
  loadedStyles: { [url: string]: ReplaySubject<boolean> } = {};

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  load$(url: string): Observable<boolean> {
    if (this.loadedStyles[url]) {
      return this.loadedStyles[url].asObservable();
    }

    this.loadedStyles[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this.loadedStyles[url].next(true);
      this.loadedStyles[url].complete();
    };

    this.document.head.insertBefore(style, this.document.head.firstElementChild);

    return this.loadedStyles[url].asObservable();
  }

  loadMaterial$(): Observable<boolean> {
    return this.load$('/material-18.2.css').pipe(take(1));
  }
}
