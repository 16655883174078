import { Injectable } from '@angular/core';
import {
  ConfigService,
  FooterService,
  ICguLink,
  INewFooter,
  Site,
  ctaSubject,
  getMythicInscriptionUrl,
  getSiteUrl,
  getSocialLinks,
  socialsLinkText,
} from '@effy-tech/acquisition-core';
import { WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EffyEnvironment, EffyIcons } from '@effy-tech/angular-common';

@Injectable({
  providedIn: 'root',
})
export class EffyFooterService extends FooterService {
  constructor(private readonly configService: ConfigService) {
    super();
  }

  getContent(isPro = false): INewFooter {
    const env = this.configService.getEnv();
    return isPro ? this.getProContent(env) : this.getPartContent(env);
  }

  private getProContent(env: EffyEnvironment): INewFooter {
    return {
      logo: {
        url: 'https://www.effy.fr/common-assets/images/logo-effy-blanc.svg',
        alt: 'logo site Effy',
      },
      title: 'N°1 de la rénovation <span class="d-block">énergétique en ligne<span>',
      ctaSubject: 'Devenez Partenaire Effy <span class="d-block">et simplifiez-vous la vie !<span>',
      cta: {
        url: getMythicInscriptionUrl(env),
        title: 'Devenir partenaire',
      },
      siteLogo: {
        title: 'Les sites du groupe Effy',
        logos: [
          {
            url: 'assets/new-footer/logo_quelleenergie_white.svg',
            alt: 'Logo Quelle Énergie',
            link: getSiteUrl(Site.QE, env),
            site: WebsiteThemes.qe,
            icon: EffyIcons.QUELLEENERGIE_WHITE_LOGO,
          },
          {
            url: 'assets/new-footer/logo_lpe_white.svg',
            alt: 'Logo La Prime Énergie',
            link: getSiteUrl(Site.LPE, env),
            site: WebsiteThemes.lpe,
            icon: EffyIcons.LAPRIMEENERGIE_WHITE_LOGO,
          },
          {
            url: 'assets/new-footer/logo_calculeo_white.svg',
            alt: 'Logo Calculeo',
            link: getSiteUrl(Site.CALCULEO, env),
            site: WebsiteThemes.calculeo,
            icon: EffyIcons.CALCULEO_WHITE_LOGO,
          },
        ],
      },
      columns: [
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Nos services',
            },
            {
              mainLink: false,
              link: 'Chantiers clés en main',
              url: '/pro/offre-serenite',
            },
            {
              mainLink: false,
              link: 'Rendez-vous qualifiés',
              url: '/pro/offre-liberte',
            },
            {
              mainLink: false,
              link: 'Accompagnement prime CEE',
              url: '/pro/offre-simplicite',
            },
            {
              mainLink: false,
              link: `Décryptage de l'actualité`,
              url: '/pro/actualite',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Nos conseils',
            },
            {
              mainLink: false,
              link: 'Gestion de votre entreprise',
              url: '/pro/guide-pratique/gestion-entreprise',
            },
            {
              mainLink: false,
              link: 'Développement de votre activité',
              url: '/pro/guide-pratique/conseils-developpement-activite',
            },
            {
              mainLink: false,
              link: 'Qualifications',
              url: '/pro/guide-pratique/label-rge',
            },
            {
              mainLink: false,
              link: 'Réglementation',
              url: '/guide-pratique/reglementation',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'À propos',
            },
            {
              mainLink: false,
              link: 'Qui sommes-nous ?',
              url: '/nous-connaitre',
            },
          ],
        },
      ],
      socialsLink: {
        text: socialsLinkText,
        links: getSocialLinks(true),
      },
      cguLinks: this.getCguLinks(),
      banner: {
        title: 'Vous êtes un particulier souhaitant rénover son logement ?',
        description: 'Découvrez nos offres particulier sur le site Effy',
        cta: {
          url: '/',
          title: 'Découvrir les offres particuliers',
        },
      },
    };
  }

  private getPartContent(env: EffyEnvironment): INewFooter {
    return {
      logo: {
        url: 'https://www.effy.fr/common-assets/images/logo-effy-blanc.svg',
        alt: 'logo site Effy',
      },
      title: 'N°1 de la rénovation énergétique en ligne',
      ctaSubject: ctaSubject,
      cta: {
        url: '/parcours/1/travaux-aides',
        title: 'Demander un devis',
      },
      siteLogo: {
        title: 'Les sites du groupe Effy',
        logos: [
          {
            url: 'assets/new-footer/logo_quelleenergie_white.svg',
            alt: 'Logo Quelle Énergie',
            link: getSiteUrl(Site.QE, env),
            site: WebsiteThemes.qe,
            icon: EffyIcons.QUELLEENERGIE_WHITE_LOGO,
          },
          {
            url: 'assets/new-footer/logo_lpe_white.svg',
            alt: 'Logo La Prime Énergie',
            link: getSiteUrl(Site.LPE, env),
            site: WebsiteThemes.lpe,
            icon: EffyIcons.LAPRIMEENERGIE_WHITE_LOGO,
          },
          {
            url: 'assets/new-footer/logo_calculeo_white.svg',
            alt: 'Logo Calculeo',
            link: getSiteUrl(Site.CALCULEO, env),
            site: WebsiteThemes.calculeo,
            icon: EffyIcons.CALCULEO_WHITE_LOGO,
          },
        ],
      },
      columns: [
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Le chauffage',
              url: '/guide/chauffage',
            },
            {
              mainLink: false,
              link: 'Pompe à chaleur',
              url: '/guide/chauffage/pompe-a-chaleur',
            },
            {
              mainLink: false,
              link: 'Chaudière',
              url: '/guide/chauffage/chaudiere',
            },
            {
              mainLink: false,
              link: 'Poêle',
              url: '/guide/chauffage/poele',
            },
            {
              mainLink: false,
              link: 'Chauffe-eau',
              url: '/guide/chauffage/chauffe-eau',
            },
            {
              mainLink: false,
              link: 'Radiateur',
              url: '/guide/chauffage/radiateur',
            },
            {
              mainLink: false,
              link: 'Climatisation',
              url: '/guide/chauffage/climatisation',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: `L'isolation`,
              url: '/guide/isolation',
            },
            {
              mainLink: false,
              link: 'Isolation des combles',
              url: '/guide/isolation/combles',
            },
            {
              mainLink: false,
              link: 'Isolation des murs',
              url: '/guide/isolation/murs',
            },
            {
              mainLink: false,
              link: 'Isolation du sol',
              url: '/guide/isolation/sol',
            },
            {
              mainLink: false,
              link: 'Isolation du toit',
              url: '/guide/isolation/toiture-terrasse',
            },
            {
              mainLink: false,
              link: 'Les fenêtres',
              url: '/guide/isolation/fenetres',
            },
            {
              mainLink: false,
              link: 'La VMC',
              url: '/guide/isolation/vmc',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Rénovation globale & aides',
              url: '/guide/renovation-globale'
            },
            {
              mainLink: false,
              link: `MaPrimeRenov'`,
              url: '/guide/aides-financieres/ma-prime-renov',
            },
            {
              mainLink: false,
              link: 'Prime Effy (CEE)',
              url: '/guide/aides-financieres/prime-effy',
            },
            {
              mainLink: false,
              link: 'Chèque énergie',
              url: '/guide/aides-financieres/cheque-energie',
            },
            {
              mainLink: false,
              link: 'Aides locales',
              url: '/guide/aides-financieres/aides-locales',
            },
            {
              mainLink: false,
              link: 'Aides financières',
              url: '/guide/aides-financieres',
            },
            {
              mainLink: false,
              link: 'DPE et audit énergétique',
              url: '/guide/renovation-globale/dpe-audit-energetique',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Solaire',
              url: '/guide/solaire',
            },
            {
              mainLink: false,
              link: 'Tous les panneaux',
              url: '/guide/solaire/panneaux-solaires'
            },
            {
              mainLink: false,
              link: 'Panneaux photovoltaïques',
              url: '/guide/solaire/panneaux-solaire-photovoltaique',
            },
            {
              mainLink: false,
              link: 'Chauffe-eau solaire',
              url: '/guide/solaire/chauffe-eau-solaire',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Avis de nos clients',
              url: '/avis-effy',
            },
            {
              mainLink: false,
              link: 'Témoignages Solaire',
              url: '/temoignages-clients-solaire',
            },
            {
              mainLink: false,
              link: 'Témoignages Isolation',
              url: '/temoignages-clients-isolation',
            },
            {
              mainLink: false,
              link: 'Témoignages Chauffage',
              url: '/temoignages-clients-chauffage',
            },
          ],
        },
        {
          columnLinks: [
            {
              mainLink: true,
              link: 'Qui sommes-nous',
            },
            {
              mainLink: false,
              link: 'Nous connaître',
              url: '/nous-connaitre',
            },
            {
              mainLink: false,
              link: 'Notre mission',
              url: '/nous-connaitre/notre-mission',
            },
            {
              mainLink: false,
              link: 'Nos engagements',
              url: '/nous-connaitre/nos-engagements',
            },
            {
              mainLink: false,
              link: 'Notre équipe',
              url: '/nous-connaitre/notre-equipe',
            },
            {
              mainLink: false,
              link: 'Ils parlent de nous',
              url: '/nous-connaitre/notre-espace-presse',
            },
            {
              mainLink: false,
              link: 'Nous rejoindre !',
              url: '/nous-connaitre/rejoignez-nous',
            },
          ],
        },
      ],
      socialsLink: {
        text: socialsLinkText,
        links: getSocialLinks(),
      },
      cguLinks: this.getCguLinks(),
      banner: {
        title: 'Vous êtes un artisan RGE ? ',
        description: 'Devenez partenaire Effy, visitez notre espace dédié aux artisans',
        cta: {
          url: '/pro',
          title: 'Découvrir l’espace Pro',
        },
      },
    };
  }

  private getCguLinks(): ICguLink[] {
    return [
      {
        text: 'Plan du site',
        url: '/guide/plan-du-site'
      },
      {
        text: 'Mentions légales',
        url: '/mentions-legales',
      },
      {
        text: 'CG Partenaires',
        url: '/conditions-generales-acces-reseau-partenaires-effy',
      },
      {
        text: 'CGV',
        url: '/conditions-generales-vente',
      },
      {
        text: `CGU`,
        url: '/cgu',
      },
      {
        text: 'Vie Privée',
        url: '/vie-privee',
      },
      {
        text: 'Politique des cookies',
        url: '/politique-des-cookies',
      },
    ];
  }
}
