<div class="carousel-card" [id]="slide.id">
  <!-- Player wrapper / image call -->
  @if (slide.video) {
    <div class="carousel-card__player-wrapper" [ngClass]="{ loaded: isMediaLoaded() }" (click)="loadMedia($event)">
      <!-- Image call -->
      @if (!isMediaLoaded() && slide.videoCover) {
        <img loading="lazy" [alt]="slide.videoCover?.alt || ''" [src]="slide.videoCover.url | safeResourceUrl" />
      }
      <!-- Iframe media -->
      @if (loadedMedias) {
        @if (slide.videoCover) {
          <iframe
            [id]="'player-' + stepNumber"
            [src]="url | safeResourceUrl"
            [title]="slide.videoCover?.title || ''"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen
          >
          </iframe>
        } @else {
          <iframe
            [id]="'player-' + stepNumber"
            [src]="url | safeResourceUrl"
            [title]="slide.videoCover?.title || ''"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            allowfullscreen
            loading="lazy"
          >
          </iframe>
        }
      }
    </div>
  }

  @if (!slide.video && slide.videoCover) {
    <img loading="lazy" [alt]="slide.videoCover?.alt || ''" [src]="slide.videoCover.url | safeResourceUrl" />
  }

  <div class="carousel-card__content">
    <!-- Description / article -->
    @if (slide.description) {
      <div class="carousel-card__description" [ngClass]="clampingClassByIndex" [innerHTML]="slide.description.value | safeHtml"></div>
    }

    <!-- PDF or external link -->
    @if (slide.pdf || slide.url) {
      <a [href]="slide.pdf?.url || slide.url" class="carousel-card__link" target="_blank">Lire l'article</a>
    }

    <!-- Informations about the media -->
    @if (slide.picto || slide.name || slide.date) {
      <div class="carousel-card__media">
        <div class="media__logo">
          @if (slide.picto) {
            <img loading="lazy" [src]="slide.picto.url" [alt]="slide.name" />
          }
        </div>
        <div class="media__infos">
          <span class="media__name">{{ slide.name }}</span>
          <span class="media__date">{{ slide.date }}</span>
        </div>
      </div>
    }
  </div>
</div>
