import { AsyncPipe, NgClass, SlicePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { EffyEPatternComponent, EPatternSize, EPatternStyle } from '@effy-tech/angular-common';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { map } from 'rxjs';
import { CarouselContainer } from '../../classes/carousel-container/carousel-container';
import { ICardSummarySimplified } from '../../interfaces/cards-summary';
import { ITaxonomy } from '../../interfaces/taxonomy';
import { CardificationService } from '../../services/cardification/cardification.service';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { ArticleCardSmallComponent } from '../article-card-small/article-card-small.component';
import { CardSummarySimplifiedComponent } from '../card-summary-simplified/card-summary-simplified.component';

@Component({
  selector: 'nscf-cards-summary',
  templateUrl: 'cards-summary.component.html',
  styleUrls: ['cards-summary.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    CarouselModule,
    AsyncPipe,
    SlicePipe,
    CardSummarySimplifiedComponent,
    ArticleCardSmallComponent,
    EffyEPatternComponent,
  ],
})
export class CardsSummaryComponent extends CarouselContainer implements OnInit {
  @Input() data: {
    taxonomies: Array<ITaxonomy>;
    cards: ICardSummarySimplified[];
  };
  @Input() isBrowser: boolean;
  isSiteEffy: boolean;
  readonly isDesktop$ = this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.isDesktop));
  protected readonly ePatternStyle = EPatternStyle.WHITE;
  protected readonly ePatternSize = EPatternSize.NORMAL;

  constructor(
    private readonly cardificationService: CardificationService,
    protected readonly deviceManagerService: DeviceManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }
    this.cardificationService.getCardificationByType$(this.data.cards, this.data.taxonomies).subscribe(cards => {
      super.setCarouselOptions();
      super.setCarouselItems(cards);
    });
  }
}
