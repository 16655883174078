import { NgClass, NgFor, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EffyTileComponent, EffyTileInputType, EffyTileSize } from '@effy-tech/angular-common';
import { CtaActionDirective } from '../../directives/cta-action/cta-action.directive';
import { ImagesService } from '../../services/images-service/images.service';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { IFormSTPCard, ISuperTopPage } from './super-top-page';
@Component({
  selector: 'nscf-super-top-page',
  templateUrl: 'super-top-page.component.html',
  styleUrls: ['super-top-page.component.scss'],
  standalone: true,
  imports: [NgClass, TitleComponent, NgFor, CtaActionDirective, NgStyle, TrustpilotMicroStarComponent, EffyTileComponent],
  host: {
    class: 'container',
  },
})
export class SuperTopPageComponent implements OnInit {
  @Input() data: ISuperTopPage;
  @Input() bigViewPort: boolean;
  @Input() isBrowser: boolean;
  urlCover: any;
  displayScoring: boolean;
  formGroup: FormGroup;

  readonly tileSize = EffyTileSize.SMALL;
  readonly tileType = EffyTileInputType.RADIO;

  get cardControls(): FormControl[] {
    return (this.formGroup.get('cards') as FormArray).controls as FormControl[];
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({});
    this.data.formCards.forEach((card, index) => {
      this.formGroup.addControl('card' + index, new FormControl(null));
    });

    this.urlCover = ImagesService.setBackGroundCoverUrl(this.data.mediaCover);
  }

  redirectForm(evt: any, card: IFormSTPCard): void {
    if (card.url) {
      setTimeout(() => {
        window.location.href = card.url.url;
      }, 500);
    }
  }
}
