import { Component, Input } from '@angular/core';
import { EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common';
import { CtaActionDirective } from '../../../directives/cta-action/cta-action.directive';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { ISubBlockCTA } from './sub-block-cta';

@Component({
  selector: 'nscf-sub-block-cta',
  templateUrl: 'sub-block-cta.component.html',
  styleUrls: ['sub-block-cta.component.scss'],
  standalone: true,
  imports: [CtaActionDirective, CtaButtonComponent],
  host: {
    class: 'container',
  },
})
export class SubBlockCtaComponent {
  @Input() element: ISubBlockCTA;
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonStyle = EffyButtonStyle;
}
