@if (navbar) {
  <nav aria-label="Main" class="header-navbar">
    <ul class="header-navbar__links">
      @for (link of navbar?.links; track link; let index = $index) {
        <li class="header-navbar__item" (mouseover)="loadImages()">
          <nscf-header-link [index]="index" [link]="link" [isDesktop]="isDesktop" (checked)="onChecked($event)"></nscf-header-link>
        </li>
      }
    </ul>
    @if (!isDesktop && !isSubMenuMobileOpened) {
      <nscf-connection
        [connection]="connection"
        [scrollOverHeight]="scrollOverHeight()"
        [theme]="theme"
        class="tw-block tw-mb-4"
      ></nscf-connection>
      @if (linkPart) {
        <nscf-header-cta-button [btnClass]="'cta-primary big-btn'" [ctaLink]="linkPart"></nscf-header-cta-button>
      }
      <nscf-new-phone-block [phoneInsert]="phoneInsert" [isDesktop]="false"></nscf-new-phone-block>
    }
  </nav>
}
