import { AsyncPipe, DOCUMENT, NgClass, NgTemplateOutlet, SlicePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren, computed, effect, inject, signal } from '@angular/core';
import { map, mergeMap, of, retry, take, throwError, timer } from 'rxjs';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { TopPageMosaicCarouselComponent } from './carousel/carousel.component';
import { ITopPageMosaic } from './top-page-mosaic';

@Component({
  selector: 'nscf-top-page-mosaic',
  standalone: true,
  templateUrl: './top-page-mosaic.component.html',
  styleUrls: ['./top-page-mosaic.component.scss'],
  imports: [AsyncPipe, TopPageMosaicCarouselComponent, NgClass, SlicePipe, NgTemplateOutlet, TrustpilotMicroStarComponent],
})
export class TopPageMosaicComponent implements OnInit {
  @Input() data: ITopPageMosaic;
  photosSrc: string[] = [];
  isCarouselLengthFive: boolean = false;
  isSinglePicture: boolean = false;
  selectedImageIndex = 0;
  @ViewChildren('carouselImages') set carouselImages(carouselImages: QueryList<ElementRef<HTMLImageElement>>) {
    this._carouselImages = carouselImages;
    this.waitForImageAndScroll();
  }
  readonly openedCarousel = signal(false);
  readonly bodyOverflow = computed<'auto' | 'hidden'>(() => (this.openedCarousel() ? 'hidden' : 'auto'));

  readonly isMobileOrTablet$ = inject(DeviceManagerService).isMobileOrTablet();
  private readonly document = inject(DOCUMENT);
  private _carouselImages: QueryList<ElementRef<HTMLImageElement>>;

  constructor() {
    effect(() => (this.document.body.style.overflow = this.bodyOverflow()));
  }

  ngOnInit(): void {
    this.isCarouselLengthFive = this.data.carousel.length === 5;
    this.isSinglePicture = this.data.carousel.length === 1;
    for (const image of this.data.carousel) {
      this.photosSrc.push(image.url);
    }
  }

  // Wait for image to be defined and scroll to it
  private waitForImageAndScroll() {
    if (!this._carouselImages || !this._carouselImages.length) {
      return;
    }
    timer(200)
      .pipe(
        map(() => this._carouselImages.get(this.selectedImageIndex)),
        mergeMap(image => (image ? of(image) : throwError(() => 'image not defined'))),
        retry(5),
        take(1)
      )
      .subscribe(image => image.nativeElement?.scrollIntoView({ behavior: 'smooth', inline: 'center' }));
  }

  openCarouselOnImageIndex(index: number) {
    this.selectedImageIndex = this.mod(index, this.data.carousel.length);
    this.openedCarousel.set(true);
  }

  selectImageIndex($event: MouseEvent, index: number) {
    if ($event) {
      // Prevent overlay to receive click event and to close
      $event.stopPropagation();
    }
    // If user clicks on current image, select next image
    if (index === this.selectedImageIndex) {
      index = index + 1;
    }
    this.selectedImageIndex = this.mod(index, this.data.carousel.length);
    this._carouselImages.get(this.selectedImageIndex)?.nativeElement?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
  }

  // Necessary to always have a positive number (i.e. mod(-1, 5) is equal to 4 when -1 % 5 is equal to -1)
  private mod(n: number, m: number) {
    return ((n % m) + m) % m;
  }
}
