import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { EffyLinkComponent, EffyLinkSize } from '@effy-tech/angular-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICardSummary } from '../../../interfaces/cards-summary';
import { IMagazineArticle } from '../../../interfaces/magazine-block';
import { CardificationService } from '../../../services/cardification/cardification.service';
import { ArticleCardSmallComponent } from '../../article-card-small/article-card-small.component';

export enum DefaultThemesActu {
  ACTUALITE = 'Actualité',
  LA_UNE = 'A la une',
  MAG_ACTUALITE = 'MAG - Actualité',
  ACTUALITY_PRO = 'PRO - Actus pros',
  COMMUNIQUE_PRESSE = 'Communiqués de presse',
}

enum URL_MAGAZINE {
  PART = '/magazine',
  PRO = '/pro/actualite',
}

@Component({
  selector: 'nscf-magazine-block',
  templateUrl: './magazine-block.component.html',
  styleUrls: ['./magazine-block.component.scss'],
  standalone: true,
  imports: [AsyncPipe, ArticleCardSmallComponent, EffyLinkComponent, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'container',
  },
})
export class MagazineBlockComponent implements OnInit {
  private readonly cardificationService = inject(CardificationService);
  private readonly router = inject(Router);

  @Input() data: {
    showBlockMagazinePro: boolean;
  };
  articles$: Observable<Array<IMagazineArticle>>;
  urlMagazine = URL_MAGAZINE.PART;
  linkSize = EffyLinkSize;

  ngOnInit() {
    this.articles$ = this.cardificationService
      .getNewsCards(this.getMagazineThemes(this.data.showBlockMagazinePro), { isNews: true })
      .pipe(map(cardsActu => this.mapCardsActusToMagazineBlock(cardsActu, this.router.url)));

    this.urlMagazine = this.data.showBlockMagazinePro ? URL_MAGAZINE.PRO : URL_MAGAZINE.PART;
  }

  getMagazineThemes(showBlockMagazinePro: boolean): Array<DefaultThemesActu> {
    return showBlockMagazinePro ? [DefaultThemesActu.ACTUALITY_PRO] : [DefaultThemesActu.MAG_ACTUALITE];
  }

  mapCardsActusToMagazineBlock(cardsActus: Array<ICardSummary>, url: string): Array<IMagazineArticle> {
    const articles = cardsActus.map(
      (card: ICardSummary) =>
        <IMagazineArticle>{
          title: card.title,
          url: card.link.url,
          mediaCover: card.wideCoverWebp,
          taxonomies: card.taxonomies,
        }
    );

    return this.isCurrentURLOnMagazineBlock(articles.slice(0, 3), url);
  }

  private isCurrentURLOnMagazineBlock(articles: Array<IMagazineArticle>, currentUrl: string): Array<IMagazineArticle> {
    return articles.filter(article => article.url != currentUrl);
  }
}
