<div
  class="tw-flex tw-flex-col tw-rounded-lg md:tw-rounded-xl md:tw-flex-row tw-overflow-hidden tw-w-[312px] md:tw-w-full tw-max-w-[90vw] xs:tw-mx-auto"
  [ngClass]="[element().color ? 'focus--' + element().color : '', element().location ? 'focus--' + element().location : '']"
>
  <div class="md:tw-shrink-0 md:tw-w-[268px]">
    <img
      loading="lazy"
      [attr.width]="isDesktop() ? '268' : undefined"
      [attr.height]="isDesktop() ? undefined : '164'"
      [src]="element().image?.url"
      [alt]="element().image?.alt"
      class="tw-object-cover tw-w-full tw-h-[164px] md:tw-aspect-[1/1] md:tw-h-full"
    />
  </div>
  <div class="tw-p-6 md:tw-flex md:tw-flex-col md:tw-justify-between md:tw-grow">
    <nscf-rte class="tw-block" [data]="element().description" />
    @if (element().ctaLabel) {
      <nscf-cta-button
        class="tw-mt-6 md:tw-mt-8 tw-w-fit"
        [withArrow]="true"
        [text]="element().ctaLabel"
        [ctaUrl]="element().ctaUrl"
        [effyStyle]="buttonColor()"
        [style.--effy-button-width]="isDesktop() ? undefined : '100%'"
      />
    }
  </div>
</div>
