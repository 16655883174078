import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EffyHashtagComponent, EffyHashtagSize, EffyHashtagStyle } from '@effy-tech/angular-common';
import { LiseretComponent } from '../../liseret/liseret.component';
import { IFocusCategorie } from './focus-categorie';

@Component({
  selector: 'nscf-focus-categorie',
  templateUrl: './focus-categorie.component.html',
  styleUrls: ['./focus-categorie.component.scss'],
  standalone: true,
  imports: [LiseretComponent, NgClass, EffyHashtagComponent],
})
export class FocusCategorieComponent {
  @Input()
  data: IFocusCategorie;

  hashtagSizeMedium = EffyHashtagSize.MEDIUM;
  hashtagStylePrimary = EffyHashtagStyle.PRIMARY;
}
