import { SlicePipe, isPlatformBrowser } from '@angular/common';
import { Component, Input, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { assign } from 'lodash-es';
import { ITaxonomy } from '../../../interfaces/taxonomy';

@Component({
  selector: 'nscf-article-theme',
  templateUrl: 'theme.component.html',
  styleUrls: ['theme.component.scss'],
  standalone: true,
  imports: [SlicePipe],
  host: {
    ngSkipHydration: 'true',
  },
})
export class ArticleThemeComponent implements OnInit {
  @Input({ required: true }) data: Array<ITaxonomy>;
  @Input() isInPageArticle = false;

  themeKeys: Array<string>;
  nbMaxItemDisplayedAtFirst = 3;
  showAllItems: boolean;
  private readonly platformId = inject(PLATFORM_ID);
  private readonly isBrowser = isPlatformBrowser(this.platformId);

  ngOnInit(): void {
    if (this.data) {
      assign(this.data, {});
      this.themeKeys = Object.keys(this.data);
      this.themeKeys.pop();
    }
    if (!this.isBrowser) {
      this.showAllItems = true;
    }
  }

  navigateToHub(target: string): void {
    const el = document.getElementById(target);
    if (el) {
      el.click();
    }
  }
}
