import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CAMPAIGN_COOKIE_NAME, UTM_LABELS } from './utm-campaign';

@Component({
  selector: 'nscf-utm-campaign',
  template: '',
  standalone: true,
})
export class UtmCampaignComponent implements OnInit {
  $event: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly cookieService: CookieService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params[UTM_LABELS.source]) {
        const computedUtmKeyValues = [];
        // Créer un cookie pour stocker les infos utm
        Object.keys(UTM_LABELS).forEach(key => {
          // add in the URL only if the key exists
          if (params[UTM_LABELS[key]]) {
            computedUtmKeyValues.push(UTM_LABELS[key] + '=' + params[UTM_LABELS[key]]);
          }
        });
        this.cookieService.set(CAMPAIGN_COOKIE_NAME, computedUtmKeyValues.join('&'), this.getExpirationDate());
      }
    });
  }

  ngOnInit(): void {
    // Si un cookie campaign existe, on met à jour la date d'expiration
    const cookieValue = this.cookieService.get(CAMPAIGN_COOKIE_NAME);
    if (cookieValue) {
      this.cookieService.set(CAMPAIGN_COOKIE_NAME, cookieValue, this.getExpirationDate());
    }
  }

  getExpirationDate(): Date {
    const today = new Date();
    const expirationDate = new Date();
    expirationDate.setDate(today.getDate() + 30);
    return expirationDate;
  }
}
