import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nscf-liseret',
  templateUrl: 'liseret.component.html',
  styleUrls: ['liseret.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiseretComponent {
  @Input() isCentered = false;
}
