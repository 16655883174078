<div class="stp__wrapper-container" [ngClass]="{ 'stp__wrapper-container--without': !data.mediaCover }">
  <div class="container">
    <div class="row">
      <div class="col-12">
        @if (data.title) {
          <nscf-title class="stp__title" [selector]="'h1'" [title]="data.title"></nscf-title>
        }
        @if (data.heading) {
          <p class="stp__description">{{ data.heading }}</p>
        }
        @if (data.enableTrustPilot) {
          <nscf-trustpilot-micro-star class="stp__wrapper-avis"></nscf-trustpilot-micro-star>
        }
      </div>
    </div>
  </div>

  <div class="stp__card-wrapper-position-bg">
    <div class="container">
      <div
        class="stp__card-container col-12 col-sm-8 col-md-6 col-lg-10 m-auto" 
        [class.stp__card-container--animate]="isBrowser"
      >
        <p class="stp__card-title">{{ data.formTitle }}</p>
          <div class="stp__card-wrapper">
            <effy-tile 
              *ngFor="let formCardInput of data.formCards; index as i"
              class="stp__card-item"
              [control]="$any(formGroup.get('card' + i))"
              [label]="formCardInput.title"
              [value]="'formCardInput-' + i"
              [id]="'formCardInput-' + i"
              [image]="formCardInput.picto?.url"
              [size]="tileSize"
              [inputType]="tileType"
              (valueChanged)="redirectForm($event, formCardInput)"
            >
            </effy-tile>
          </div>

        @if (data.formDescription) {
          <p class="stp__card-description mx-auto">
            {{ data.formDescription }}
          </p>
        }
        <div appCtaAction [text]="data.ctaLabel" [ctaUrl]="data.ctaUrl" [replaceElements]="true"></div>
      </div>
    </div>

    @if (data.mediaCover) {
      <div class="container-fluid stp__card-bg-image" [ngStyle]="urlCover" #cover></div>
    }
  </div>
</div>
