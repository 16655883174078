import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional, RendererFactory2 } from '@angular/core';
import { LoggerService } from '../../loggers';

@Injectable({
  providedIn: 'root',
})
export class StaticMetaTagsService {
  constructor(
    @Inject(DOCUMENT) private readonly dom: any,
    private readonly rendererFactory: RendererFactory2,
    @Optional() private readonly logger: LoggerService,
  ) {}

  addTag(tag: LinkDefinition) {
    try {
      const head = this.dom.head;
      let link: HTMLLinkElement = this.dom.querySelector(`link[rel=${tag.rel}]`) || null;
      if (!link) {
        link = this.dom.createElement('link');
        head.appendChild(link);
      }
      Object.keys(tag).forEach((prop: string) => {
        return link.setAttribute(prop, tag[prop]);
      });
    } catch (e) {
      this.logger.error('StaticMetaTagsService: Error within linkService', e);
    }
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
