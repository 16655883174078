import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EffyButtonComponent } from '@effy-tech/angular-common';
import { CarouselComponent } from '../carousel/carousel.component';
import { LiseretComponent } from '../liseret/liseret.component';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { IMedias } from './medias';
import { MediasCardComponent } from './medias-card/medias-card.component';
import { MediasCardificationComponent } from './medias-cardification/medias-cardification.component';

@Component({
  selector: 'nscf-medias-component',
  templateUrl: 'medias.component.html',
  styleUrls: ['medias.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TitleComponent,
    RteComponent,
    NgClass,
    CarouselComponent,
    MediasCardComponent,
    MediasCardificationComponent,
    LiseretComponent,
    EffyButtonComponent,
  ],
})
export class MediasComponent {
  @Input() data: IMedias;
  @Input() carrousel: boolean;
  @Input() isBrowser: boolean;

  constructor() {
  }
}
