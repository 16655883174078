<ul class="header-list" [style.width.px]="menuWidth" [ngStyle]="{ transform: 'translateX(' + -menuGapFromLeft + 'px)' }">
  @if (!isDesktop) {
    <label [for]="id" (click)="onGoBack()" effy-link [color]="EffyLinkStyle.MIDNIGHT_BLUE" [size]="EffyLinkSize.MEDIUM" class="tw-my-8">
      @defer (on viewport) {
        <mat-icon [svgIcon]="'chevron-right'" class="tw-size-4 rotate-icon"></mat-icon>
      } @placeholder {
        <span class="tw-size-4">icône</span>
      }
      <span>Retour</span>
    </label>
  }
  @for (item of menu.list.items; track item; let index = $index) {
    <li class="header-list__item">
      <nscf-header-item [item]="item"></nscf-header-item>
    </li>
  }
  <input class="c-close-flap-element" [id]="id" name="menu-list" type="radio" />
</ul>
