@if (data.showPreFooter) {
  <nscf-pre-footer></nscf-pre-footer>
}
<footer class="tw-flex tw-flex-col tw-bg-main-color tw-relative tw-pb-[var(--cta-sticky-height)] smd:tw-pb-0">
  <button class="new-footer__arrow-scroll" (click)="scrollToTop()" aria-label="Faire défiler jusqu'en haut">
    @defer (on viewport) {
      <mat-icon [svgIcon]="arrowScrollIcon" class="new-footer__arrow-scroll-icon"></mat-icon>
    } @placeholder {
      <span>scroll</span>
    }
  </button>
  <div
    class="tw-grid tw-grid-cols-1 smd:tw-grid-cols-[min-content,1fr] tw-gap-10 tw-px-8 tw-py-12 smd:tw-px-[50px] md:tw-px-[124px] smd:tw-py-[88px]"
  >
    <div class="tw-flex tw-flex-col tw-items-center smd:tw-items-start tw-gap-20 tw-w-full smd:tw-w-[400px]">
      @defer (on viewport) {
        <mat-icon class="site-logo" [svgIcon]="siteLogo()"></mat-icon>
      } @placeholder {
        <span>{{ siteLogo() }}</span>
      }
      <div class="tw-w-full tw-flex tw-flex-col tw-gap-10">
        <p class="tw-text-2xl smd:tw-text-3xl tw-font-700 tw-mb-0" [innerHTML]="content.ctaSubject | safeHtml"></p>
        <a [href]="content.cta.url" effy-button [color]="ctaStyle()" class="tw-w-full">
          @if (isSolar()) {
            Je passe au solaire
          } @else {
            {{ content.cta.title }}
          }
        </a>
      </div>
    </div>
    <div
      class="smd:tw-justify-self-end tw-w-fit tw-grid tw-gap-10 tw-grid-cols-1"
      [ngClass]="content.columns.length > 4 ? 'column-more-than-4' : 'column-less-than-4'"
    >
      @for (column of content.columns; track $index) {
        <div class="tw-min-w-[216px] tw-flex tw-flex-col tw-gap-4 tw-items-start">
          @for (columnLink of column.columnLinks; track $index) {
            @if (columnLink.mainLink) {
              @if (columnLink.url) {
                <a [href]="columnLink.url" class="tw-text-md tw-font-700">{{ columnLink.link }}</a>
              } @else {
                <p class="tw-text-md tw-font-700 tw-mb-0">{{ columnLink.link }}</p>
              }
            } @else if (columnLink.url) {
              <a [href]="columnLink.url" class="column-link">
                <span>{{ columnLink.link }}</span>
                <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
              </a>
            }
          }
        </div>
      }
    </div>
    <div class="tw-flex tw-flex-col tw-gap-4">
      <div class="tw-text-xl tw-font-300">{{ content.siteLogo.title }}</div>
      <div class="tw-w-fit tw-flex tw-flex-wrap md:tw-flex-nowrap tw-gap-6">
        @for (siteLogo of content.siteLogo.logos; track $index) {
          <a [href]="siteLogo.link">
            @defer (on viewport) {
              <mat-icon class="tw-h-8 smd:tw-h-12 tw-w-auto" [svgIcon]="siteLogo.icon"></mat-icon>
            } @placeholder {
              <span>{{ siteLogo.icon }}</span>
            }
          </a>
        }
      </div>
    </div>
    <div class="smd:tw-justify-self-end tw-flex tw-flex-col tw-gap-10">
      <div class="tw-flex tw-flex-col tw-items-center smd:tw-flex-row tw-gap-4 smd:tw-gap-10">
        <span class="tw-text-xl tw-font-700">{{ content.socialsLink.text }}</span>
        <div class="tw-flex tw-gap-4">
          @for (socialLink of content.socialsLink.links; track $index) {
            <a [href]="socialLink.link" [attr.aria-label]="socialLink.name" class="tw-flex hover:tw-text-primary-color">
              @defer (on viewport) {
                <mat-icon class="tw-size-8" [svgIcon]="socialLink.name"></mat-icon>
              } @placeholder {
                <span class="tw-size-8">{{ socialLink.name }}</span>
              }
            </a>
          }
        </div>
      </div>
      <div class="tw-flex tw-flex-wrap tw-gap-x-10 tw-gap-y-4">
        @for (cguLink of content.cguLinks; track $index) {
          <a class="tw-text-sm tw-font-600 hover:tw-text-primary-color" [href]="cguLink.url">{{ cguLink.text }}</a>
        }
      </div>
    </div>
  </div>
  @if (content?.banner) {
    <div class="new-footer__banner">
      <div class="new-footer__container">
        <div class="new-footer__row">
          <div class="new-footer__col-12 new-footer__col-lg-8 new-footer__wrapper-banner">
            <p class="new-footer__banner-title">
              {{ content?.banner?.title }}
            </p>
            <p class="new-footer__banner-description">
              {{ content?.banner?.description }}
            </p>
          </div>
          <div class="new-footer__col-12 new-footer__col-lg-4">
            @if (content?.banner?.cta) {
              <a class="new-footer__banner-cta" [href]="content?.banner?.cta?.url">{{ content?.banner?.cta?.title }}</a>
            }
          </div>
        </div>
      </div>
    </div>
  }
  @if (content?.banner) {
    <div
      [ngClass]="bannerTheme()"
      class="tw-flex tw-flex-col tw-items-center smd:tw-flex-row tw-justify-between tw-gap-5 tw-bg-primary-color tw-text-on-primary-color tw-px-8 smd:tw-px-[50px] md:tw-px-[124px] tw-py-6"
    >
      <div class="tw-flex tw-flex-col">
        <p class="tw-text-xl tw-font-700 tw-mb-0">{{ content?.banner?.title }}</p>
        <p class="tw-text-lg tw-font-300 tw-mb-0">{{ content?.banner?.description }}</p>
      </div>
      <a
        [href]="content?.banner?.cta?.url"
        effy-button
        [size]="bannerButtonSize()"
        class="tw-w-full smd:tw-w-[400px]"
        [style.--effy-button-border-color]="!isPro() ? '#ffffff' : null"
      >
        <span>{{ content?.banner?.cta?.title }}</span>
        <mat-icon [svgIcon]="'chevron-right'" class="tw-flex"></mat-icon>
      </a>
    </div>
  }
</footer>
