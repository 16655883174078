import { NgIf, NgStyle } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TrustpilotBase } from '../../config/trustpilot-base';
import { TitleComponent } from '../title/title.component';
import { ITrustpilotCarousel } from './trustpilot-carousel';

@Component({
  selector: 'nscf-trustpilot-carousel',
  standalone: true,
  imports: [TitleComponent, NgStyle, NgIf],
  templateUrl: './trustpilot-carousel.component.html',
  styleUrls: ['./trustpilot-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'container',
    ngSkipHydration: 'true',
  },
})
export class TrustpilotCarouselComponent extends TrustpilotBase implements AfterViewInit {
  @Input({ required: true }) data: ITrustpilotCarousel;
  readonly width = '100%';
  readonly height = '240px';
  readonly style = this.computeStyle();

  ngAfterViewInit(): void {
    this.init();
  }
}
