import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EffyHashtagComponent, EffyHashtagSize, EffyHashtagStyle } from '@effy-tech/angular-common';
import { ICardSummary } from '../../interfaces/cards-summary';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';
import { NewsCardMode } from './news-card';

@Component({
  selector: 'nscf-news-card',
  templateUrl: 'news-card.component.html',
  styleUrls: ['news-card.component.scss'],
  standalone: true,
  imports: [NgClass, SafeHtmlPipe, EffyHashtagComponent],
  host: {
    ngSkipHydration: 'true',
  },
})
export class NewsCardComponent {
  @Input() isSticky: boolean;
  @Input() data: ICardSummary = {} as ICardSummary;
  @Input() isDesktop: boolean;
  @Input() isHeadCard: boolean;
  @Input() mode: NewsCardMode;
  newsCardMode = NewsCardMode;

  hashtagSize = EffyHashtagSize.MEDIUM;
  hashtagColorHeadline = EffyHashtagStyle.SECONDARY;
}
