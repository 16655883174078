@if (isOverMobile) {
  @if (element.video?.video.length || element.image || element.map?.isActive) {
    <div [ngClass]="element.imageLocation === 'left' ? 'tw-flex-row' : 'tw-flex-row-reverse'" class="container tw-flex tw-flex-row">
      <div class="img-container col-6 col-md-12 col-lg-6 d-md-none d-lg-block">
        @if (element.map?.isActive) {
          <nscf-map [map]="element.map" class="text__img"></nscf-map>
        }
        @if (element.image?.url && !element.map?.isActive && !element.video?.video.length) {
          <img [alt]="element.image?.alt" [src]="element.image?.url" loading="lazy" class="text__img" />
        }
        @if (element.video?.video.length) {
          <nscf-video-text [data]="element.video" [isDesktop]="isOverMobile" class="text__img"></nscf-video-text>
        }
      </div>
      <div class="col-6 col-md-10 col-lg-6 mx-auto text text-container">
        <div class="text__wrapper-content">
          @if (element.title) {
            <nscf-title
              [id]="'anchor_' + element.id"
              [selector]="element.isFirstTitle ? 'h1' : 'h2'"
              [title]="element.title"
              [shouldDisplaySeparator]="true"
            >
            </nscf-title>
          }
          @if (element.image?.url && !element.map?.isActive && !element.video?.video.length) {
            <img [alt]="element.image?.alt" [src]="element.image?.url" loading="lazy" class="text__img d-none d-md-block d-lg-none" />
          }
          @if (element.map.isActive) {
            <nscf-map [map]="element.map" class="text__img d-none d-md-block d-lg-none"></nscf-map>
          }
          @if (element.video?.video.length) {
            <nscf-video-text
              [data]="element.video"
              [isDesktop]="isOverMobile"
              class="text__img d-none d-md-block d-lg-none"
            ></nscf-video-text>
          }
          <nscf-rte [data]="element.text" class="text__content text__content--left"></nscf-rte>
          @if (element.ctaLabel) {
            <div>
              <nscf-cta-button [ctaUrl]="element.ctaUrl" [text]="element.ctaLabel"></nscf-cta-button>
            </div>
          }
        </div>
      </div>
    </div>
  } @else {
    <div
      class="col-8 col-md-10 col-lg-8 tw-mx-auto text text--article"
      [ngClass]="{ 'tw-mt-[24px] lg:tw-mt-[40px]': element.title && !isFirst }"
    >
      @if (element.title) {
        <nscf-title
          [id]="'anchor_' + element.id"
          [selector]="element.isFirstTitle ? 'h1' : 'h2'"
          [title]="element.title"
          class="text-center"
          [shouldDisplaySeparator]="true"
        >
        </nscf-title>
      }
      <nscf-rte [data]="element.text" class="text__content text__content--without-img"></nscf-rte>
      @if (element.widgetCalculator?.isActive) {
        <nscf-widget-calculator [theme]="theme" [data]="element.widgetCalculator" [isBrowser]="isBrowser"> </nscf-widget-calculator>
      }
      @if (element.ctaLabel) {
        <div>
          <nscf-cta-button class="tw-mx-auto" [ctaUrl]="element.ctaUrl" [text]="element.ctaLabel"></nscf-cta-button>
        </div>
      }
    </div>
  }
} @else {
  @if (element.video || element.image || element.map?.isActive) {
    <div class="col-12 text">
      @if (element.title) {
        <nscf-title [id]="'anchor_' + element.id" [selector]="element.isFirstTitle ? 'h1' : 'h2'" [title]="element.title"> </nscf-title>
      }
      @if (element.map.isActive) {
        <nscf-map [map]="element.map"></nscf-map>
      }
      @if (element.image?.url && !element.map?.isActive && !element.video?.video.length) {
        <img [alt]="element.image?.alt" [src]="element.image?.url" loading="lazy" class="text__img" />
      }
      @if (element.video?.video.length) {
        <nscf-video-text [data]="element.video" [isDesktop]="isOverMobile"></nscf-video-text>
      }
      <nscf-rte [data]="element.text" class="text__content text__content--right"></nscf-rte>
      @if (element.widgetCalculator?.isActive) {
        <nscf-widget-calculator [theme]="theme" [data]="element.widgetCalculator" [isBrowser]="isBrowser"> </nscf-widget-calculator>
      }
      @if (element.ctaLabel) {
        <div>
          <nscf-cta-button [ctaUrl]="element.ctaUrl" [text]="element.ctaLabel"></nscf-cta-button>
        </div>
      }
    </div>
  } @else {
    <div class="col-12 mx-auto text">
      @if (element.title) {
        <nscf-title [id]="'anchor_' + element.id" [selector]="element.isFirstTitle ? 'h1' : 'h2'" [title]="element.title"> </nscf-title>
      }
      <nscf-rte [data]="element.text" class="text__content text__content--without-img"></nscf-rte>
      @if (element.widgetCalculator?.isActive) {
        <nscf-widget-calculator [theme]="theme" [data]="element.widgetCalculator" [isBrowser]="isBrowser"> </nscf-widget-calculator>
      }
      @if (element.ctaLabel) {
        <div>
          <nscf-cta-button [ctaUrl]="element.ctaUrl" [text]="element.ctaLabel"></nscf-cta-button>
        </div>
      }
    </div>
  }
}
