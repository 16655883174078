import { DOCUMENT, Location } from '@angular/common';
import { Directive, Inject, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderComponent } from '@effy-tech/acquisition-core/header';
import { BasicThemedComponentDirective } from '@effy-tech/acquisition-core/shared';
import { FooterComponent } from '../../components/footer/footer.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { typenamePreHeaderBloc } from '../../components/pre-header/pre-header';
import { PreHeaderComponent } from '../../components/pre-header/pre-header.component';
import { DynamicComponent, DynamicComponentData } from '../../interfaces/dynamic-types';
import { IPage } from '../../interfaces/pages';
import { DefaultPageComponent } from '../../pages/default/default.component';
import { pageMapping, shouldDisplayPhoneInsert } from '../../pages/dynamic';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LoggerService } from '../../services/loggers';
import { MetaTagsService, MetaTagsTitleService } from '../../services/meta-tags';
import { PageScrollingService } from '../../services/page-scrolling/page-scrolling.service';
import { isEmpty } from '../../utils/object/object.utils';

@Directive()
export class ComponentInitializerDirective extends BasicThemedComponentDirective {
  data: any = null;
  page: IPage;
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;

  constructor(
    private readonly metaTagsService: MetaTagsService,
    private readonly metaTagsTitleService: MetaTagsTitleService,
    private readonly pageScrollingService: PageScrollingService,
    private readonly router: Router,
    private readonly location: Location,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly renderer: Renderer2,
    protected readonly deviceManagerService: DeviceManagerService,
    protected readonly logger: LoggerService
  ) {
    super();
  }

  preparePage(): void {
    this.page = <IPage>this.data;

    if (!this.page) {
      return;
    }
    // Add specific classes to the body
    if (this.page.__typename) {
      try {
        this.renderer.addClass(this.document.body, this.computeBodyClass(this.page.__typename));
      } catch (e) {
        this.logger.error('ComponentInitializerDirective: Error in renderer.addClass with computeBodyClass', e);
      }
    }

    try {
      this.renderer.addClass(this.document.body, this.page.template);
    } catch (e) {
      this.logger.error('ComponentInitializerDirective: Error in renderer.addClass without computeBodyClass', e);
    }

    // Set Metatags
    try {
      this.setMetatags();
    } catch (e) {
      this.logger.error('ComponentInitializerDirective: Error in setMetatags', e);
    }

    // Set the dynamic components
    try {
      this.setDynamicComponents();
    } catch (e) {
      this.logger.error('ComponentInitializerDirective: Error in setDynamicComponents', e);
    }

    // Add behaviors on loaded DOM
    try {
      if (this.deviceManagerService.isBrowser()) {
        this.checkIfLoaded();
      }
    } catch (e) {
      this.logger.error('ComponentInitializerDirective: Error in checkIfLoaded', e);
    }
  }

  setDynamicComponentError(): void {
    const dynamicComponentsData: DynamicComponentData[] = [
      {
        type: NotFoundComponent,
        content: null,
        typename: null,
      },
    ];
    for (const data of dynamicComponentsData) {
      try {
        this.createComponent(data.type, data.content);
      } catch (e) {
        this.logger.error('ComponentInitializerDirective: Erreur à la création du component lié à ' + data.typename, e);
      }
    }
  }

  private setMetatags() {
    // Vérifier que this.page.metatags existe
    if (this.page.metatags) {
      // Pour le title de la page on check l'array sur la valeur title
      const titleTag = this.page.metatags.filter(metatag => metatag.name === 'title');
      // Vérification si titleTag[0] existe
      if (titleTag.length) {
        // Définie le title avec la value reçue lors de la recherche sur title on renvoie titleTag[0].content
        this.metaTagsTitleService.setTitleTag(titleTag[0].content);
      } else {
        this.logger.error('ComponentInitializerDirective: Erreur à la création du meta title lié à ' + titleTag[0]);
      }

      // Fais un add des nouvelles données de metatag fournies par drupal
      this.metaTagsService.addTags(this.page.metatags);
    }
  }

  private checkIfLoaded() {
    const that = this;
    window.addEventListener('load', event => {
      // if there is an anchor in the URL, scroll to the element
      if (this.data && this.data.urlAnchor) {
        this.pageScrollingService.scrollTo(`#${this.data.urlAnchor}`, 80);
      }

      // bind all anchors behaviors
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        // handle only anchor links (with hash)
        if (link.hash) {
          link.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            // scroll to the element
            that.pageScrollingService.scrollTo(this.hash, 80);
            // then push the hash in the current url
            that.location.go(that.router.url.split('#')[0] + this.hash);
            return;
          });
        }
      });
    });
  }

  private setDynamicComponents(): void {
    const dynamicComponentsData: DynamicComponentData[] = [];
    // 1.PreHeader
    if (this.page.preHeader) {
      dynamicComponentsData.push({
        type: PreHeaderComponent,
        content: this.page.preHeader,
        typename: typenamePreHeaderBloc,
      });
    }
    // 2. Header
    if (!isEmpty(this.page.header)) {
      dynamicComponentsData.push({
        type: HeaderComponent,
        content: { ...this.page.header, shouldDisplayPhoneInsert: shouldDisplayPhoneInsert(this.page?.__typename) },
        typename: this.page.__typename,
      });
    }
    // 3. Page
    this.getDynamicPage(dynamicComponentsData);
    // 4. Footer
    dynamicComponentsData.push({
      type: FooterComponent,
      content: { isPro: this.page?.header?.isPro },
      typename: this.page.__typename,
    });

    for (const data of dynamicComponentsData) {
      try {
        this.createComponent(data.type, data.content);
      } catch (e) {
        this.logger.error('ComponentInitializerDirective: Erreur à la création du component lié à ' + data.typename, e);
      }
    }
  }

  private getDynamicPage(dynamicComponentsData: DynamicComponentData[]): void {
    dynamicComponentsData.push({
      type: pageMapping.hasOwnProperty(this.page.__typename) ? pageMapping[this.page.__typename] : DefaultPageComponent,
      content: this.page,
      typename: this.page.__typename,
    });
  }

  private createComponent(type, content: any): void {
    const component = this.container.createComponent<DynamicComponent>(type).instance;
    component.data = content;
    component.theme = this.theme;
  }

  private computeBodyClass(typename: string) {
    // Remove 'Node' text from Drupal typename and lower it.
    try {
      return `pagetype-${typename.substring(4).toLowerCase()}`;
    } catch (e) {
      this.logger.error('ComponentInitializerDirective: Error in computeBodyClass', e);
      return '';
    }
  }
}
