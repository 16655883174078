@if (shouldDisplayBlock()) {
  <h3 class="tw-font-700 tw-text-xl slg:tw-text-2xl tw-mb-4" data-test="ui-heading-questions__title">{{ title() }} : vos questions</h3>
  <span class="tw-w-[60px] tw-h-[8px] tw-mb-6 tw-bg-secondary-color"></span>
  <div class="tw-flex tw-flex-col tw-gap-y-6">
    <a
      effy-link
      *ngFor="let item of questions() | slice: 0 : nbMaxQuestionsDisplayed"
      [attr.href]="item.url"
      data-test="ui-heading-questions__item"
    >
      @defer (on viewport) {
        <mat-icon svgIcon="chevron-right" class="tw-size-4 tw-text-[16px] tw-flex tw-flex-shrink-0 tw-self-start" />
      } @placeholder {
        <span class="tw-size-4">></span>
      }
      <span
        [innerHTML]="item.text"
      >
        {{ item.text }}</span
      ></a
    >
  </div>
}
