@for (card of data(); track card.title) {
  @if (card.title || card.image) {
    <div
      class="tw-flex-1 tw-gap-[16px] tw-items-center tw-flex tw-flex-col tw-text-center tw-border-border-card tw-border-[1px] tw-border-solid tw-rounded-[12px] tw-p-[32px] tw-bg-white"
    >
      @if (card.image) {
        <div class="tw-h-[35px]">
          <img class="tw-h-full" [src]="card.image.url" [alt]="card.image.alt" height="35" />
        </div>
      }
      <p class="tw-mb-0 tw-font-600 tw-text-lg">{{ card.title }}</p>
    </div>
  }
}
