import { defaultsDeep, map } from 'lodash-es';
import { IBreadCrumbItems } from '../../components/breadcrumb/breadcrumb';
import { Devices } from '../../enums/devices.enum';
import { IPageDefault, IPageOffer, TypeNamePageOffer } from '../../interfaces/pages';
import { LoggerService } from '../../services/loggers';
import { TaxonomyPresenter } from '../commons/taxonomy.presenter';
import { MainBlockPresenter } from '../main-blocks.presenter';
import { IPagePresenter } from '../presenter';
import { PageDefaultPresenter } from './page-default.presenter';

export class PageOfferPresenter extends PageDefaultPresenter implements IPagePresenter {
  constructor(private readonly logger: LoggerService) {
    super();
  }

  parse(data: any): IPageOffer {
    const defaultPage: IPageDefault = super.parse(data);
    // merge defaultPage with extra Page data
    return defaultsDeep(
      <IPageOffer>{
        ...this.getCompleteData(data, defaultPage.breadcrumb),
      },
      defaultPage
    );
  }

  private getCompleteData(data: any, breadcrumb: IBreadCrumbItems): IPageOffer {
    const bcData = breadcrumb;
    const mainBlockPresenter = new MainBlockPresenter(this.logger).parse(data['fieldBlock']);
    if (bcData) {
      mainBlockPresenter.push({ ...bcData, extra: [Devices.MOBILE_SE, Devices.MOBILE, Devices.IPAD_PORTRAIT] });
      mainBlockPresenter.unshift({ ...bcData, extra: [Devices.IPAD_LANDSCAPE, Devices.DESKTOP] });
    }

    return <IPageOffer>{
      __typename: TypeNamePageOffer,
      taxonomy: map(data['fieldTaxonomy1'], taxonomy => {
        return TaxonomyPresenter.getTaxonomy(taxonomy);
      }),
      blocks: mainBlockPresenter,
    };
  }
}
