@for (card of cards; track card; let index = $index) {
  <div class="cards-discovery-categorie__content  cards-discovery-categorie__content-{{ index }}">
    <a href="{{ card.ctaUrl }}" class="cards-discovery-categorie__wrapper-link">
      <div class="cards-discovery-categorie__card cards-discovery-categorie__card-{{ index }}">
        <div class="wrapper-image">
          @if (card?.cover?.url) {
            <div class="illustration illustration-{{ index }}">
              @if (card.cover) {
                <picture>
                  <source [attr.srcset]="card.cover?.webpUrl" />
                  <img [attr.src]="card.cover?.url" [attr.alt]="card.cover?.alt" loading="lazy" />
                </picture>
              }
            </div>
          }
        </div>
        <div class="wrapper-content" [ngClass]="!card?.cover?.url ? 'without-image' : ''">
          @if (card.title) {
            <p class="cards-discovery-categorie__title">{{ card.title }}</p>
          }
          <a
            class="tw-flex tw-items-center"
            effy-link
            [color]="linkStyle.YELLOW"
            [size]="(isDesktop$ | async) ? linkSize.MEDIUM : linkSize.SMALL"
            [href]="card?.ctaUrl"
            >{{ linkText$ | async }}
            @defer (on viewport) {
              <mat-icon class="tw-flex tw-items-center" [svgIcon]="'chevron-right'"></mat-icon>
            } @placeholder {
              <span>></span>
            }
          </a>
        </div>
      </div>
    </a>
  </div>
}
