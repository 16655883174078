import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService, IconsService, RichResultsService, SchemaTypes, UtmCampaignComponent } from '@effy-tech/acquisition-core';
import { EffyIconsService } from '@effy-tech/angular-common';

@Component({
  selector: 'acq-app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, UtmCampaignComponent],
  host: {
    class: 'app-container',
  },
})
export class AppComponent implements OnInit {
  private readonly configService = inject(ConfigService);
  private readonly effyIconsService = inject(EffyIconsService);
  private readonly iconsService = inject(IconsService);
  private readonly richResultsService = inject(RichResultsService);

  ngOnInit() {
    this.configService.initConfig();
    this.initIconsServices();
    this.richResultsService.setJsonLd(SchemaTypes.ORGANIZATION, null);
    this.richResultsService.setTrustpilotRatesJsonLd('Effy');
  }

  private initIconsServices() {
    this.iconsService.init();
    this.effyIconsService.init();
  }
}
