import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { computeParamsFormQuestions, IFormParcours, IParamsFromQuestion } from './form-parcours';
import { FormParcoursQuestionComponent } from './question/form-parcours-question.component';

@Component({
  selector: 'nscf-form-parcours',
  templateUrl: './form-parcours.component.html',
  styleUrls: ['./form-parcours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormParcoursQuestionComponent, NgClass],
})
export class FormParcoursComponent {
  @Input() data: IFormParcours;
  @Input() enableTrustpilot = false;
  @Output() changeAnswer = new EventEmitter<IParamsFromQuestion>();
  objectParams: IParamsFromQuestion;

  onChangeAnswer() {
    this.objectParams = computeParamsFormQuestions(this.data.questions);
    this.changeAnswer.emit(this.objectParams);
  }
}
