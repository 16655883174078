<div class="rte">
  @switch (selector) {
    @case ('div') {
      @if (data) {
        @if (data.processed) {
          <div [innerHTML]="data.processed | safeHtml" class="text__content"></div>
        }
      } @else {
        @if (toBeAppended) {
          <div [innerHTML]="toBeAppended.join() | safeHtml" class="text__content"></div>
        }
      }
    }
    @case ('p') {
      @if (data) {
        @if (data.processed) {
          <p [innerHTML]="data.processed | safeHtml"></p>
        }
      } @else {
        @if (toBeAppended) {
          <p [innerHTML]="toBeAppended.join() | safeHtml"></p>
        }
      }
    }
  }
</div>
