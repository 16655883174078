import { Component, Input } from '@angular/core';
import { LiseretComponent } from '../../liseret/liseret.component';
import { IArticlePdfBloc } from './article-pdf';

import { MatIconModule } from '@angular/material/icon';
import { EffyLinkComponent, EffyLinkSize } from '@effy-tech/angular-common';

@Component({
  selector: 'nscf-article-pdf',
  templateUrl: 'article-pdf-bloc.component.html',
  styleUrls: ['article-pdf-bloc.component.scss'],
  standalone: true,
  imports: [LiseretComponent, EffyLinkComponent, MatIconModule],
  host: {
    class: 'article-files container',
  },
})
export class ArticlePdfBlocComponent {
  @Input() data: IArticlePdfBloc;
  @Input() isDesktop = true;

  linkSize = EffyLinkSize.MEDIUM;

  openPdf(url: string) {
    window.open(url, '_blank');
  }
}
