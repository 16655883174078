@if (data) {
  <div class="tw-relative tw-order-1 slg:tw-order-[0]">
    @if (data.image && isDesktop()) {
      <img
        class="tw-absolute tw-max-w-full tw-rounded-xl tw-object-cover tw-h-full slg:tw-mt-[-52px]"
        [src]="data.image.url"
        [alt]="data.image.title"
        fetchPriority="high"
      />
    }
    <div
      class="tw-flex tw-flex-col tw-justify-center slg:tw-absolute tw-max-w-[445px] tw-right-[32px] tw-mt-[40px] slg:tw-mt-[60px] tw-mx-auto sm:tw-min-w-[360px]"
    >
      <nscf-form-parcours
        class="tw-bg-midnight-blue tw-p-[32px] tw-rounded-[12px]"
        (changeAnswer)="redirectToParcours($event)"
        [data]="data.formParcours"
        [enableTrustpilot]="data.enableTrustPilot"
      />
      @if (data.enableTrustPilot) {
        <nscf-trustpilot-micro-star class="trustpilot-rating tw-my-[16px]" />
      }
    </div>
  </div>
  <div class="tw-flex tw-flex-col">
    <nscf-title selector="h1" [title]="data.title" shouldDisplaySeparator />
    <nscf-rte [data]="description()" />
    @if (data.cta; as cta) {
      <a
        effy-button
        class="tw-mx-auto tw-my-[24px]"
        [attr.href]="cta?.url"
        [attr.rel]="cta?.options?.attributes?.rel"
        [attr.target]="cta?.options?.attributes?.target"
        [ngClass]="cta?.options?.attributes?.class"
        >{{ data.cta.title }}</a
      >
    }
  </div>
}
