@if (map?.region) {
  <div class="map-block container d-flex justify-content-center">
    <div class="map-block__text">
      <p class="map-block-title">
        Région : <span class="no-wrap">{{ map?.region | region }}</span>
      </p>
      <p class="map-block-description">Cliquez sur la carte pour sélectionner une autre région</p>
    </div>
    @if (map?.isActive) {
      <nscf-map [map]="map" class="map-block__map"></nscf-map>
    }
  </div>
}
