import { inject, Injectable } from '@angular/core';
import {
  IconDefinitions,
  LinePictosDefinitions,
  OtherPictosDefinitions,
  SymbolePictosDefinitions,
} from '../../configs/icons-definitions.config';
import { SITES } from '../../models/widget-calculator.models';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CommonAssetsService {
  private readonly configService = inject(ConfigService);
  private readonly COMMON_ASSETS_BASE_URL = `${this.configService.getConfig().sitesUri[SITES.EFFY]}/common-assets/`;
  private readonly FORM_CARD_IMAGES = `${this.COMMON_ASSETS_BASE_URL}images/pictos/`;
  private readonly WORKS_PICTOS = `${this.FORM_CARD_IMAGES}works/`;
  private readonly LINE_PICTOS = `${this.FORM_CARD_IMAGES}line/`;
  private readonly OTHER_PICTOS = `${this.FORM_CARD_IMAGES}other/`;
  private readonly SYMBOLE_PICTOS = `${this.FORM_CARD_IMAGES}symbole/`;

  getWorkImagePngUrl(workIcon: IconDefinitions): string {
    return `${this.WORKS_PICTOS}${workIcon}.png`;
  }

  getLinePictoSvgUrl(lineIcon: LinePictosDefinitions): string {
    return `${this.LINE_PICTOS}${lineIcon}.svg`;
  }

  getOtherPictoSvgUrl(otherIcon: OtherPictosDefinitions): string {
    return `${this.OTHER_PICTOS}${otherIcon}.svg`;
  }

  getSymbolePictoSvgUrl(symboleIcon: SymbolePictosDefinitions): string {
    return `${this.SYMBOLE_PICTOS}${symboleIcon}.svg`;
  }
}
