import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { EffyLinkComponent, EffyLinkSize, EffyLinkStyle } from '@effy-tech/angular-common';
import { IRelatedItem } from '../../../interfaces/pages';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';

@Component({
  selector: 'nscf-article-related',
  templateUrl: 'related.component.html',
  styleUrls: ['related.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatExpansionModule, SafeResourceUrlPipe, NgClass, MatIconModule, EffyLinkComponent],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class ArticleRelatedComponent {
  @Input() data: IRelatedItem;
  readonly bigViewPort = input<boolean>(false);
  protected readonly size = computed(() => (this.bigViewPort ? EffyLinkSize.MEDIUM : EffyLinkSize.SMALL));
  protected readonly hostClasses = computed(() => `effy-related-${this.size()}`);
  protected readonly EffyLinkStyle = EffyLinkStyle;
}
