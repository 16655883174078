import { Component, Input } from '@angular/core';
import { EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { RteComponent } from '../../rte/rte.component';
import { TitleComponent } from '../../title/title.component';
import { ISubBlockTables } from './tables';

@Component({
  selector: 'nscf-tables',
  templateUrl: 'tables.component.html',
  styleUrls: ['tables.component.scss'],
  standalone: true,
  imports: [TitleComponent, RteComponent, CtaButtonComponent],
  host: {
    class: 'container',
  },
})
export class TablesComponent {
  @Input() element: ISubBlockTables;
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonColor = EffyButtonStyle;
}
