import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { IPagerLinks } from './pager-link';

@Component({
  selector: 'nscf-pager',
  templateUrl: 'pager.component.html',
  styleUrls: ['pager.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class PagerComponent {
  @Input() data: IPagerLinks;
}
