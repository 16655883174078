import { AsyncPipe, isPlatformServer, NgClass, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    PLATFORM_ID,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { EffyProgressBarComponent } from '@effy-tech/angular-common';
import { MagazineBlockComponent, MetaInfoComponent } from '../../components/article-blocs';
import { ArticleAuthorComponent } from '../../components/article-blocs/article-author/article-author.component';
import { ArticleCoverComponent } from '../../components/article-blocs/article-cover/cover.component';
import { ArticleRelatedComponent } from '../../components/article-blocs/article-related/related.component';
import { ArticleSummaryDetailsComponent } from '../../components/article-blocs/article-summary-details/article-summary-details.component';
import { ArticleThemeComponent } from '../../components/article-blocs/article-theme/theme.component';
import { MapBlockComponent, TypeNameMapBlock } from '../../components/article-blocs/map-block/map-block.component';
import { TypeNameBlockTexts } from '../../components/block-text/block-text';
import { IBreadCrumbName } from '../../components/breadcrumb/breadcrumb';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { CardsSummaryComponent } from '../../components/cards-summary/cards-summary.component';
import { getCtaStickyDynamicComponentData, TypeNameBlockCtaSticky } from '../../components/cta-sticky/cta-sticky';
import { HeadingArticleComponent } from '../../components/heading-article/heading-article.component';
import { HeadingQuestionsComponent } from '../../components/heading-questions/heading-questions.component';
import { TypeNameBlocNewsletter } from '../../components/newsletter/newsletter';
import { TypeNameBlockTheme } from '../../components/theme/theme';
import { TitleComponent } from '../../components/title/title.component';
import { BOTemplate } from '../../enums/bo-template.enum';
import { TypeNameSubBlockCardsSummary } from '../../interfaces/cards-summary';
import { DynamicComponentData } from '../../interfaces/dynamic-types';
import { IPageArticle, PagesName } from '../../interfaces/pages';
import { ISummaryElement } from '../../interfaces/summary';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LoggerService } from '../../services/loggers';
import { RichResultsService } from '../../services/rich-results/rich-results.service';
import { SchemaTypes } from '../../services/rich-results/rich-results.values';
import { ScrollProgressPageService } from '../../services/scroll-progress-page/scroll-progress-page.service';
import { WebPagesUtil } from '../../utils/web-pages/web-pages.utils';
import { DynamicPageDirective } from '../dynamic-page';

@Component({
  selector: 'nscf-article-page',
  templateUrl: 'article.component.html',
  styleUrls: ['article.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, TitleComponent, MetaInfoComponent, ArticleSummaryDetailsComponent, AsyncPipe, EffyProgressBarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePageComponent extends DynamicPageDirective implements OnInit, OnDestroy {
  @Input() data: IPageArticle;

  @ViewChild('topContent', { read: ViewContainerRef, static: true })
  topContent: ViewContainerRef;
  @ViewChild('breadcrumbContent', { read: ViewContainerRef, static: true })
  breadcrumbContent: ViewContainerRef;
  @ViewChild('mainContent', { read: ViewContainerRef, static: true })
  mainContent: ViewContainerRef;
  @ViewChild('bottomContent', { read: ViewContainerRef, static: true })
  bottomContent: ViewContainerRef;
  @ViewChild('beforeFooterContent', { read: ViewContainerRef, static: true })
  beforeFooterContent: ViewContainerRef;
  @ViewChild('ctaDesktopContent', { read: ViewContainerRef, static: true })
  ctaDesktopContent: ViewContainerRef;
  @ViewChild('ctaMobileContent', { read: ViewContainerRef, static: true })
  ctaMobileContent: ViewContainerRef;
  component: {
    __typename: 'NodePageArticle';
    title: string;
    breadcrumb: { items: any[] };
    taxonomy: any[];
    template: BOTemplate.PARTS;
    header: any;
    blocks: any[];
    metatags: any[];
    cover: { alt: string; url: string; webpUrl: string };
    coverVisibleOnMobile: false;
    metaInfo: {
      date: { created: number; showDate: true; changed: number; showChangedDate: true };
      author: { name: string; imgAlt: string; imgSrc: string; isEditorialTeam: false; link: string };
      readingTime: string;
    };
    relateds: { name: string; links: any[] };
  };
  summary: ISummaryElement[] = [];
  hasCtaContent = false;
  progress$ = this.scrollProgressPageService.progress$;

  constructor(
    protected readonly logger: LoggerService,
    protected readonly deviceManagerService: DeviceManagerService,
    private readonly richResultsService: RichResultsService,
    @Inject(PLATFORM_ID) private readonly platformId,
    private readonly scrollProgressPageService: ScrollProgressPageService
  ) {
    super(logger, deviceManagerService);
  }

  ngOnInit(): void {
    this.getDeviceType().subscribe();
    if (isPlatformServer(this.platformId)) {
      this.richResultsService.setJsonLd(
        SchemaTypes.WEB_PAGE,
        WebPagesUtil.dataToWepPageArticleMapper(this.data, this.deviceManagerService.getFullUrlSSr(), this.theme)
      );
    }
  }

  buildPage(): void {
    // Clear page.
    this.clearPage();

    // Top contents.
    const topDynamicComponentsData: DynamicComponentData[] = [];
    if (this.isCoverVisible()) {
      topDynamicComponentsData.push({
        type: ArticleCoverComponent,
        content: this.data.cover,
        typename: this.data.__typename,
      });
    }
    super.appendDynamicComponentData(this.topContent, topDynamicComponentsData);
    // Main contents.
    // Extract dynamic components.
    let mainDynamicComponentsData = super.fieldBlocksAsDynamicComponentData(this.data.blocks, PagesName.Article);
    const bottomContentComponentsData = this.getBottomContent(mainDynamicComponentsData);

    // Article Author
    const articleMetaInfoComponentData = {
      type: MetaInfoComponent,
      content: this.data.metaInfo,
      typename: this.data.__typename,
    };

    if (this.data.metaInfo.author && this.data.metaInfo.author.name && !this.data.metaInfo.author.isEditorialTeam) {
      mainDynamicComponentsData.push(this.getArticleAuthorContent());
    }
    mainDynamicComponentsData.push(this.getThemeContent());

    // CTA
    if (this.data.showCtaSticky) {
      const ctaContent = getCtaStickyDynamicComponentData(this.data);
      this.hasCtaContent = !!ctaContent;
      if (this.hasCtaContent) {
        // We have to remove CTA from mainDynamicComponentsData
        mainDynamicComponentsData = mainDynamicComponentsData.filter(componentData => componentData?.typename !== TypeNameBlockCtaSticky);
        if (this.isDesktop) {
          super.appendDynamicComponentData(this.ctaDesktopContent, ctaContent);
        } else {
          super.appendDynamicComponentData(this.ctaMobileContent, ctaContent);
        }
      }
    } else {
      mainDynamicComponentsData = mainDynamicComponentsData.filter(componentData => componentData?.typename !== TypeNameBlockCtaSticky);
    }

    // Remove content that should be in bottom content
    mainDynamicComponentsData = [...mainDynamicComponentsData.filter(componentData => !this.isBottomContent(componentData?.typename))];

    // Then append them to the ViewContainerRef.
    super.appendDynamicComponentData(this.mainContent, mainDynamicComponentsData);

    const mapBlockComponentData: DynamicComponentData = {
      type: MapBlockComponent,
      content: {
        extra: { map: this.data.map },
      },
      typename: TypeNameMapBlock,
    };

    if (this.data.map?.isActive) super.appendDynamicComponentData(this.beforeFooterContent, [mapBlockComponentData]);

    // add bloc more articles just in case we have themes
    if (this.data.taxonomy?.length) {
      const taxonomyDynamicComponentData: DynamicComponentData[] = [
        {
          type: CardsSummaryComponent,
          content: {
            cards: this.data.moreArticle,
            taxonomies: { ...this.data.taxonomy },
          },
          typename: TypeNameSubBlockCardsSummary,
        },
      ];

      const magazineBlockComponentData = {
        type: MagazineBlockComponent,
        content: {
          showBlockMagazinePro: this.data.showBlockMagazinePro,
        },
        typename: TypeNameSubBlockCardsSummary,
      };

      const bottomDynamicComponentData: DynamicComponentData[] = [];

      // Related articles.
      const articleRelatedComponentData = {
        type: ArticleRelatedComponent,
        content: this.data.relateds,
        typename: this.data.__typename,
      };

      if (this.data.relateds) super.appendDynamicComponentData(this.beforeFooterContent, [articleRelatedComponentData]);

      const shouldDisplayMagazineBlock = this.data.showBlockMagazinePro || this.data.showBlockMagazinePart;

      if (shouldDisplayMagazineBlock) bottomDynamicComponentData.push(magazineBlockComponentData);

      if (this.data.moreArticle) bottomDynamicComponentData.push(taxonomyDynamicComponentData[0]);

      super.appendDynamicComponentData(this.beforeFooterContent, bottomDynamicComponentData);
    }

    // Breadcrumb Content.
    const breadcrumbContent = this.isDesktop ? this.breadcrumbContent : this.beforeFooterContent;
    super.appendDynamicComponentData(breadcrumbContent, this.getBreadcrumbContent());

    // Related articles mobile.
    if (!this.bigViewPort) {
      // Heading articles.
      const headingArticleComponentData = {
        type: HeadingArticleComponent,
        content: { headingThemeId: this.data.headingThemeId },
        typename: this.data.__typename,
      };

      const bottomDynamicComponentDataMobile: DynamicComponentData[] = [];
      if (this.data.visibleHeading) {
        bottomDynamicComponentDataMobile.push(headingArticleComponentData);
      }
      super.appendDynamicComponentData(this.beforeFooterContent, bottomDynamicComponentDataMobile);
    }

    if (this.data.visibleQuestions) {
      // Questions articles.
      const HeadingQuestionsComponentData = {
        type: HeadingQuestionsComponent,
        content: {
          title: this.data.questions?.title,
          currentThemeId: this.data.questions?.currentThemeId,
          currentArticleID: this.data?.nid,
        },
        typename: this.data.__typename,
      };
      mainDynamicComponentsData.push(articleMetaInfoComponentData);
      super.appendDynamicComponentData(this.mainContent, [HeadingQuestionsComponentData]);
    }

    if (this.data.showSummary) {
      this.summary = this.generateSummary(this.data);
    }

    if (bottomContentComponentsData) {
      super.appendDynamicComponentData(this.bottomContent, bottomContentComponentsData);
    }
  }

  getBreadcrumbContent(): DynamicComponentData[] {
    return [
      {
        type: BreadcrumbComponent,
        content: { ...this.data.breadcrumb, isInArticlePage: true },
        typename: IBreadCrumbName,
      },
    ];
  }

  getThemeContent(): DynamicComponentData {
    return {
      type: ArticleThemeComponent,
      content: { ...this.data.taxonomy, extra: { isInPageArticle: true } },
      typename: TypeNameBlockTheme,
    };
  }
  getArticleAuthorContent(): DynamicComponentData {
    return {
      type: ArticleAuthorComponent,
      content: { ...this.data.metaInfo.author },
      typename: '',
    };
  }

  clearPage(): void {
    this.topContent?.clear();
    this.breadcrumbContent?.clear();
    this.mainContent?.clear();
    this.bottomContent?.clear();
    this.beforeFooterContent?.clear();
    this.ctaDesktopContent?.clear();
    this.ctaMobileContent?.clear();
  }

  /**
   * Cover only should be visible on mobile if exist and coverVisibleOnMobile is true and showPostHeader is true.
   */
  isCoverVisible(): boolean {
    return this.data.cover && !this.bigViewPort && this.data.coverVisibleOnMobile && !this.data.showPostHeader;
  }

  private generateSummary(data: IPageArticle) {
    return data.blocks.reduce<ISummaryElement[]>((acc, block) => {
      // fetch all subBlocks
      if (block.__typename === TypeNameBlockTexts) {
        block['subBlocks'].forEach(subBlock =>
          this.generateSummaryEntity(acc, {
            title: subBlock['title'],
            id: subBlock['id'],
          })
        );
      } else if (!block.__typename) {
        this.generateSummaryEntity(acc, {
          title: block['nameFront'],
          id: block['targetId'],
        });
      } else {
        this.generateSummaryEntity(acc, {
          title: block['title'],
          id: block['id'] as number,
        });
      }
      return acc;
    }, []);
  }

  private generateSummaryEntity(acc: ISummaryElement[], entity: ISummaryElement) {
    // Do not generate menu entry without id nor title
    if (entity['title'] && entity['id']) {
      acc.push(entity);
    }
  }

  private getBottomContent(data: DynamicComponentData[]): DynamicComponentData[] | null {
    return data?.filter(componentData => this.isBottomContent(componentData?.typename));
  }

  private isBottomContent(typename: string): boolean {
    return [TypeNameBlocNewsletter].includes(typename);
  }

  ngOnDestroy(): void {
    this.doUnsubscribe();
  }
}
