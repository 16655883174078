import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IContactLinks } from './author.contact';

@Component({
  selector: 'nscf-author-contact',
  templateUrl: './author-contact.component.html',
  styleUrls: ['./author-contact.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [],
})
export class AuthorContactComponent {
  @Input() links?: IContactLinks;
}
