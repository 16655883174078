
import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LazyLoadMaterialDirective } from '../../directives/lazy-load-material/lazy-load-material.directive';
import { ILink } from '../../interfaces/link';
import { IParagraphCtaStickyWithQuestions } from '../../presenters/blocks/cta-sticky/cta-sticky.presenter';
import { UrlUtils } from '../../utils/url/url.utils';
import { QUESTIONS_DATA } from '../../values/form-parcours.values';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { HousingBuildValues, HousingTypeValues } from '../form-parcours/form-parcours';
import { FormParcoursQuestionComponent } from '../form-parcours/question/form-parcours-question.component';

@Component({
  selector: 'nscf-cta-sticky-with-questions',
  standalone: true,
  templateUrl: './cta-sticky-with-questions.component.html',
  styleUrls: ['./cta-sticky-with-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormParcoursQuestionComponent,
    CtaButtonComponent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadMaterialDirective,
    MatDividerModule
],
})
export class CtaStickyWithQuestionsComponent {
  protected readonly data = input.required<IParagraphCtaStickyWithQuestions>();

  private readonly questions = QUESTIONS_DATA;
  protected readonly firstQuestion = { ...this.questions[0], title: null };
  protected readonly secondQuestion = { ...this.questions[1], title: null };
  protected defaultHousingAge = HousingBuildValues.MoreThan15;
  protected readonly housingType = signal<HousingTypeValues>(null);
  protected readonly housingAge = signal<HousingBuildValues>(this.defaultHousingAge);

  protected readonly housingAges: { value: HousingBuildValues; label: string }[] = [
    { value: HousingBuildValues.LessThan2Year, label: '- de 2 ans' },
    { value: HousingBuildValues.Between2and15, label: 'Entre 2 et 15 ans' },
    { value: HousingBuildValues.MoreThan15, label: '+ de 15 ans' },
  ];

  protected readonly uri = computed(() => this.computeUri());

  protected onHousingTypeChange(housingType: HousingTypeValues): void {
    this.housingType.set(housingType);
  }

  protected onHousingAgeChange(housingAge: HousingBuildValues): void {
    this.housingAge.set(housingAge);
  }

  private computeUri(): ILink {
    const url = UrlUtils.generateUrl(this.data().cta.uri, {
      housingAge: this.housingAge(),
      ...(this.housingType() ? { housingType: this.housingType() } : {}),
    });
    return { url };
  }
}
