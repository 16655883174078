import { isPlatformBrowser, Location } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Request, Response } from 'express';
import { LoggerService } from '../services/loggers';
import { REQUEST, RESPONSE } from '../tokens/express.tokens';

@Injectable({
  providedIn: 'root',
})
export class GetUrlService {
  private readonly UrlSegment: string;
  private readonly UrlAnchor: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    @Optional() private readonly logger: LoggerService,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Optional() @Inject(RESPONSE) private readonly response: Response,
    @Optional() @Inject(REQUEST) private readonly request: Request
  ) {
    [this.UrlSegment, this.UrlAnchor] = this.location.path(true).split('#');
  }

  getResponse() {
    return this.response;
  }

  getRequest() {
    return this.request;
  }

  getUrlSegment(): string {
    return this.UrlSegment.split('?')[0];
  }

  getUrlAnchor(): string {
    return this.UrlAnchor ? this.UrlAnchor.split('?')[0] : this.UrlAnchor;
  }

  addUrlQueryParams(params: any, removeParams?: boolean): void {
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: removeParams ? null : params,
      queryParamsHandling: removeParams ? '' : 'merge',
    });
    this.location.go(urlTree.toString());
  }

  redirectWithHttpCode(code: number, url: string): void {
    if (isPlatformBrowser(this.platformId)) {
      // browser redirection without status code when using npm start
      this.redirectionBrowser(url);
    } else {
      // server redirection with request status
      this.redirectionSSR(code, url);
    }
  }

  private redirectionBrowser(url: string): void {
    window.location.href = url;
  }

  private redirectionSSR(code: number, url: string): void {
    if (this.request.url !== url) {
      this.logger.info(`Redirecting from ${this.request.url} to ${url} with ${code}`);
      this.response.redirect(code, url);
      this.response.end();
    }
  }
}
