import { DynamicComponentData } from '../../interfaces';
import { IPageArticle, IPageWork } from '../../interfaces/pages';
import { TypeNameBlockCtaStickyWithQuestions } from '../cta-sticky-with-questions/cta-sticky-with-questions';
import { CtaStickyComponent } from './cta-sticky.component';

export const TypeNameBlockCtaSticky = 'ParagraphBlocCtaSticky';

export const getCtaStickyDynamicComponentData = (data: IPageWork | IPageArticle): DynamicComponentData[] | null => {
  const ctaStickyBlock = data?.blocks?.find(block => block.__typename === TypeNameBlockCtaSticky);
  const ctaStickyWithQuestionsBlock = data?.blocks?.find(block => block.__typename === TypeNameBlockCtaStickyWithQuestions);
  const hasCtaStickyBlock = !!ctaStickyBlock || !!ctaStickyWithQuestionsBlock;

  return hasCtaStickyBlock
    ? [
        {
          type: CtaStickyComponent,
          content: {
            extra: {
              data: { ...(ctaStickyBlock ?? ctaStickyWithQuestionsBlock), shouldDisplayCtaWithQuestions: !!ctaStickyWithQuestionsBlock },
            },
          },
          typename: !!ctaStickyBlock ? TypeNameBlockCtaSticky : TypeNameBlockCtaStickyWithQuestions,
        },
      ]
    : null;
};
