import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CtaActionDirective } from '../../directives/cta-action/cta-action.directive';
import { ImagesService } from '../../services/images-service/images.service';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { LiseretComponent } from '../liseret/liseret.component';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { TopPageCarouselComponent } from './carousel/carousel.component';
import { ITopPage } from './top-page';

@Component({
  selector: 'nscf-top-page',
  templateUrl: 'top-page.component.html',
  styleUrls: ['top-page.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    TitleComponent,
    CtaActionDirective,
    CtaButtonComponent,
    LiseretComponent,
    TopPageCarouselComponent,
    TrustpilotMicroStarComponent,
  ],
})
export class TopPageComponent implements OnInit {
  @Input() data: ITopPage;
  @Input() isBrowser: boolean;
  @Input() isOverMobile: boolean;

  urlCover: any;

  constructor() {
  }

  ngOnInit(): void {
    if (this.isOverMobile) {
      this.urlCover = ImagesService.setBackGroundCoverUrl(this.data.mediaCover);
    }
  }
}
