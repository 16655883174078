<div class="focus-categorie">
  <div class="container">
    <div class="focus-categorie__header">
      <h2 class="focus-categorie__title">{{ data.title }}</h2>
      @if (data.title) {
        <nscf-liseret class="focus-categorie__liseret"></nscf-liseret>
      }
      <p class="focus-categorie__description">{{ data.chapo }}</p>
    </div>
  </div>
  <div class="container first-container">
    <div class="focus-categorie__primary-card">
      <a class="focus-categorie__link" [href]="data.mainArticle.url">
        <div class="focus-categorie__wrapper-content-primary-card">
          @if (data.mainArticle.theme) {
            <p effy-hashtag [size]="hashtagSizeMedium" [color]="hashtagStylePrimary" class="tw-mb-[8px]">
              {{ '#' + data.mainArticle.theme }}
            </p>
          }
          <p class="focus-categorie__primary-card-title">
            {{ data.mainArticle.title }}
          </p>
        </div>
        @if (data.mainArticle.cover?.url) {
          <picture class="focus-categorie__illustration">
            <source [attr.srcset]="data.mainArticle.cover?.webpUrl" />
            <img [attr.src]="data.mainArticle.cover?.url" [attr.alt]="data.mainArticle.cover?.alt" loading="lazy" />
          </picture>
        } @else {
          <div class="focus-categorie__primary-without-image"></div>
        }
      </a>
    </div>
  </div>
  <div class="container">
    <div class="focus-categorie__secondaries-card">
      @for (secondaryArticle of data.secondariesArticles; track secondaryArticle; let index = $index) {
        <div class="focus-categorie__secondaries-card-item focus-categorie__secondaries-card-item-{{ index }}">
          <a class="focus-categorie__link" [href]="secondaryArticle.url">
            <div class="focus-categorie__secondary-card-wrapper">
              <div class="wrapper-secondary-card">
                @if (secondaryArticle?.cover?.url) {
                  <picture>
                    <source [attr.srcset]="secondaryArticle.cover?.webpUrl" />
                    <img [attr.src]="secondaryArticle.cover?.url" [attr.alt]="secondaryArticle.cover?.alt" loading="lazy" />
                  </picture>
                } @else {
                  <div class="focus-categorie__secondary-without-image"></div>
                }
              </div>
              @if (data.mainArticle.theme) {
                <p effy-hashtag [size]="hashtagSizeMedium" [color]="hashtagStylePrimary" class="tw-mb-[4px] tw-mt-[16px]">
                  {{ '#' + secondaryArticle?.theme }}
                </p>
              }
              <p class="focus-categorie__secondary-card-title" [ngClass]="{ 'no-theme': !secondaryArticle?.theme }">
                {{ secondaryArticle?.title }}
              </p>
            </div>
          </a>
        </div>
      }
    </div>
  </div>
</div>
