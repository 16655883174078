import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IHeader } from '@effy-tech/acquisition-core/header';
import { EMPTY, iif, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RequestName } from '../../graphql/graphql';
import { GetDataService } from '../../graphql/graphql.service';
import { IPage } from '../../interfaces/pages';
import { ConfigService } from '../../modules/config/config.service';
import { NewHeaderPresenter } from '../../presenters/commons/header';
import { MainPagesPresenter } from '../../presenters/main-pages.presenter';
import { DeviceManagerService } from '../device-manager/device-manager.service';
import { LoggerService } from '../loggers/logger.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  constructor(
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly http: HttpClient,
    private readonly configService: ConfigService,
    private readonly deviceManagerService: DeviceManagerService,
    private readonly getDataService: GetDataService,
    private readonly location: Location
  ) {}

  public getData(keyValue: string): Observable<any> {
    const url = this.getUrlPreview(keyValue);
    return this.http.get(url).pipe(
      mergeMap((result: any) => {
        const pageRowData = JSON.parse(result.data.previewGraphQLField).previewNode;
        const targetId = pageRowData.fieldHeader?.entity?.fieldMenu?.targetId;
        return iif(
          () => pageRowData,
          this.getDataService.getRequestData(RequestName.MENU_REQ, { name: targetId }).pipe(
            map(menuHeaderResult => {
              return {
                pageRowData: pageRowData,
                previewGraphQLUrlCallback: result.data.previewGraphQLUrlCallback,
                menuHeaderResult,
              };
            })
          ),
          EMPTY
        );
      }),
      catchError(e => this.catchError(e, this.router))
    );
  }

  private getUrlPreview(keyValue): string {
    return `${this.configService.getEnvironment().graphqlApiEndpoint}?queryId=${this.configService.getQueryVersionId()}:${
      this.configService.getQueriesId().PREVIEW
    }&key=${keyValue}&mode=preview`;
  }

  private catchError(error: any, router: Router): any {
    this.logger.error('PreviewService: Erreur de récupération de data', error);
    router.navigate(['']);
    return EMPTY;
  }

  parseData(rowPageData: any, rowHeaderData: any): IPage {
    const mainPagesPresenter = new MainPagesPresenter(this.logger, this.location);
    const headerData: IHeader = NewHeaderPresenter.getHeader(rowPageData?.fieldHeader, rowHeaderData, this.configService.getEnvironment());
    return {
      ...mainPagesPresenter.parse({ ...rowPageData }),
      header: {
        ...headerData,
        isDesktop$: this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.bigViewPort)),
      },
    };
  }
}
