@if (data && data.name && !data.isEditorialTeam) {
  <div class="author">
    <div class="author__container">
      @if (data.imgSrc) {
        <div class="d-inline author__image-container">
          <img class="author__image" width="65" height="65" [src]="data.imgSrc" [alt]="data.imgAlt" loading="lazy" />
        </div>
      }
      <div class="author__info-container">
        <div class="author__info">
          <p class="author__name">
            @if (data.link) {
              <a [href]="data.link">{{ data.name }}</a>
            } @else {
              {{ data.name }}
            }
          </p>
          @if (data.job) {
            <p class="author__job">{{ data.job }}</p>
          }
        </div>
        @if (data.details) {
          <p class="author__detail">{{ data.details }}</p>
        }
      </div>
    </div>
  </div>
}
