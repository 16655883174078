export const INTERNAL_ICONS = [
  'icon-trustpilot-white',
  'icon-trustpilot-black',
  'quote-icon',
  'quote-icon-2',
  'money-icon',
  'world-icon',
  'icon-location',
  'logo-GPPEP',
  'check-gold',
  'self-consumption-icon',
  'arrow-left',
  'arrow-right',
  'heart-hand',
  'icon-house-in-build',
  'icon-documents',
  'icon-power',
  'icon-warranty',
  'icon-stats',
];//Effy front icons(Better performance)

type InternalIcon = typeof INTERNAL_ICONS[number];
export type Icon = InternalIcon;