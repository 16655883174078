import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'nscf-reading-time',
  templateUrl: './reading-time.component.html',
  styleUrls: ['./reading-time.component.scss'],
  standalone: true,
  imports: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReadingTimeComponent {
  @Input({ required: true }) time: string;
}
