@if (data) {
  <div class="container">
    <p class="tw-font-600 tw-text-2xl slg:tw-text-3xl slg:tw-mr-[40px] m-0">{{ data.text }}</p>
    @if (data.cta; as cta) {
      <a
        class="tw-shrink-0 tw-self-center"
        effy-button
        [attr.id]="cta.options?.attributes?.id"
        [attr.rel]="cta.options?.attributes?.rel"
        [attr.href]="cta.url | safeResourceUrl"
        >{{ cta.title }}</a
      >
    }
  </div>
}
