import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  IPageArticle,
  IPageGuide,
  IPageHub,
  IPageOffer,
  IPageWork,
  TypeNamePageArticle,
  TypeNamePageCategorie,
  TypeNamePageGuide,
  TypeNamePageHub,
  TypeNamePageManualHub,
  TypeNamePageOffer,
  TypeNamePageWork,
} from '../interfaces/pages';
import { LoggerService } from '../services/loggers';
import { ManualPageHubPresenter } from './pages/manual-page-hub.presenter';
import { PageArticlePresenter } from './pages/page-article.presenter';
import { PageCategoriePresenter } from './pages/page-categorie.presenter';
import { PageGuidePresenter } from './pages/page-guide.presenter';
import { PageHubPresenter } from './pages/page-hub.presenter';
import { PageOfferPresenter } from './pages/page-offer.presenter';
import { PageWorkPresenter } from './pages/page-work.presenter';
import { IPagePresenter } from './presenter';

@Injectable({
  providedIn: 'root',
})
export class MainPagesPresenter implements IPagePresenter {
  constructor(private readonly logger: LoggerService, private readonly location: Location) {}

  parse(data: any): IPageArticle | IPageGuide | IPageOffer | IPageHub | IPageWork {
    let page = null;
    let pagePresenter = null;

    // determine Presenter according to the typename
    switch (data['__typename']) {
      // Article
      case TypeNamePageArticle:
        pagePresenter = new PageArticlePresenter(this.logger, this.location);
        break;

      // Guide
      case TypeNamePageGuide:
        pagePresenter = new PageGuidePresenter();
        break;

      // Offer
      case TypeNamePageOffer:
        pagePresenter = new PageOfferPresenter(this.logger);
        break;

      // Page Travaux
      case TypeNamePageWork:
        pagePresenter = new PageWorkPresenter(this.logger);
        break;

      // Hub
      case TypeNamePageHub:
        pagePresenter = new PageHubPresenter();
        break;

      // Hub Manuelle
      case TypeNamePageManualHub:
        pagePresenter = new ManualPageHubPresenter();
        break;

      // Categorie
      case TypeNamePageCategorie:
        pagePresenter = new PageCategoriePresenter(this.logger);
        break;

      default:
        this.logger.warn(`Page Presenter not found (typename "${data['__typename']}"), ${data?.toString()}`);
        break;
    }

    // parse the page data
    if (pagePresenter) {
      page = pagePresenter.parse(data);
    }

    return page;
  }
}
