import { Location, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Response } from 'express';
import { find, forEach, indexOf, take } from 'lodash-es';
import { from } from 'rxjs';
import { LoadNewPageDirective } from '../../directives/load-new-page/load-new-page.directive';
import { GetUrlService } from '../../graphql/getUrl.service';
import { GetDataService } from '../../graphql/graphql.service';
import { ICardificationResponse } from '../../interfaces/cardification-response';
import { ICardSummary } from '../../interfaces/cards-summary';
import { IQueryVariables } from '../../interfaces/themes-query-variables';
import { CardificationHubPresenter, removeCurrentPageFromCards } from '../../presenters/cardifications/cardification-hub.presenter';
import { CardificationService, NUMBER_CARDS_PER_PAGE } from '../../services/cardification/cardification.service';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { StaticMetaTagsService } from '../../services/meta-tags';
import { RESPONSE } from '../../tokens/express.tokens';
import { CardSummarySimplifiedComponent } from '../card-summary-simplified/card-summary-simplified.component';
import { TitleComponent } from '../title/title.component';
import { IPagerLinks } from './pager';
import { PagerComponent } from './pager/pager.component';
import { IThemesHub } from './themes-hub';
import { EffyButtonComponent } from '@effy-tech/angular-common';

@Component({
  selector: 'nscf-themes-hub',
  templateUrl: 'themes-hub.component.html',
  styleUrls: ['themes-hub.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, TitleComponent, NgFor, CardSummarySimplifiedComponent, LoadNewPageDirective, PagerComponent, EffyButtonComponent],
})
export class ThemesHubComponent implements OnInit {
  @Input() data: IThemesHub;
  @Input() bigViewPort: boolean;
  currentPage = 0;
  targetIds: Array<string>;
  pageSize: number;
  numberOfPages: number;
  queryVariables: IQueryVariables;
  urlSegment: string;
  paginatedCards: Array<ICardSummary>;
  displayButton: boolean;
  pagerLinks: IPagerLinks;
  disableIntersection: boolean;

  constructor(
    private readonly getDataService: GetDataService,
    private readonly cardificationService: CardificationService,
    @Optional() @Inject(RESPONSE) private readonly response: Response,
    private readonly activatedRoute: ActivatedRoute,
    private readonly getUrlService: GetUrlService,
    private readonly location: Location,
    private readonly deviceManagerService: DeviceManagerService,
    private readonly metaServiceStatic: StaticMetaTagsService,
    @Inject(PLATFORM_ID) readonly platformId: Object
  ) {
    this.checkUrlQueryParams();
  }

  ngOnInit() {
    this.urlSegment = this.getUrlService.getUrlSegment();
    this.targetIds = [this.data.themeId];
    this.setQueryVariables();
    this.pageSize = NUMBER_CARDS_PER_PAGE;
    this.cardificationService.setPageSize(this.pageSize);
    this.getCards();
  }

  getCards(): void {
    const queryVariables: IQueryVariables = {
      theme: this.queryVariables.theme,
      page: this.queryVariables.page || 0,
      pageSize: this.queryVariables.pageSize || 90,
    };
    const subscription = this.getDataService
      .getCardificationData(queryVariables, {
        isMulti: this.data.multithemes,
        isPromoted: this.data.promoted,
      })
      .subscribe(data => {
        this.buildCardification(data.data);
        this.displayButton = this.data.multithemes && this.cardificationService.getTotalCards() > 4;
        this.cardificationService.setCurrentPage(this.currentPage);
        this.subscribeToCurrentPage();
        setTimeout(() => {
          subscription.unsubscribe();
        }, 100);
      });
  }

  isStepNumberPerPageSizeInteger(stepNumber, pageSize) {
    return Number.isInteger(stepNumber / pageSize);
  }

  setPaging(): void {
    this.paginatedCards = take(this.data.blocks.cardsSummary, (this.currentPage + 1) * this.pageSize);
    this.numberOfPages = Math.ceil(this.cardificationService.getTotalCards() / this.pageSize);
    if (this.currentPage < this.numberOfPages) {
      const urlParams = this.currentPage === 0 ? null : { page: this.currentPage + 1 };
      this.getUrlService.addUrlQueryParams(urlParams, false);
    } else if (this.currentPage && this.numberOfPages && this.currentPage >= this.numberOfPages) {
      this.response.location(this.urlSegment + (this.numberOfPages > 1 ? '?page=' + this.numberOfPages : ''));
      this.response.status(302);
    } else {
      return;
    }
  }

  private checkUrlQueryParams(): void {
    const page = this.activatedRoute.snapshot.queryParams.page;
    if (!page && page !== '0') {
      return;
    }
    this.currentPage = parseInt(page, 10) - 1;
    if (this.currentPage <= 0) {
      this.getUrlService.redirectWithHttpCode(301, this.location.path()?.split('?')[0]);
    }
  }

  private buildCardification(data: ICardificationResponse): void {
    const currentSegment: string = this.getUrlService.getUrlSegment();
    const cardsData = CardificationHubPresenter.mapCardBoToSimplifiedCardsHub(data, this.data.multithemes, this.data.promoted);
    this.data.blocks.cardsSummary = removeCurrentPageFromCards(currentSegment, cardsData.cards);
    this.cardificationService.setTotalCards(cardsData.count);
    this.setPaging();
    this.setMetaTag();
    this.buildPagerLinks();
  }

  private setQueryVariables(): void {
    const pageParam = this.activatedRoute.snapshot.queryParams.page;

    if (pageParam) {
      this.disableIntersection = true;
    }

    const monothemePageSize = pageParam ? NUMBER_CARDS_PER_PAGE : 300;
    const queriedPage = this.data.multithemes || !pageParam ? 0 : pageParam - 1;

    this.queryVariables = {
      theme: this.targetIds,
      page: queriedPage,
      pageSize: this.data.multithemes ? 4 : monothemePageSize,
    };
  }

  private setMetaTag(): void {
    this.metaServiceStatic.addTag({
      rel: 'canonical',
      href: this.deviceManagerService.getLocation() + this.location.path(),
    });

    if (this.currentPage > 0) {
      let prevHref = this.urlSegment;
      if (this.currentPage > 1) {
        prevHref = prevHref + '?page=' + this.currentPage;
      }
      this.metaServiceStatic.addTag({
        rel: 'prev',
        href: prevHref,
      });
    }

    if (this.currentPage < this.numberOfPages - 1) {
      this.metaServiceStatic.addTag({
        rel: 'next',
        href: this.urlSegment + '?page=' + (this.currentPage + 2),
      });
    }
  }

  private subscribeToCurrentPage(): void {
    if (!this.data.blocks?.cardsSummary?.length) {
      return;
    }
    const page = from(this.cardificationService.currentPage);
    page.pipe().subscribe((newPage: number) => {
      if (newPage < this.numberOfPages) {
        this.currentPage = newPage;
        this.setPaging();
        this.buildPagerLinks(newPage);
        this.setMetaTag();
      }
    });
  }

  private buildPagerLinks(currentPage?: number) {
    if (!currentPage) {
      currentPage = this.currentPage;
    }

    const lengthOfPager = this.numberOfPages > 10 ? 10 : this.numberOfPages;
    const numberOfPagerRange = parseInt((this.numberOfPages / lengthOfPager).toString(), 10);

    const ranges = [];

    let iRange = 0;
    let currentP = 1;
    for (iRange; iRange <= numberOfPagerRange; iRange++) {
      const range = [];
      let ii = 1;
      for (ii; ii <= lengthOfPager; ii++) {
        if (currentP <= this.numberOfPages) {
          range.push(currentP++);
        }
      }

      ranges.push(range);
    }

    const arr = find(ranges, r => {
      return indexOf(r, currentPage + 1) > -1;
    });

    const displayFirstHref = ranges.length > 1 && currentPage + 1 > lengthOfPager;
    const displayPrevHref = currentPage > 0 && this.numberOfPages > 1;
    const displayNextHref = this.numberOfPages > 1 && currentPage + 1 !== this.numberOfPages;
    const displayLastHref = this.numberOfPages > 2 && indexOf(ranges, arr) < ranges.length - 1 && this.numberOfPages > lengthOfPager;

    // Si le bouton first href n'est pas affiché, on ne veut pas le param page=1
    const prevHref = this.urlSegment + (currentPage > 1 ? '?page=' + currentPage : '');

    this.pagerLinks = {
      numberOfPages: this.numberOfPages,
      firstHref: displayFirstHref ? this.urlSegment : '',
      prevHref: displayPrevHref ? prevHref : '',
      nextHref: displayNextHref ? this.urlSegment + '?page=' + (currentPage + 2).toString() : '',
      lastHref: displayLastHref ? this.urlSegment + '?page=' + this.numberOfPages : '',
      links: [],
    };

    forEach(arr, p => {
      this.pagerLinks.links.push({
        href: this.urlSegment + (p > 1 ? '?page=' + p : ''),
        isCurrent: p === currentPage + 1,
        label: p.toString(),
      });
    });
  }
}
