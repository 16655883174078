<a class="card-summary-simplified" [attr.href]="data?.link?.url" alt="">
  @if (data.image) {
    <picture>
      <source [attr.srcset]="data.imageWebp" />
      <img [attr.src]="data.image" [attr.alt]="data.imageAlt" loading="lazy" height="164" width="350" />
    </picture>
  } @else {
    <ng-container *ngTemplateOutlet="img_placeholder"></ng-container>
  }
  <div class="card-summary-simplified__container">
    @if (data.taxonomies) {
      <p class="card-summary-simplified__taxonomy">
        @for (taxonomy of data.taxonomies; track taxonomy) {
          <span effy-hashtag>#{{ taxonomy | lowercase }}&nbsp;</span>
        }
      </p>
    }
    @if (data.title) {
      <p class="card-summary-simplified__title" [innerHTML]="data.title | safeHtml"></p>
    }
    @if (data.description) {
      <p class="card-summary-simplified__description">
        {{ data.description }}
      </p>
    }
  </div>

  <div>
    <a
      effy-tiny-button
      class="tw-absolute tw-right-[24px] tw-bottom-[24px]"
      [size]="tinyButtonSize.SMALL"
      [arrowRight]="true"
      [href]="data?.link?.url"
      >Lire</a
    >
  </div>
</a>

<ng-template #img_placeholder>
  <div class="card-summary-simplified__img_placeholder">
    <img src="assets/backgrounds/placeholders/media_emptyph.svg" alt="e bleu foncé sur fond gris clair" loading="lazy" />
  </div>
</ng-template>
