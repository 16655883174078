import { NgClass, NgTemplateOutlet } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, input, Input, OnInit } from '@angular/core';
import { SummaryScrollDirective } from '../../directives/summary-scroll/summary-scroll.directive';
import { getAlphabeticalString } from '../../utils/string/string.utils';

@Component({
  selector: 'nscf-title',
  templateUrl: 'title.component.html',
  styleUrls: ['title.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, SummaryScrollDirective, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent implements OnInit {
  @Input() title: string;
  @Input() addedClass: string;
  @Input() id = '';
  @Input() selector = 'h2';

  shouldDisplaySeparator = input(false, { transform: booleanAttribute });

  ngOnInit(): void {
    if (!this.id && this.title) {
      this.id = this.getFallbackId(this.title);
    }
  }

  private getFallbackId(title: string): string {
    return getAlphabeticalString(title, '-');
  }
}
