<a class="card" [href]="url">
  <div class="card__cover-container">
    @if (img) {
      <img [src]="img" [alt]="imgAlt" loading="lazy" class="card__cover" />
    } @else {
      <div class="card__cover-placeholder"></div>
    }
  </div>
  <div class="card__texts-container">
    @for (taxonomy of taxonomies; track taxonomy) {
      <span effy-hashtag> #{{ taxonomy | lowercase }} </span>
    }
    <p class="card__texts-title" [innerHTML]="title"></p>
  </div>
</a>
