import { NgFor, NgIf } from '@angular/common';
import { Component, computed, inject, Input, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatExpansionModule } from '@angular/material/expansion';
import { EffyCollapseComponent, EffyCollapseSize } from '@effy-tech/angular-common';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { PageScrollingService } from '../../services/page-scrolling/page-scrolling.service';
import { RichResultsService } from '../../services/rich-results/rich-results.service';
import { SchemaTypes } from '../../services/rich-results/rich-results.values';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { IFaq, IFaqContent } from './faq';

@Component({
  selector: 'nscf-faq-component',
  templateUrl: 'faq.component.html',
  styleUrls: ['faq.component.scss'],
  standalone: true,
  imports: [NgIf, TitleComponent, RteComponent, MatExpansionModule, NgFor, EffyCollapseComponent],
  host: {
    '[class]': 'hostClasses()',
  },
})
export class FaqComponent implements OnInit {
  @Input() data: IFaq;
  readonly isMobileOrTablet = toSignal(inject(DeviceManagerService).isMobileOrTablet());
  readonly size = computed(() => (this.isMobileOrTablet() ? EffyCollapseSize.SMALL : EffyCollapseSize.MEDIUM));
  private readonly pageScrollingService = inject(PageScrollingService);
  private readonly richResultsService = inject(RichResultsService);
  protected readonly hostClasses = computed(() => `container faq faq-${this.size()}`);

  ngOnInit(): void {
    this.richResultsService.setJsonLd(SchemaTypes.FAQ_PAGE, this.data.content);
  }

  toggle(content: IFaqContent, value: boolean, index: number) {
    if (value && index > 1) {
      setTimeout(() => {
        this.pageScrollingService.scrollTo('#faq_' + this.data.id + '_' + (index - 1), 160);
      }, 300);
    }
    content.openState = value;
  }
}
