<a [href]="link" effy-button [color]="buttonStyle()" [size]="buttonSize" class="tw-w-full smd:tw-w-fit">
  <mat-icon [svgIcon]="'account'" class="account-icon"></mat-icon>
  <div class="tw-flex tw-flex-col tw-text-left tw-whitespace-nowrap">
    <p data-cs-mask class="tw-text-xs tw-h-4 tw-mb-0 tw-font-300">{{ labelUser }}</p>
    <p class="tw-text-sm tw-font-600 tw-h-4 tw-mb-0 tw-flex tw-items-center tw-gap-[2px]">
      <span>{{ title }}</span>
      <mat-icon [svgIcon]="'chevron-right'" class="tw-w-3 tw-flex tw-flex-shrink-0"></mat-icon>
    </p>
  </div>
</a>
