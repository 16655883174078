@if (data && themeKeys.length) {
  <div class="article-theme" [class.container]="isInPageArticle">
    @if (!isInPageArticle) {
      <div class="article-theme__title">Thème(s) associé(s)</div>
    }
    <ul class="article-theme__list">
      @for (key of themeKeys | slice: 0:(showAllItems ? undefined : nbMaxItemDisplayedAtFirst); track key) {
        <li
          class="article-theme__item" (click)="navigateToHub('tag' + data[key].targetId)">
          @if (!data[key].hubUrl || !data[key].hubUrl.length) {
            <span>{{ data[key].nameFront }}</span>
          }
          @if (data[key].hubUrl && data[key].hubUrl.length) {
            <a [id]="'tag' + data[key].targetId"
              [href]="data[key].hubUrl">
              {{ data[key].nameFront }}
            </a>
          }
        </li>
      }
      @if (themeKeys.length > nbMaxItemDisplayedAtFirst && !showAllItems) {
        <li (click)="showAllItems = true"
        class="article-theme__item article-theme__item--show-more"></li>
      }
    </ul>
  </div>
}