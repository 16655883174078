@if (data?.questions?.length) {
  <p class="tw-text-2xl slg:tw-text-3xl tw-font-700 tw-mb-[16px] slg:tw-mb-[24px]">{{ data.title }}</p>
  <span class="tw-block tw-w-[60px] slg:tw-w-[100px] tw-h-[8px] tw-bg-secondary-color tw-mb-[16px] slg:tw-mb-[24px]"></span>
  @for (question of data.questions; track question.property; let isLast = $last) {
    <nscf-form-parcours-question
      [ngClass]="{ 'tw-mb-[16px]': !isLast }"
      (selectAnswer)="onChangeAnswer()"
      [question]="question"
    ></nscf-form-parcours-question>
  }
}
