import { ChangeDetectionStrategy, Component, HostListener, Input, OnInit } from '@angular/core';
import { INewTopPage } from '../../interfaces/new-top-page';
import { ImagesService } from '../../services/images-service/images.service';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { CardsComponent } from './cards/cards.component';

@Component({
  selector: 'nscf-new-top-page-card',
  templateUrl: 'new-top-page-card.component.html',
  styleUrls: ['new-top-page-card.component.scss'],
  standalone: true,
  imports: [TitleComponent, RteComponent, CtaButtonComponent, CardsComponent, TrustpilotMicroStarComponent],
  hostDirectives: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTopPageCardComponent implements OnInit {
  @Input() data: INewTopPage;
  @Input() isOverMobile: boolean;
  @Input() bigViewPort: boolean;
  urlCover: any;

  protected imageWidth = window.innerWidth / 2;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.imageWidth = window.innerWidth / 2;
  }

  constructor() {
  }

  ngOnInit(): void {
    this.urlCover = ImagesService.setBackGroundCoverUrl(this.data.mediaCover);
  }
}
