import { AsyncPipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestName } from '../../../graphql/graphql';
import { GetDataService } from '../../../graphql/graphql.service';
import { ICardSummary } from '../../../interfaces/cards-summary';
import { IQueryVariables } from '../../../interfaces/themes-query-variables';
import { CardificationActuThemePresenter } from '../../../presenters/cardifications/cardification-actu-theme.presenter';
import { LiseretComponent } from '../../liseret/liseret.component';
import { NewsCardComponent } from '../../news-cards/news-card.component';
import { getQueryVariables, IBlocActuTheme } from './actu-theme';

@Component({
  selector: 'nscf-actu-theme-component',
  templateUrl: 'actu-theme.component.html',
  styleUrls: ['actu-theme.component.scss'],
  standalone: true,
  imports: [LiseretComponent, NewsCardComponent, AsyncPipe],
})
export class ActuThemeComponent implements OnInit {
  @Input() data: IBlocActuTheme;
  @Input() isDesktop: boolean;
  cardsActu$: Observable<ICardSummary[]>;
  private readonly getDataService = inject(GetDataService);

  ngOnInit(): void {
    this.cardsActu$ = this.getCardsActu$(getQueryVariables(this.data?.taxonomy, this.data?.actualiteThemeId));
  }

  getCardsActu$(queryVariables: IQueryVariables): Observable<ICardSummary[]> {
    return this.getDataService
      .getRequestData(RequestName.ARTICLE_ACTU, queryVariables)
      .pipe(map(CardificationActuThemePresenter.mapDataToSummaryCards))
      .pipe(map(CardificationActuThemePresenter.mapCreatedToString))
  }
}
