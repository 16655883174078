import { AfterViewInit, Component, Input } from '@angular/core';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { IMap } from './map';

@Component({
    selector: 'nscf-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    standalone: true
})
export class MapComponent implements AfterViewInit {
  @Input() map: IMap;

  constructor(private readonly deviceManagerService: DeviceManagerService) {
  }

  ngAfterViewInit() {
    this.deviceManagerService.getCurrentDevice().subscribe(() => {
      if (this.deviceManagerService.isBrowser()) {
        if (this.map && this.map.region) {
          const map = document.querySelector(`#map${this.map.id}`);
          map?.querySelector(`#${this.map.region}`)?.classList.add('isActive');
        }
      }
    });
  }
}
