import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { EffyButtonComponent } from '@effy-tech/angular-common';
import { map } from 'rxjs/operators';
import { DeviceManagerService, DeviceType } from '../../services/device-manager/device-manager.service';
import { getQueryStringFromObject } from '../../utils/object/object.utils';
import { Parcours, ParcoursUrlUtils } from '../../utils/parcours/parcours.utils';
import { IParamsFromQuestion, IParamsFromQuestionAnswers } from '../form-parcours/form-parcours';
import { FormParcoursComponent } from '../form-parcours/form-parcours.component';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { IBlocTopPageForm } from './top-page-parcours';

@Component({
  selector: 'nscf-top-page-parcours',
  templateUrl: './top-page-parcours.component.html',
  styleUrls: ['./top-page-parcours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TitleComponent,
    NgClass,
    RteComponent,
    FormParcoursComponent,
    TrustpilotMicroStarComponent,
    TrustpilotMicroStarComponent,
    EffyButtonComponent,
  ],
  host: {
    class: 'container',
  },
})
export class TopPageParcoursComponent {
  @Input() data: IBlocTopPageForm;
  protected readonly isDesktop = toSignal(
    this.deviceManagerService.deviceType$().pipe(map((deviceType: DeviceType) => deviceType.isDesktop))
  );
  protected readonly description = computed(() => (this.isDesktop ? this.data.desktopDescription : this.data.mobileDescription));

  constructor(protected readonly deviceManagerService: DeviceManagerService) {
  }

  redirectToParcours(formAnswer: IParamsFromQuestion) {
    if (formAnswer.isValid) {
      window.open(this.getUrlRedirectionParcours(formAnswer.questionAnswers, this.data.parcours), '_self');
    }
  }

  private getUrlRedirectionParcours(formAnswer: IParamsFromQuestionAnswers, parcours: Parcours): string {
    const urlParcoursParams = getQueryStringFromObject(formAnswer);
    return `${ParcoursUrlUtils.computeParcoursBaseUrl(parcours)}?${urlParcoursParams}`;
  }
}
