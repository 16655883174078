@if (data.subBlocks && data.subBlocks.length) {
  @for (subBlock of data.subBlocks; track index; let index = $index; let first = $first) {
    @switch (subBlock.__typename) {
      @case (typeSBFocusBusiness) {
        <nscf-focus-business [bigViewPort]="bigViewPort" [element]="$any(subBlock)"></nscf-focus-business>
      }
      @case (typeSBImage) {
        <nscf-images [element]="$any(subBlock)"></nscf-images>
      }
      @case (typeSBCta) {
        <nscf-sub-block-cta [element]="$any(subBlock)"></nscf-sub-block-cta>
      }
      @case (typeSBQuestionCta) {
        <nscf-question-cta [data]="$any(subBlock)"></nscf-question-cta>
      }
      @case (typeSBTables) {
        <nscf-tables [element]="$any(subBlock)"></nscf-tables>
      }
      @case (typeSBTexts) {
        <nscf-texts
          (click)="setPdfLinkTarget($event)"
          [element]="$any(subBlock)"
          [isBrowser]="isBrowser"
          [isOverMobile]="isOverMobile"
          [isFirst]="first"
        ></nscf-texts>
      }
      @case (typeSBVideos) {
        <nscf-videos [element]="$any(subBlock)" [loadedMedias]="loadedMedias" [stepNumber]="index.toString()"></nscf-videos>
      }
    }
  }
}
