import { AsyncPipe, NgFor, NgIf, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import {
  EffyButtonComponent,
  EffyChoiceButtonComponent,
  EffyInputNumberComponent,
  EffySliderComponent,
  EffyTinyButtonComponent,
  EffyTinyButtonStyle,
} from '@effy-tech/angular-common';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, share, skipWhile, switchMap } from 'rxjs/operators';
import { CUSTOM_RANGE_PADDING, STEP_RANGE_AMOUNT } from '../../configs/default.config';
import { INPUT_NUMBER_DEFAULT_VALUE, inputNumberMax, inputNumberMin } from '../../configs/household-people.config';
import { LinePictosDefinitions } from '../../configs/icons-definitions.config';
import { WORKS_LABEL_VALUES } from '../../configs/labels/works.labels';
import { LOCATION_CONFIG } from '../../configs/location.config';
import { ICostEstimatorPrecarityLevel } from '../../models/cost-estimator.models';
import { PrecarityLevelApiResponseModel } from '../../models/precarity-level.models';
import { CommonAssetsService } from '../../services/common-assets/common-assets.service';
import { CostEstimatorService } from '../../services/cost-estimator/cost-estimator.service';
import { FormService } from '../../services/form.service';
import { PrecarityLevelService } from '../../services/precarity-level/precarity-level.service';
import { RedirectionService } from '../../services/redirection/redirection.service';
import { DestroyerBase } from '../../shared/destroyer/destroyer.base';
import { GuidUtils } from '../../utils/guid.utils';
import { LocationValues } from '../../values/location.values';
import { WorksValues } from '../../values/works.values';
import { WorkEstimateUtils } from './work-estimate-utils';
registerLocaleData(localeFr);

@Component({
  selector: 'calc-work-estimate',
  templateUrl: './work-estimate.component.html',
  styleUrls: ['./work-estimate.component.scss'],
  providers: [FormService],
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    NgFor,
    EffyTinyButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    EffyInputNumberComponent,
    EffySliderComponent,
    EffyChoiceButtonComponent,
    EffyButtonComponent,
    MatIconModule,
  ],
})
export class WorkEstimateComponent extends DestroyerBase implements OnInit {
  @Input() workSelectedValue: WorksValues;

  @Input() isPreselectedWork: boolean;

  @Output() workTypeReset = new EventEmitter<void>();

  generatedGuid = GuidUtils.newGuid();

  locationConfig = LOCATION_CONFIG;

  inputNumberDefaultValue = INPUT_NUMBER_DEFAULT_VALUE;

  inputNumberMin = inputNumberMin;

  inputNumberMax = inputNumberMax;

  rangeStep = STEP_RANGE_AMOUNT;

  precarityLevels$: Observable<PrecarityLevelApiResponseModel>;

  incomeRange$: Observable<IncomeRange>;
  costEstimatorPrecarityLevel$: Observable<ICostEstimatorPrecarityLevel>;

  estimationMyPrimeRenov$: Observable<string>;

  locationValue: string = LocationValues.OtherRegions;
  private readonly formService = inject(FormService);
  private readonly location$ = this.formService.locationValue$.pipe(skipWhile(v => !v));

  constructor(
    readonly commonAssetsService: CommonAssetsService,
    private readonly costEstimatorService: CostEstimatorService,
    private readonly precarityLevelService: PrecarityLevelService,
    private readonly redirectionService: RedirectionService
  ) {
    super();
  }

  ngOnInit() {
    this.precarityLevels$ = combineLatest([this.location$, this.formService.houseHoldValue$.pipe(skipWhile(v => !v))]).pipe(
      distinctUntilChanged(),
      mergeMap(([location, houseHoldSize]) => this.precarityLevelService.getPrecarityLevel(location, houseHoldSize)),
      share()
    );

    this.incomeRange$ = combineLatest([this.precarityLevels$, this.formService.rangeValue$]).pipe(
      distinctUntilChanged(),
      map(([precarityLevel, value]) => WorkEstimateUtils.computeRange(precarityLevel, value, CUSTOM_RANGE_PADDING)),
      share()
    );

    this.costEstimatorPrecarityLevel$ = combineLatest([this.precarityLevels$, this.incomeRange$]).pipe(
      map(([precarityLevel, income]) => WorkEstimateUtils.computePrecarityLevel(income.value, precarityLevel))
    );

    this.estimationMyPrimeRenov$ = combineLatest([this.costEstimatorPrecarityLevel$, this.location$]).pipe(
      filter(() => !!this.workSelectedValue),
      switchMap(([precarityLevel, location]) => this.costEstimatorService.getEstimate$(this.workSelectedValue, precarityLevel, location))
    );
  }

  goToWorkSelection(): void {
    this.workTypeReset.emit();
  }

  onLocationValueChange(value: string): void {
    this.formService.setLocationValue(value as LocationValues);
  }

  onRangeValueChange(input: number): void {
    this.formService.setRangeValue(input);
  }

  onHouseHoldValue(value: number): void {
    this.formService.setHouseHoldeValue(value);
  }

  goToHelpsAndWorks(): void {
    this.redirectionService.redirectToHelpsAndWorks();
  }

  getWorkLabel(work: WorksValues): string {
    return WORKS_LABEL_VALUES[work];
  }

  public get LinePictosDefinitions() {
    return LinePictosDefinitions;
  }

  protected readonly EffyTinyButtonStyle = EffyTinyButtonStyle;
}

export interface IncomeRange {
  min: number;
  max: number;
  value: number;
}
