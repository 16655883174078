import { Component, Input } from '@angular/core';
import { EffyButtonComponent } from '@effy-tech/angular-common';
import { ILink } from '../../../interfaces/link';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';

@Component({
  selector: 'nscf-question-cta',
  templateUrl: 'question-cta.component.html',
  styleUrls: ['question-cta.component.scss'],
  standalone: true,
  imports: [EffyButtonComponent, SafeResourceUrlPipe],
})
export class QuestionCtaComponent {
  @Input() data: { text: string; cta: ILink };
}
