<div class="engagement stats-numbers">
  <div class="container numbers" [ngClass]="'numbers-' + size()">
    <nscf-title [id]="'anchor_' + data.id" [selector]="data.isFirstTitle ? 'h1' : 'h2'" [title]="data.title" class="numbers__title" />
    <div class="liseret"></div>
    <nscf-rte [data]="data.chapo" class="numbers__chapo" />
    <div class="numbers__cards-container">
      @for (cardNumber of data.cardsNumbers; track $index) {
        <div class="numbers__card">
          @if (cardNumber.image) {
            <div class="numbers__block-img">
              <img [alt]="cardNumber.image.alt" [src]="cardNumber.image.url" loading="lazy" />
            </div>
          }
          @if (cardNumber.title) {
            <p class="numbers__card-title">{{ cardNumber.title }}</p>
          }
          @if (cardNumber.chapo) {
            <p class="numbers__card-description">{{ cardNumber.chapo }}</p>
          }
        </div>
      }
    </div>
  </div>
</div>
