import { map } from 'lodash-es';
import { VideoGenerator } from '../../classes';
import { IMediasCardsVideo } from '../../components/medias/medias-card';
// import { ImagesService } from '../../services/images-service/images.service';

export class CardificationVideoPresenter {
  static mapNewsCardsVideo(results): Array<IMediasCardsVideo> {
    return []
  }
}
