import { EventEmitter, Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Observable, iif, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay } from 'rxjs/operators';
import { IMediasCarouselType } from '../../components/carousel/carousel';
import { IS_SITE_EFFY } from '../../components/medias/medias';
import { GetUrlService } from '../../graphql/getUrl.service';
import { CardificationOptions, RequestName } from '../../graphql/graphql';
import { GetDataService } from '../../graphql/graphql.service';
import { ICardSummary, ICardSummarySimplified } from '../../interfaces/cards-summary';
import { ITaxonomy, ITheme } from '../../interfaces/taxonomy';
import { ConfigService } from '../../modules/config/config.service';
import { CardificationHubPresenter } from '../../presenters/cardifications/cardification-hub.presenter';
import { CardificationMediaPresenter } from '../../presenters/cardifications/cardification-media.presenter';
import { CardificationNewsPresenter } from '../../presenters/cardifications/cardification-news.presenter';
import { CardificationSummaryPresenter } from '../../presenters/cardifications/cardification-summary.presenter';
import { CardificationVideoPresenter } from '../../presenters/cardifications/cardification-video.presenter';
import { LoggerService } from '../../services/loggers';

export const NUMBER_CARDS_PER_PAGE = 36;

@Injectable({
  providedIn: 'root',
})
export class CardificationService {
  pageSize = NUMBER_CARDS_PER_PAGE; // Default page size
  currentPage: EventEmitter<number> = new EventEmitter<number>();
  isFirstPage$ = this.currentPage.pipe(map(page => page === 0));
  private totalCards: number;
  isLastPage$ = this.currentPage.pipe(map(page => page === Math.ceil(this.totalCards / this.pageSize) - 1));

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    @Optional() @Inject(IS_SITE_EFFY) public readonly isSiteEffy: boolean | null,
    private readonly getUrlService: GetUrlService,
    private readonly logger: LoggerService,
    private readonly configurationService: ConfigService,
    private readonly getDataService: GetDataService
  ) {}

  setPageSize(pageSize: number): void {
    this.pageSize = pageSize;
  }

  setCurrentPage(page: number): void {
    this.currentPage.emit(page);
  }

  getTotalCards(): number {
    return this.totalCards;
  }

  setTotalCards(count: number): void {
    this.totalCards = count;
  }

  getNewsCards(
    themes: Array<string | number>,
    cardificationOptions?: CardificationOptions,
    numberOfCards?: number
  ): Observable<Array<ICardSummary>> {
    const isThemesId = themes.every(theme => !isNaN(+theme));
    return this.getTaxonomyData().pipe(
      mergeMap((visibleThemes: Array<ITheme>) => {
        // Check if themes is number and get id if it's not the case.
        const themesId: any = isThemesId
          ? themes
          : visibleThemes
              ?.filter(theme => {
                return themes.indexOf(theme.name) > -1;
              })
              .map(theme => theme.tid + '');
        return this.getDataService
          .getCardificationData(
            {
              theme: themesId,
              page: 0,
              pageSize: numberOfCards,
            },
            cardificationOptions
          )
          .pipe(
            map(cardificationData => {
              if (cardificationOptions.isTheme) {
                return CardificationHubPresenter.mapNewsCardsTheme(cardificationData);
              }
              return CardificationNewsPresenter.mapNewsCards(cardificationData, themes, isThemesId, visibleThemes);
            })
          );
      })
    );
  }

  getMediaCards(cardificationOptions?: CardificationOptions, numberOfCards?: number): Observable<Array<IMediasCarouselType>> {
    return this.getDataService
      .getMediaCardsData({
        theme: null,
        page: 0,
        pageSize: numberOfCards,
      })
      .pipe(
        map(results => {
          return CardificationMediaPresenter.mapMediasCards(results.data.graphQLCardsMedia.results, this.platformId);
        })
      );
  }

  getCardsVideo(): Observable<Array<IMediasCarouselType>> {
    return this.getDataService.getCardificationData(null, { isBlockVideo: true }).pipe(
      map(results => {
        return CardificationVideoPresenter.mapNewsCardsVideo(results);
      })
    );
  }

  getTaxonomyData(): Observable<ITheme[]> {
    return this.getDataService.getRequestData(RequestName.TAXONOMY_REQ).pipe(
      map(result => result?.data?.taxonomyTermQuery?.entities.filter(theme => theme?.name && theme?.visibility)),
      shareReplay()
    );
  }

  getCardificationByType$(cards: ICardSummarySimplified[], taxonomies: ITaxonomy[]) {
    return iif(() => this.isSiteEffy, this.getCardsSummary$(taxonomies), of(cards));
  }

  private getCardsSummary$(taxonomies: ITaxonomy[]): Observable<Array<ICardSummarySimplified>> {
    const currentThemeIds = Object.values(taxonomies).map(t => t.targetId);
    return this.getTaxonomyData().pipe(
      mergeMap((everyVisibleThemes: ITheme[]) => this.getDataService.getCardificationData(
        {
          theme: currentThemeIds,
          page: 0,
          pageSize: 12,
        },
        { isCardification: true }
      ).pipe(
        map(data => CardificationSummaryPresenter.mapCardificationSummary(
          data, 
          this.getUrlService.getUrlSegment(),
          currentThemeIds,
          everyVisibleThemes
        )),
        catchError(err => {
          this.logger.error('CardsSummaryComponent: error "getCardificationData()"', {
            url: this.getUrlService.getUrlSegment(),
            err: err.toString(),
          });
          return [];
        })
      ))
    );
  }
}

export function mapStringThemesToArray(themes: string): Array<string> {
  return themes.split(',').map(theme => theme.trim());
}
