<div class="work-estimate-wrapper">
  <div class="main-panel">
    <div class="panel-header">
        <a effy-tiny-button [color]="EffyTinyButtonStyle.PRIMARY"
           [arrowLeft]="true" *ngIf="!isPreselectedWork"
           (click)="goToWorkSelection()">
            <span class="work-title">
        {{ getWorkLabel(workSelectedValue) }}
      </span>
        </a>
    </div>

    <div class="form-section">
      <div class="form-section-header">
        <span class="section-header-title">Où habitez-vous ?</span>
      </div>
      <div class="form-section-content">
        <div class="switch-field">
          <ng-container *ngFor="let value of locationConfig">
              <effy-choice-button>
                  <input type="radio" [name]="value.name" [id]="value.value+generatedGuid"
                         [value]="value.value"
                         [ngModel]="locationValue"
                         (ngModelChange)="onLocationValueChange($event)"
                         [checked]="value.checked"/>
                  <label [for]="value.value+generatedGuid">{{ value.label }}</label>
              </effy-choice-button>
          </ng-container>
        </div>
      </div>
    </div>

      <div class="form-section">
        <div class="form-section-header">
          <span class="section-header-title">
            Combien de personnes composent votre foyer ?
          </span>
        </div>

        <div class="form-section-content">
            <effy-input-number [(ngModel)]="inputNumberDefaultValue"
                               (ngModelChange)="onHouseHoldValue($event)"
                               [min]="inputNumberMin"
                               [max]="inputNumberMax"/>
        </div>
      </div>

      <div class="form-section">
        <div class="form-section-header">
          <span class="section-header-title">
            Quels sont les revenus de votre foyer ?
          </span>
        </div>

        <div class="form-section-content">
          <ng-container *ngIf="incomeRange$ | async as range">
              <effy-slider [min]="range.min" [max]="range.max" [step]="rangeStep" [(ngModel)]="range.value" (ngModelChange)="onRangeValueChange($event)"/>
          </ng-container>
        </div>
      </div>
  </div>

  <div class="side-panel">
    <div class="side-panel-header">
        <p class="side-panel-title">Votre estimation<br/>MaPrimeRénov’</p>
        <p *ngIf="(estimationMyPrimeRenov$ | async) as estimationMyPrimeRenov" class="estim-amount">
            {{ estimationMyPrimeRenov }}
        </p>
    </div>
    
    <div class="info">
        @defer (on viewport) {
          <mat-icon class="info-icon" svgIcon="lightbulb"></mat-icon>
        } @placeholder {
          <span class="tw-size-6">icône</span>
        }
        <p class="info-text">
            Cumulez <span>MaPrimeRénov’</span>avec la <span>Prime Effy</span> pour financer vos travaux.
        </p>
    </div>

      <button effy-button type="button" (click)="goToHelpsAndWorks()">
          <span class="effy-btn-content-text">Je découvre mes primes</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.333252 10.3333L3.66659 6.99999L0.333252 3.66666L0.333252 0.333323L6.99992 6.99999L0.333252 13.6667L0.333252 10.3333Z"
                    fill="none">
              </path>
          </svg>
      </button>
  </div>
</div>