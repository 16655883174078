import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { LocationError } from '../graphql/graphql';
import { IQueryVariables } from '../interfaces/themes-query-variables';
import { LoggerService } from '../services/loggers';
import { ErrorType } from './error.model';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private readonly router: Router, private readonly loggerService: LoggerService) {
  }

  catchError(error: unknown, locationError?: LocationError, queryVariables?: IQueryVariables): any {
    if (locationError === LocationError.CARDIFICATION) {
      this.redirectToErrorPage(queryVariables)
    }
    if (locationError === LocationError.PRINCIPAL_QUERY) {
      return error === ErrorType.NOT_FOUND_CODE
        ? throwError(error + 'here' + (queryVariables?.path ? ` url requested : ${queryVariables?.path}` : ''))
        : this.redirectToErrorPage(queryVariables);
    }
    this.logError(locationError, queryVariables);
  }

  private redirectToErrorPage(queryVariables: IQueryVariables) {
    this.router.navigate(['erreur'], {queryParams: {from: queryVariables?.path}, skipLocationChange: true})
  }

  private logError(locationError: LocationError, queryVariables: IQueryVariables) {
    try {
      this.loggerService.error("Error service", `locationError: ${locationError} - queryVariables : ${JSON.stringify(queryVariables)}`)
    } catch {
      this.loggerService.error("Error service", `Error: parsing error`)
    }
  }
}

export interface ResponseError extends Error {
  status?: number;
}
