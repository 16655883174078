import { environment } from '@acq-environments/environment';
import { EffyFooterService } from '@acq-services/footer/footer.service';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_ID, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { InMemoryScrollingOptions, provideRouter, withEnabledBlockingInitialNavigation, withInMemoryScrolling } from '@angular/router';
import {
  BrowserStateInterceptor,
  ConfigModule,
  ConsoleLoggerService,
  FooterService,
  IS_SITE_EFFY,
  LoggerService
} from '@effy-tech/acquisition-core';
import { WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { DEFAULT_THEME, EFFY_ENVIRONMENT, provideEffyMaterial } from '@effy-tech/angular-common';
import { Theme } from '@effy-tech/design-system';
import { routes } from './app.routes';
import { errorsList } from './config/errors.config';
import { HEADER_DATA } from './config/header.config';
import { QueriesId_VALUES } from './config/queries';

const scrollConfig: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
};

registerLocaleData(localeFr);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    provideRouter(routes, withEnabledBlockingInitialNavigation(), withInMemoryScrolling(scrollConfig)),
    provideClientHydration(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    [
      { provide: LoggerService, useClass: ConsoleLoggerService },
      { provide: APP_ID, useValue: 'effy-front' },
      { provide: LOCALE_ID, useValue: 'fr' },
      { provide: IS_SITE_EFFY, useValue: true },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: BrowserStateInterceptor,
        multi: true,
      },
      {
        provide: FooterService,
        useClass: EffyFooterService,
      },
      { provide: EFFY_ENVIRONMENT, useValue: environment.env },
      { provide: DEFAULT_THEME, useValue: Theme.EFFY },
      importProvidersFrom(ConfigModule.forRoot(environment, HEADER_DATA, errorsList, WebsiteThemes.effy, QueriesId_VALUES)),
      DatePipe,
      provideEffyMaterial,
    ],
  ],
};
