import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MenuComponent } from '../menu/header-menu.component';
import { ILinkNavbar } from '../navbar/navbar';

@Component({
  selector: 'nscf-header-link',
  templateUrl: 'link.component.html',
  styleUrls: ['link.component.scss'],
  standalone: true,
  imports: [MenuComponent, MatIcon],
})
export class LinkComponent {
  @Input() link: ILinkNavbar;
  @Input() index: number;
  @Input() isDesktop: boolean;
  @Output() checked: EventEmitter<boolean> = new EventEmitter();

  onInputChecked(): void {
    this.checked.emit(true);
  }

  onGoBack(): void {
    this.checked.emit(false);
  }
}
