import { IBlockGuidCard } from '@acq-shared/block-guide/guide';
import { ILink, ISubBlockVideos } from '@effy-tech/acquisition-core';
import { HeaderStaticTypes } from '@effy-tech/acquisition-core/header';

export interface ICorpSubBlock extends ICorpCard {
  subtitle?: string;
  title: string;
  link?: ILink;
  cta?: ILink;
  videoData?: ISubBlockVideos;
  cards?: Array<ICorpCard> | Array<IBlockGuidCard>;
  values?: Array<ICorpCard>;
  isTextRight?: boolean;
  address?: string;
  legend?: string;
  galleryCards?: Array<ICorpCard>;
}

interface ICorpCard {
  title: string;
  description?: string;
  image?: ICorpImage;
  images?: Array<ICorpImage>;
}

export interface ICorpImage {
  desktop?: string;
  mobile?: string;
  alt?: string;
}

export enum CorpPages {
  OUR_TEAM = 'ourTeam',
  OUR_JOB = 'ourJob',
  OUR_FIGHT = 'ourFight',
  MAGAZINE = 'magazine',
  JOIN_US = 'joinUs',
  ABOUT_US = 'aboutUs',
}

export enum LinksPageCorp {
  OUR_MISSION = 'notre-mission',
  OUR_TEAM = 'notre-equipe',
  OUR_ENGAGEMENT = 'nos-engagements',
  MAGAZINE = 'notre-espace-presse',
  JOIN_US = 'rejoignez-nous',
  ABOUT_US = 'nous-connaitre',
}

export const metaDataPageCorp = {
  ourTeam: {
    metaTitle: 'Spécialiste de la rénovation énergétique | Notre équipe | Effy',
    meta: [
      {
        name: 'description',
        content:
          'Effy accompagne les ménages français dans leur projet de rénovation énergétique depuis 2008. Découvrez notre équipe et les différents services qui participent à la lutte contre la précarité énergétique. 93% de nos clients sont satisfaits.',
      },
    ],
  },
  ourJob: {
    metaTitle: 'Spécialiste de la rénovation énergétique | Nos métiers | Effy',
    meta: [
      {
        name: 'description',
        content:
          "Découvrez nos métiers au sein d'Effy, spécialiste de la rénovation énergétique. Chaque année, Effy accompagne plus de 100 000 ménages français. Depuis 12 ans, Effy lutte contre la précarité énergétique et propose des solutions clés en main pour améliorer le confort des habitations.",
      },
    ],
  },
  ourFight: {
    metaTitle: 'Spécialiste de la rénovation énergétique | Notre mission | Effy',
    meta: [
      {
        name: 'description',
        content:
          "Spécialisé dans l'accompagnement des travaux de rénovation énergétique, Effy propose des travaux clés en main et aide dans l'obtention des aides financières. Effy est engagé dans la lutte contre la précarité énergétique depuis 2008. 93% de nos clients sont satisfaits.",
      },
    ],
  },
  magazine: {
    metaTitle: 'Spécialiste de la rénovation énergétique | Espace presse | Effy',
    meta: [
      {
        name: 'description',
        content:
          'Découvrez les reportages et articles presse parlant de la rénovation énergétique. Découvrez nos prises de paroles dans la presse. 93% de nos clients sont satisfaits. Effy accompagne les ménages français dans leur projet de rénovation énergétique.',
      },
    ],
  },
  joinUs: {
    metaTitle: 'Spécialiste de la rénovation énergétique | Rejoignez-nous | Effy',
    meta: [
      {
        name: 'description',
        content:
          'Envie de rejoindre une équipe dynamique et participer à la lutte contre la précarité énergétique ? Découvrez Effy, spécialiste de la rénovation énergétique. Depuis plus de 12 ans, Effy accompagne les Français dans leurs projets de travaux et délivre une prime énergie.',
      },
    ],
  },
  aboutUs: {
    metaTitle: 'Spécialiste de la rénovation énergétique | Notre histoire | Effy',
    meta: [
      {
        name: 'description',
        content:
          'Depuis 2008, Effy est le 1er spécialiste de la rénovation énergétique. Nous conseillons et accompagnons les Français dans leurs travaux de rénovation énergétique : analyse des besoins, sélection d’artisans locaux de confiance, obtentions d’aides financières, contrôle de la qualité des travaux.',
      },
    ],
  },
};

export interface ICorpLink {
  url: string;
  label: string;
  id: string;
  liseretClass?: string;
  chevronBgClass?: string;
  bgImage?: string;
  pageName?: string;
  toDynamicPage?: boolean;
}

export const linksPageCorp = <Array<ICorpLink>>[
  {
    label: 'Notre histoire',
    url: '/nous-connaitre',
    liseretClass: 'active_NH',
    chevronBgClass: 'chevron_NH',
    pageName: CorpPages.ABOUT_US,
    bgImage: '../../../../assets/corps/desktop/Hero_histoire.webp',
  },
  {
    label: 'Notre mission',
    url: `/${LinksPageCorp.ABOUT_US}/${LinksPageCorp.OUR_MISSION}`,
    liseretClass: 'active_NC',
    chevronBgClass: 'chevron_NC',
    bgImage: '../../../assets/corps/desktop/hero_combat.webp',
    pageName: CorpPages.OUR_FIGHT,
  },
  {
    label: 'Nos engagements',
    url: `/${LinksPageCorp.ABOUT_US}/${LinksPageCorp.OUR_ENGAGEMENT}`,
    liseretClass: 'active_NM',
    chevronBgClass: 'chevron_NM',
    pageName: CorpPages.OUR_JOB,
    bgImage: '../../../assets/corps/desktop/hero_metier.webp',
  },
  {
    label: 'Notre équipe',
    url: `/${LinksPageCorp.ABOUT_US}/${LinksPageCorp.OUR_TEAM}`,
    liseretClass: 'active_NE',
    chevronBgClass: 'chevron_NE',
    bgImage: '../../../assets/corps/desktop/hero_equipe.webp',
    pageName: CorpPages.OUR_TEAM,
  },
  {
    label: 'Rejoignez-nous',
    url: `/${LinksPageCorp.ABOUT_US}/${LinksPageCorp.JOIN_US}`,
    liseretClass: 'active_NR',
    chevronBgClass: 'chevron_NR',
    bgImage: '../../../assets/corps/desktop/hero_nousrejoindre.webp',
    pageName: CorpPages.JOIN_US,
  },
  {
    label: 'Ils parlent de nous',
    url: `/${LinksPageCorp.ABOUT_US}/${LinksPageCorp.MAGAZINE}`,
    liseretClass: 'active_NEP',
    chevronBgClass: 'chevron_NEP',
    bgImage: '../../../assets/corps/desktop/hero_presse.webp',
    pageName: CorpPages.MAGAZINE,
    toDynamicPage: true,
  },
];

export function getStaticHeaderCorpType(url: string): HeaderStaticTypes {
  return url.includes(LinksPageCorp.MAGAZINE) ? HeaderStaticTypes.PageCorpMagazine : HeaderStaticTypes.PageCorp;
}
