@for (card of data.documents; track card) {
  <div (click)="openPdf(card.url)" class="article-files__card-container">
    <div class="article-files__image-container">
      <img [src]="card.image?.url || '../../../../assets/backgrounds/article-file.webp'" loading="lazy" alt="" />
    </div>
    <div class="article-files__content">
      <div class="article-files__title">
        {{ card.title }}
      </div>
      <nscf-liseret class="article-files__liseret" [isCentered]="!isDesktop"></nscf-liseret>
      <div class="article-files__info">
        {{ card.description }}
      </div>
      <div class="article-files__cta-container">
        <a effy-link [size]="linkSize" [href]="card.url" target="_blank">
          @defer (on viewport) {
            <mat-icon class="material-icons-outlined">visibility</mat-icon>
          } @placeholder {
            <span>icône</span>
          }
          Visualiser</a>
      </div>
    </div>
  </div>
}
