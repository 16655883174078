export enum Parcours {
  TravauxAides = 'travaux-aides',
  Isolation = 'isolation',
  Chauffage = 'pompe-a-chaleur',
  EnergeticAudit = 'audit-energetique'
}

export class ParcoursUrlUtils {
  static readonly PARCOURS_BASE_HREF = '/parcours/';

  static computeTravauxAides(): string {
    return `${ParcoursUrlUtils.computeParcoursBaseUrl(Parcours.TravauxAides)}`;
  }

  static computeIsolation(): string {
    return `${ParcoursUrlUtils.computeParcoursBaseUrl(Parcours.Isolation)}`;
  }

  static computePompeAChaleur(): string {
    return `${ParcoursUrlUtils.computeParcoursBaseUrl(Parcours.Chauffage)}`;
  }

  static computeSimulator(): string {
    return '/simulateur/bilan-energetique';
  }

  static computeSolaire(): string {
    return `/formulaire/solaire/1/`;
  }

  static computeAuditEnergetique(): string {
    return `${ParcoursUrlUtils.computeParcoursBaseUrl(Parcours.EnergeticAudit)}`;
  }

  static computeParcoursBaseUrl(parcours: Parcours, variation = '1'): string {
    return `${ParcoursUrlUtils.PARCOURS_BASE_HREF}${variation}/${parcours}`;
  }
}