@if (data) {
  <div class="eligible-renovations">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-12 m-auto">
          <nscf-title
            [shouldDisplaySeparator]="true"
            [selector]="data.isFirstTitle ? 'h1' : 'h2'"
            class="text-center tw-flex tw-flex-col tw-items-center"
            [ngClass]="data.subtitle ? '' : 'h2-title--without-subtitle'"
            [title]="data.title"
            [id]="'anchor_' + data.id"
          />
          <p [ngClass]="data.title ? '' : 'h2-title--without-title'" class="eligible-renovations__main-subtitle">
            {{ data.subtitle }}
          </p>
          @if (isBrowser) {
            <div>
              @if (data.renovationTypes?.length > 1) {
                @if (isDesktop()) {
                  <div class="tw-flex tw-gap-6 tw-justify-center tw-mb-m slg:tw-mb-[64px]">
                    @for (renovationType of data.renovationTypes; track renovationType.id) {
                      <button effy-tag (click)="selectRenovationType(renovationType)" [selected]="renovationType === currentRenovationType">
                        {{ renovationType.label }}
                      </button>
                    }
                  </div>
                } @else {
                  <mat-form-field class="tw-w-full">
                    <mat-select [(value)]="currentRenovationType" (selectionChange)="selectRenovationType($event.value)">
                      @for (renovationType of data.renovationTypes; track renovationType) {
                        <mat-option [value]="renovationType">
                          {{ renovationType.label }}
                        </mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                }
              }
              <div class="eligible-renovations__main-content">
                @if (currentRenovationType.image) {
                  <div class="eligible-renovations__block-img">
                    <img loading="lazy" [src]="currentRenovationType.image.url" [alt]="currentRenovationType.image.alt" />
                  </div>
                }
                <div class="eligible-renovations__block-description">
                  @if (currentRenovationType.gaugeTitle || currentRenovationType.customGaugeTitle) {
                    <div class="col-4 text-center eligible-renovations__desktop">
                      @if (currentRenovationType.gaugeTitle && !currentRenovationType.customGaugeTitle) {
                        <nscf-rte
                          class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                          [data]="currentRenovationType.gaugeTitle"
                        ></nscf-rte>
                      }
                      <!--TODO: penser au cas où nous avons plusieurs entrées-->
                      @if (currentRenovationType.customGaugeTitle) {
                        <nscf-rte
                          class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                          [data]="currentRenovationType.gaugeTitle"
                          [toBeAppended]="[
                            currentRenovationType.customGaugeTitle.firstPart.value,
                            currentRenovationType.customGaugeTitle.percent,
                            currentRenovationType.customGaugeTitle.lastPart.value,
                          ]"
                        ></nscf-rte>
                      }
                      <div class="block-gauge">
                        <div class="block-gauge__gauge">
                          <div
                            class="block-gauge__gauge-percent"
                            [ngStyle]="{
                              height: currentRenovationType.dynamicPercent + '%',
                            }"
                          ></div>
                        </div>
                        <div class="block-gauge__grid">
                          <span class="block-gauge__grid-50">{{ currentRenovationType.gaugeMax }}%</span>
                          <span class="block-gauge__grid-20">{{ currentRenovationType.gaugeMax / 2 }}%</span>
                          <span class="block-gauge__grid-0">0%</span>
                        </div>
                      </div>
                    </div>
                  }
                  <div class="col-12 col-lg-8 text-center tw-flex tw-flex-col tw-gap-4 tw-items-center">
                    <div class="eligible-renovations__desktop">
                      @if (currentRenovationType.picto) {
                        <img
                          class="tw-size-[60px]"
                          [src]="currentRenovationType.picto.url"
                          [alt]="currentRenovationType.picto.alt"
                          loading="lazy"
                          width="60"
                          height="60"
                        />
                      }
                    </div>
                    <p class="eligible-renovations__block-description-title">
                      {{ currentRenovationType.title }}
                    </p>
                    <div class="eligible-renovations__block-description-chapo">
                      <nscf-rte [data]="currentRenovationType.chapo"></nscf-rte>
                    </div>
                    @if (currentRenovationType.buttonUrl) {
                      <nscf-cta-button
                        class="cta-button tw-flex tw-justify-center"
                        [text]="currentRenovationType.buttonText"
                        [ctaUrl]="currentRenovationType.buttonUrl"
                      >
                      </nscf-cta-button>
                    }
                    <div class="eligible-renovations">
                      <nscf-rte
                        selector="p"
                        class="eligible-renovations__block-description-chapo"
                        [data]="currentRenovationType.description"
                      ></nscf-rte>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          @if (!isBrowser) {
            <div>
              @for (renovationType of data.renovationTypes; track renovationType) {
                <div>
                  <h3 class="eligible-renovations__main-subtitle text-center h2-title-ssr">
                    {{ renovationType.label }}
                  </h3>
                  <div class="eligible-renovations__main-content">
                    @if (renovationType.image) {
                      <div class="eligible-renovations__block-img">
                        <img loading="lazy" [src]="renovationType.image.url" [alt]="renovationType.image.alt" />
                      </div>
                    }
                    <div class="eligible-renovations__block-description">
                      <div class="col-4 text-center eligible-renovations__desktop">
                        @if (renovationType.gaugeTitle && !renovationType.customGaugeTitle) {
                          <nscf-rte
                            class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                            [data]="renovationType.gaugeTitle"
                          ></nscf-rte>
                        }
                        @if (renovationType.customGaugeTitle) {
                          <nscf-rte
                            class="eligible-renovations__desktop eligible-renovations__block-description-gauge-eco"
                            [data]="renovationType.gaugeTitle"
                            [toBeAppended]="[
                              renovationType.customGaugeTitle.firstPart.value,
                              renovationType.customGaugeTitle.percent,
                              renovationType.customGaugeTitle.lastPart.value,
                            ]"
                          ></nscf-rte>
                        }
                        <div class="block-gauge">
                          <div class="block-gauge__gauge">
                            <div
                              class="block-gauge__gauge-percent"
                              [ngStyle]="{
                                height: renovationType.gauge + '%',
                              }"
                            ></div>
                          </div>
                          <div class="block-gauge__grid">
                            <span class="block-gauge__grid-50">{{ renovationType.gaugeMax }}%</span>
                            <span class="block-gauge__grid-20">{{ renovationType.gaugeMax / 2 }}%</span>
                            <span class="block-gauge__grid-0">0%</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-8 text-center tw-flex tw-flex-col tw-gap-4 tw-items-center">
                        <div class="eligible-renovations__desktop">
                          @if (renovationType.picto) {
                            <img
                              class="tw-size-[60px]"
                              [src]="renovationType.picto.url"
                              [alt]="renovationType.picto.alt"
                              loading="lazy"
                              width="60"
                              height="60"
                            />
                          }
                        </div>
                        <p class="eligible-renovations__block-description-title">
                          {{ renovationType.title }}
                        </p>
                        <div class="eligible-renovations__block-description-chapo">
                          <nscf-rte [data]="currentRenovationType.chapo"></nscf-rte>
                        </div>
                        @if (renovationType.buttonUrl) {
                          <nscf-cta-button
                            class="cta-button"
                            [text]="renovationType.buttonText"
                            [ctaUrl]="renovationType.buttonUrl"
                          ></nscf-cta-button>
                        }
                        <div class="eligible-renovations">
                          <nscf-rte
                            selector="p"
                            class="eligible-renovations__block-description-chapo"
                            [data]="currentRenovationType.description"
                          ></nscf-rte>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
