import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { INewTopPageCards } from '../../../interfaces/new-top-page-cards';

@Component({
  selector: 'nscf-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['cards.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [],
})
export class CardsComponent {
  data = input.required<Array<INewTopPageCards>>();
}
