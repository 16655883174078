import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, input, Input } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common';
import { GtmClickEventList, GtmEventCategory } from '../../enums/gtm.enum';
import { GtmClickEvent } from '../../interfaces/gtm';
import { UserSubscribeNewsletterSource } from '../../interfaces/newsletter';
import { GtmService } from '../../services/gtm/gtm.service';
import { NewsletterService } from '../../services/newsletter/newsletter.service';
import { INewsletter, NEWSLETTER_NETWORK_CONFIG } from './newsletter';

@Component({
  selector: 'nscf-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, FormsModule, ReactiveFormsModule, EffyButtonComponent, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
  host: {
    '[class]': 'hostClasses()'
  }
})
export class NewsletterComponent {
  @Input() data: INewsletter;
  readonly isHome = input(false);
  emailPattern = /^[0-9a-zA-Z][\+\w-\_\.]+@([0-9a-zA-Z][a-zA-Z0-9.-]+\.)+[\w-]{2,4}$/;

  emailFC = new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]);
  readonly emailFG = new FormGroup({
    email: this.emailFC,
  });
  isSuccessfullySubscribed = false;
  isLoading = false;
  private readonly newsletterService = inject(NewsletterService);
  private readonly gtmService = inject(GtmService);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  readonly hostClasses = computed(() => `${this.isHome() ? 'is-home' : ''}`);

  buttonSize = EffyButtonSize.MEDIUM;
  buttonStyle = EffyButtonStyle.PRIMARY;

  readonly newsletterNetworkConfig = NEWSLETTER_NETWORK_CONFIG;

  subscribeNewsletter() {
    if (!this.emailFG.valid) {
      return;
    }

    this.isLoading = true;

    this.newsletterService.subscribeToNewsletter(this.emailFC.value, UserSubscribeNewsletterSource.OptinNewsletterSites).subscribe(() => {
      this.isLoading = false;
      const subscribeEvent: GtmClickEvent = {
        event: GtmEventCategory.Click,
        'gtm.elementId': GtmClickEventList.Newsletter,
      };
      this.gtmService.pushEvent(subscribeEvent);
      this.isSuccessfullySubscribed = true;
      this.changeDetectorRef.detectChanges();
    });
  }
}
