import { Component, EventEmitter, inject, input, Input, OnInit, Output } from '@angular/core';
import { BasicThemedComponentDirective, WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EffyButtonStyle } from '@effy-tech/angular-common';
import { CookieService } from 'ngx-cookie-service';
import { CtaButtonConnectedComponent } from '../cta/cta-button-connected/cta-button-connected.component';
import { CtaButtonComponent } from '../cta/cta-button/cta-button.component';
import { EnvironmentModel } from '../header/header';
import { IConnection } from './connection';

@Component({
  selector: 'nscf-connection',
  templateUrl: 'connection.component.html',
  styleUrls: ['connection.component.scss'],
  standalone: true,
  imports: [CtaButtonConnectedComponent, CtaButtonComponent],
})
export class ConnectionComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly cookieService = inject(CookieService);

  @Input() connection: IConnection;
  @Input() env: EnvironmentModel;
  @Input() isPro: boolean;
  readonly scrollOverHeight = input<boolean>();
  @Output() connected: EventEmitter<boolean> = new EventEmitter<boolean>();
  showConnection = false;
  labelIdentity = '';
  btnClass: string;
  readonly EffyButtonStyle = EffyButtonStyle;

  ngOnInit(): void {
    const token = this.getRefreshTokenCookie();
    if (token && this.tokenIsValid(token) && this.theme === WebsiteThemes.effy && !this.isPro) {
      const firstName = this.getFirstNameInLocalStorage();
      if (firstName) {
        this.labelIdentity = `Bonjour ${this.capitalize(firstName)}`;
      }
    }

    this.showConnection = !!this.connection?.link?.title && !!this.connection?.link?.url;
    this.connected.emit(!!this.labelIdentity);
  }

  tokenIsValid(token: string) {
    let isValid = false;
    const tokenDate = new Date(parseJwt(token)?.exp * 1000);
    if (tokenDate && tokenDate > new Date()) {
      isValid = true;
    }
    return isValid;
  }

  getRefreshTokenCookie(): string {
    return this.cookieService.get('effy-connection-refreshtoken');
  }

  getFirstNameInLocalStorage(): string {
    return this.getFirstNameFromAcquisition() || this.getFirstNameFromEspaceClient();
  }

  getFirstNameFromEspaceClient(): string {
    return localStorage.getItem('ec-customer-firstname');
  }

  getFirstNameFromAcquisition(): string {
    return localStorage.getItem('acq-customer-firstname');
  }

  capitalize(s: string): string {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
