<div class="text-video">
  <!-- Player wrapper / image call -->
  <div
    [ngClass]="{
      loaded: isMediaLoaded() || !data.cover,
    }"
    class="load-video"
    (click)="loadMedia($event)"
    >
    <!-- Image call -->
    @if (!isMediaLoaded() || !data?.cover) {
      @if (data.cover) {
        <img
          loading="lazy"
          class="text-video__cover-video"
          [ngClass]="data.caption ? '' : 'text-video__cover-video--no-caption'"
          [alt]="data.cover?.alt || ''"
          [src]="data.cover.url | safeResourceUrl"
          />
      }
    }
    <!-- Iframe media -->
    @if (isMediaLoaded() || !data?.cover) {
      @if (data.cover) {
        <iframe
          class="text-video__iframe-video"
          [ngClass]="data.caption ? '' : 'text-video__iframe-video--no-caption'"
          [src]="generateEmbedUrl(data.video, isDesktop) | safeResourceUrl"
          [title]="element?.title || ''"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="0"
        ></iframe>
      }
      @if (!data.cover) {
        <iframe
          class="text-video__iframe-video"
          [ngClass]="data.caption ? '' : 'text-video__iframe-video--no-caption'"
          [src]="generateEmbedUrl(data.video) | safeResourceUrl"
          [title]="element?.title || ''"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="0"
          loading="lazy"
        ></iframe>
      }
    }
  </div>
  @if (data.caption && data.video) {
    <div class="legend">
      <p>{{ data.caption }}</p>
    </div>
  }
</div>
