@if (linksArticles$ | async; as linksArticles) {
  @if (linksArticles?.length && bigViewPort) {
    <div class="heading-article">
      <p class="heading-article__title">En savoir plus</p>
      <div class="wrapper" [ngClass]="{ 'more-item': showAllItems }">
        @for (item of linksArticles | slice: 0:(showAllItems ? undefined : nbMaxItemDisplayedAtFirst); track item) {
          <div
            class="heading-article__wrapper-link"
            >
            <a [ngClass]="{ active: isCurrentPath(item) }" [href]="item.path">{{ item.text }}</a>
          </div>
        }
      </div>
      @if (linksArticles.length > nbMaxItemDisplayedAtFirst) {
        <p
          (click)="onClickViewMore()"
          class="heading-article__item heading-article__item--show-more"
          >
          Voir plus d’articles
        </p>
      }
    </div>
  }
  <!-- Mobile -->
  @if (linksArticles?.length && !bigViewPort) {
    <div class="container" nscfLazyLoadMaterial>
      <mat-accordion>
        <mat-expansion-panel [expanded]="!isBrowser" class="heading-article__accordion-mat">
          <mat-expansion-panel-header [collapsedHeight]="'54px'" [expandedHeight]="'54px'">
            <mat-panel-title>
              <div class="heading-article__title heading-article__title--accordion">En savoir plus</div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          @for (item of linksArticles; track item) {
            <div class="heading-article__wrapper-link">
              <a [ngClass]="{ active: isCurrentPath(item) }" [href]="item.path">{{ item.text }}</a>
            </div>
          }
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  }
}
