import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common';
import { VideoGenerator } from '../../../classes/video-generator/video-generator';
import { SafeResourceUrlPipe } from '../../../pipes/safe-resource-url/safe-resource-url.pipe';
import { TitleComponent } from '../../title/title.component';
import { VideoPlayBackgroundComponent } from '../../video-play-background/video-play-background.component';
import { ISubBlockVideos } from './videos';

@Component({
  selector: 'nscf-videos',
  templateUrl: 'videos.component.html',
  styleUrls: ['videos.component.scss'],
  standalone: true,
  imports: [TitleComponent, NgClass, SafeResourceUrlPipe, VideoPlayBackgroundComponent, EffyButtonComponent],
  host: {
    ngSkipHydration: 'true',
    class: 'tw-block container text-video',
  },
})
export class VideosComponent extends VideoGenerator {
  @Input() element: ISubBlockVideos;
  @Input() loadedMedias;
  @Input() stepNumber: string;
  protected readonly buttonSize = EffyButtonSize;
  protected readonly buttonStyle = EffyButtonStyle;

  isMediaLoaded(stepNumber: string): boolean {
    return this.loadedMedias[parseInt(stepNumber, 10)];
  }

  loadMedia(event: MouseEvent, stepNumber: string): void {
    event.preventDefault();
    if (this.isMediaLoaded(stepNumber)) {
      event.stopPropagation();
    } else {
      this.loadedMedias[parseInt(stepNumber, 10)] = true;
    }
  }

  generateEmbedUrl(videoUrl: string, autoplay?: boolean): string {
    return super.generateEmbedUrl(videoUrl, autoplay);
  }
}
