import { Component, Input } from '@angular/core';
import { PdfHelper } from '../../classes/pdf/pdf.helper';
import { TypeNameSubBlockQuestionCTA } from '../../interfaces/question-cta';
import { IBlockText } from './block-text';
import { TypeNameSubBlockFocusBusiness } from './focus-business/focus-business';
import { FocusBusinessComponent } from './focus-business/focus-business.component';
import { TypeNameSubBlockImage } from './images/images';
import { ImagesComponent } from './images/images.component';
import { QuestionCtaComponent } from './question-cta/question-cta.component';
import { TypeNameSubBlockCTA } from './sub-block-cta/sub-block-cta';
import { SubBlockCtaComponent } from './sub-block-cta/sub-block-cta.component';
import { TypeNameSubBlockTables } from './tables/tables';
import { TablesComponent } from './tables/tables.component';
import { TypeNameSubBlockTexts } from './texts/texts';
import { TextsComponent } from './texts/texts.component';
import { TypeNameSubBlockVideos } from './videos/videos';
import { VideosComponent } from './videos/videos.component';

@Component({
  selector: 'nscf-block-text',
  templateUrl: 'block-text.component.html',
  styleUrl: 'block-text.component.scss',
  standalone: true,
  imports: [
    FocusBusinessComponent,
    ImagesComponent,
    SubBlockCtaComponent,
    QuestionCtaComponent,
    TablesComponent,
    TextsComponent,
    VideosComponent,
  ],
})
export class BlockTextComponent {
  @Input() data: IBlockText;
  @Input() isOverMobile: boolean;
  @Input() isBrowser: boolean;
  @Input() bigViewPort: boolean;
  loadedMedias = {};
  setFirstCta = false;
  typeSBFocusBusiness = TypeNameSubBlockFocusBusiness;
  typeSBImage = TypeNameSubBlockImage;
  typeSBCta = TypeNameSubBlockCTA;
  typeSBQuestionCta = TypeNameSubBlockQuestionCTA;
  typeSBTables = TypeNameSubBlockTables;
  typeSBTexts = TypeNameSubBlockTexts;
  typeSBVideos = TypeNameSubBlockVideos;

  setPdfLinkTarget(event: any): void {
    // On force le target à _blank pour les fichiers PDF du bloc texte.
    // Si un jour cette option est ajoutée au back, il faut supprimer
    // cette fonction.
    PdfHelper.setPdfLinkTarget(event, '_blank');
  }
}
