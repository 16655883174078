@if (links && links.length) {
  <div class="author-contact">
    <ul class="author-contact__links-block">
      @for (link of links; track link) {
        <li>
          <a [href]="link.url" target="_blank">
            <span class="author-contact__links-block--{{ link.name }}"></span>
          </a>
        </li>
      }
    </ul>
  </div>
}
