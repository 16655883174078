<div *ngIf="data">
  <div class="container p-0">
    <div *ngIf="data.cards && data.cards.length" class="theme-block">
      <nscf-title
        [id]="'anchor_' + data.id"
        [selector]="data.isFirstTitle ? 'h1' : 'h2'"
        [title]="data.title"
        class="theme-block__title"
      ></nscf-title>

      @if (data.chapo) {
        <nscf-liseret class="theme-block__liseret"></nscf-liseret>
        <nscf-rte [data]="data.chapo" class="theme-block__chapo"></nscf-rte>
      }

      <div class="theme-block__wrapper-cards">
        <div *ngFor="let card of data.cards.slice(0, 9); trackBy: trackByFn; index as stepNumber" class="theme-block__wrapper-card">
          @if (isDesktop() === false) {
            <nscf-article-card-small
              [url]="card.link.url"
              [img]="card.imageWebp"
              [imgAlt]="card.imageAlt"
              [title]="card.title"
              [taxonomies]="card.taxonomies"
            ></nscf-article-card-small>
          } @else {
            <nscf-card-summary-simplified [data]="card" [id]="'card-' + stepNumber"></nscf-card-summary-simplified>
          }
        </div>
      </div>

      <div *ngIf="data.cards.length > 9" class="theme-block__cta-button-container">
        <nscf-cta-button
          class="theme-block__cta-button"
          [ctaUrl]="{ url: data.taxonomy[0]?.hubUrl }"
          [text]="'Voir tous les articles'"
          [withArrow]="true"
        >
        </nscf-cta-button>
      </div>
    </div>
  </div>
</div>
