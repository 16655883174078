@if (displayedSummaryElements()?.length) {
  <details open>
    <summary>
      <span class="summary-title">
        <span>Sommaire</span>
      </span>
      <img src="../../../../assets/arrow/arrow.svg" class="arrow-img-summary" width="16" height="16" alt="" />
    </summary>
    <nav>
      <ol>
        @for (element of displayedSummaryElements(); track element) {
          <li class="tw-text-pretty">
            <a [routerLink]="'.'" [fragment]="element.url">
              <span>{{ element.title }}</span>
            </a>
          </li>
        }
      </ol>
    </nav>
    @if (showSeeMoreButton()) {
      <button class="see-more-less-button" (click)="seeMore.set(!seeMore())">
        <span>{{ seeMoreLabel() }}</span>
        <img
          src="../../../../assets/arrow/arrow-white.svg"
          class="arrow-img-button"
          width="16"
          height="16"
          [class.see-more]="seeMore()"
          alt=""
        />
      </button>
    }
  </details>
}
