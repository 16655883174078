import { Component, Input } from '@angular/core';
import { TitleComponent } from '../title/title.component';
import { IVerifiedReviewsContainer } from './verified-reviews-container';

@Component({
  selector: 'nscf-verified-reviews-container-container',
  templateUrl: 'verified-reviews-container.component.html',
  standalone: true,
  imports: [TitleComponent],
})
export class VerifiedReviewsContainerComponent {
  @Input() data: IVerifiedReviewsContainer;
}
