import { NgFor, NgIf } from '@angular/common';
import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { MediaQueriesService } from '@effy-tech/angular-common';
import { forEach } from 'lodash-es';
import { Subscription } from 'rxjs';
import { CarouselContainer } from '../../classes/carousel-container/carousel-container';
import { CardificationService } from '../../services/cardification/cardification.service';
import { LoggerService } from '../../services/loggers';
import { ArticleCardSmallComponent } from '../article-card-small/article-card-small.component';
import { CardSummarySimplifiedComponent } from '../card-summary-simplified/card-summary-simplified.component';
import { CtaButtonComponent } from '../cta-button/cta-button.component';
import { LiseretComponent } from '../liseret/liseret.component';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { IBlocTheme } from './theme';

@Component({
  selector: 'nscf-theme-component',
  templateUrl: 'theme.component.html',
  styleUrls: ['theme.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TitleComponent,
    RteComponent,
    NgFor,
    CardSummarySimplifiedComponent,
    CtaButtonComponent,
    ArticleCardSmallComponent,
    LiseretComponent,
  ],
})
export class ThemeComponent extends CarouselContainer implements OnInit, OnDestroy {
  @Input() isBrowser: boolean;
  @Input() theme: WebsiteThemes = WebsiteThemes.effy;
  data: IBlocTheme;
  currentPage: number;
  pageSize: number;
  subscription: Subscription;

  isDesktop = toSignal(this.mediaQueriesService.isDesktop$);

  constructor(
    private readonly cardificationService: CardificationService,
    private readonly logger: LoggerService,
    private readonly mediaQueriesService: MediaQueriesService
  ) {
    super();
  }

  @HostBinding('class') get HeadingClass(): string {
    return this.theme;
  }

  ngOnInit(): void {
    if (!this.data) {
      return;
    }

    const targetIds: Array<string> = [];
    forEach(this.data.taxonomy, t => {
      targetIds.push(t.targetId);
    });

    try {
      this.subscription = this.cardificationService.getNewsCards(targetIds, { isTheme: true }).subscribe(results => {
        this.data.cards = results;
      });
    } catch (e) {
      this.logger.error('ThemeComponent: error "getCardificationThemeData()"', e);
    }
  }

  trackByFn(index, item) {
    return item.id ? item.id : index;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
