<div *ngIf="data" class="container">
  <div class="mx-auto">
    <nscf-title *ngIf="data.title" [title]="data.title"></nscf-title>
    <div class="images-block">
      <div *ngFor="let card of data.cards" class="images-block__card">
        <img *ngIf="card.image?.url" [alt]="card.image?.alt" [src]="card.image?.url" loading="lazy" />
        <div *ngIf="card.legend" class="images-block__legend-wrapper">
          <p class="images-block__legend">
            <span class="images-block__legend-tab"></span>
            <span>{{ card.legend }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
