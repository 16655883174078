@if (showConnection) {
  <div class="connection">
    @if (labelIdentity) {
      <nscf-header-cta-button-connected [labelUser]="labelIdentity" [buttonStyle]="EffyButtonStyle.QUATERNARY">
      </nscf-header-cta-button-connected>
    }
    @if (!labelIdentity) {
      <nscf-header-cta-button [ctaLink]="connection?.link" [buttonStyle]="EffyButtonStyle.QUATERNARY"> </nscf-header-cta-button>
    }
  </div>
}
