import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IFieldMediaImageWithWebp } from './../../../interfaces/field-media-image';

@Component({
  selector: 'nscf-article-cover',
  templateUrl: 'cover.component.html',
  styleUrls: ['cover.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'article-cover',
  },
})
export class ArticleCoverComponent {
  @Input() data: IFieldMediaImageWithWebp;
}
