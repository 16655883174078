import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ILogo } from './logo';

@Component({
  selector: 'nscf-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
  imports: [MatIcon],
})
export class LogoComponent {
  @Input() logo: ILogo;
}
