import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IArticleMetaInfoAuthor } from '../../../interfaces/article-meta-info';

@Component({
  selector: 'nscf-article-author',
  standalone: true,
  imports: [],
  templateUrl: './article-author.component.html',
  styleUrls: ['./article-author.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'container',
  },
})
export class ArticleAuthorComponent {
  @Input() data: IArticleMetaInfoAuthor;
}
