<div [ngClass]="this.displayButton ? '' : 'multi-themes__spacing-block'">
  <div class="container hub">
    <nscf-title
      *ngIf="data.nameFront"
      [id]="'anchor_' + $any(data).targetId"
      [ngClass]="data.chapo ? '' : 'multi-themes-without-description'"
      [title]="data.nameFront"
    ></nscf-title>
    <div *ngIf="data.chapo" class="row">
      <div class="col-12">
        <p class="hub__description tw-text-lg">
          {{ data.chapo }}
        </p>
      </div>
    </div>
    <div *ngIf="data.multithemes || data.blocks.cardsSummary.length" class="hub__wrapper-cards">
      <div *ngFor="let card of paginatedCards; index as stepNumber" class="hub__wrapper-card">
        <nscf-card-summary-simplified [data]="card" [id]="'card-' + stepNumber"></nscf-card-summary-simplified>
        <div *ngIf="!data.multithemes && stepNumber === 0" [disableIntersection]="disableIntersection" [id]="'page_0'" loadNewPage></div>
        <div *ngIf="!data.multithemes" id="p_0"></div>
        <div
          *ngIf="!data.multithemes && isStepNumberPerPageSizeInteger(stepNumber + 1, pageSize)"
          [disableIntersection]="disableIntersection"
          [id]="'page_' + (stepNumber + 1) / pageSize"
          loadNewPage
        ></div>
        <div *ngIf="!data.multithemes" [id]="'p_' + (stepNumber + 1) / pageSize"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!data.multithemes && numberOfPages > 1" class="hub__pager">
    <nscf-pager [data]="pagerLinks"></nscf-pager>
  </div>
  <a effy-button *ngIf="data.multithemes && displayButton" [href]="data.hubUrl" class="tw-mt-6 tw-mx-auto tw-mb-8 md:tw-mt-8 md:tw-mb-12"> Voir tous les articles </a>
</div>
