import { LowerCasePipe, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EffyHashtagComponent, EffyTinyButtonComponent, EffyTinyButtonSize } from '@effy-tech/angular-common';
import { ICardSummarySimplified } from '../../interfaces/cards-summary';
import { SafeHtmlPipe } from '../../pipes/safe-html/safe-html.pipe';

@Component({
  selector: 'nscf-card-summary-simplified',
  templateUrl: 'card-summary-simplified.component.html',
  styleUrls: ['card-summary-simplified.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, SafeHtmlPipe, LowerCasePipe, EffyHashtagComponent, EffyTinyButtonComponent],
  host: {
    ngSkipHydration: 'true',
  },
})
export class CardSummarySimplifiedComponent {
  @Input() data: ICardSummarySimplified;
  @Input() id: string | number;
  component: { description: string; image: string; imageWebp: string; imageAlt: string; link: { url: string } };

  tinyButtonSize = EffyTinyButtonSize;
}
