@if (data) {
  @if (mode === newsCardMode.HEADLINES) {
    <!-- PREMIÈRE CARTE À LA UNE -->
    @if (isHeadCard) {
      <div class="news--big-card">
        <a
          [href]="data.link?.url"
          class="actuality__card"
          [ngClass]="isSticky ? 'actuality__card--secondary-actu' : 'actuality__card--first-actu'"
        >
          <div class="actuality__card-image">
            <div class="wrapper-image">
              <picture>
                <source [attr.srcset]="data.wideCoverWebp" />
                <img [attr.src]="data.wideCover" [attr.alt]="data.imageAlt" loading="lazy" />
              </picture>
            </div>
            <div class="actuality__card--calque"></div>
            <div class="actuality__card-content">
              <div class="actuality__card-content-wrapper">
                <div class="actuality__card-content-theme">
                  @for (theme of $any(data).taxonomies; track $index) {
                    <a effy-hashtag [size]="hashtagSize" [color]="hashtagColorHeadline">#{{ theme }}</a>
                  }
                </div>
                <div class="actuality__card-content-date">
                  <span>{{ data.created }}</span>
                </div>
              </div>
              <p class="actuality__title" [innerHTML]="data?.title | safeHtml"></p>
            </div>
          </div>
        </a>
      </div>
    } @else {
      <div
        class="actuality__card col-12 col-md-6 news--simple-card"
        [ngClass]="{
          'actuality__card--first-actu': isSticky,
          'actuality__card--secondary-actu': !isSticky,
        }"
      >
        <a [href]="data.link?.url">
          <div [ngClass]="{ 'actuality__card-wrapper': !isSticky }">
            @if (data.image) {
              <div class="actuality__card-image">
                <div class="wrapper-image">
                  <picture>
                    <source [attr.srcset]="data.imageWebp" />
                    <img [attr.src]="data.image" [attr.alt]="data.imageAlt" loading="lazy" />
                  </picture>
                </div>
              </div>
            }
            <div class="actuality__card-content">
              <div class="actuality__card-content-wrapper">
                <div class="actuality__card-content-theme">
                  @for (theme of $any(data).taxonomies; track $index) {
                    <a effy-hashtag [size]="hashtagSize">#{{ theme }}</a>
                  }
                </div>
                @if (isDesktop) {
                  <div class="actuality__card-content-date actuality__card-content-date--desktop">
                    <span>{{ data.created }}</span>
                  </div>
                } @else {
                  <div class="actuality__card-content-date actuality__card-content-date--mobile">
                    <span>{{ data.created }}</span>
                  </div>
                }
              </div>
              <p class="actuality__title" [innerHTML]="data?.title | safeHtml"></p>
            </div>
          </div>
        </a>
      </div>
    }
    <!-- FIN PREMIÈRE CARTE À LA UNE -->
    <!-- CARTES 2 ET 3 À LA UNE -->

    <!-- FIN CARTES 2 ET 3 À LA UNE -->
  } @else {
    <div class="actuality__card">
      <a [href]="data.link?.url" [ngClass]="isSticky ? 'actuality__card--first-actu' : 'actuality__card--secondary-actu'">
        <div [ngClass]="{ 'actuality__card-wrapper': !isSticky }">
          @if (data.image) {
            <div class="actuality__card-image">
              <div class="wrapper-image">
                <picture>
                  <source [attr.srcset]="isSticky ? data.wideCover : data.imageWebp" />
                  <img [attr.src]="data.image" [attr.alt]="data.imageAlt" loading="lazy" />
                </picture>
              </div>
            </div>
          }
          <div class="actuality__card-content">
            <div class="actuality__card-content-wrapper">
              <div class="actuality__card-content-theme">
                @for (theme of $any(data).taxonomies; track $index) {
                  <a effy-hashtag [size]="hashtagSize">#{{ theme }}</a>
                }
              </div>
              @if (isDesktop) {
                <div class="actuality__card-content-date actuality__card-content-date--desktop">
                  <span>{{ data.created }}</span>
                </div>
              } @else {
                <div class="actuality__card-content-date actuality__card-content-date--mobile">
                  <span>{{ data.created }}</span>
                </div>
              }
            </div>
            <p class="actuality__title" [innerHTML]="data?.title | safeHtml"></p>
          </div>
        </div>
      </a>
    </div>
  }
}
