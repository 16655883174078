import { NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ArticleRelatedComponent } from '../../components/article-blocs/article-related/related.component';
import { ArticleThemeComponent } from '../../components/article-blocs/article-theme/theme.component';
import { IBreadCrumbName } from '../../components/breadcrumb/breadcrumb';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { ThemesHubComponent } from '../../components/themes-hub/themes-hub.component';
import { TypeNameBlockHub } from '../../interfaces/cards-summary';
import { DynamicComponentData } from '../../interfaces/dynamic-types';
import { IManualPageHub } from '../../interfaces/pages';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LoggerService } from '../../services/loggers';
import { DynamicPageDirective } from '../dynamic-page';

@Component({
  selector: 'nscf-manual-hub',
  templateUrl: 'manual-hub.component.html',
  styleUrls: ['manual-hub.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class ManualHubComponent extends DynamicPageDirective implements OnInit, OnDestroy {
  @Input() data: IManualPageHub;

  @ViewChild('topContent', { read: ViewContainerRef, static: true })
  topContent: ViewContainerRef;
  @ViewChild('breadcrumbContent', { read: ViewContainerRef, static: true })
  breadcrumbContent: ViewContainerRef;
  @ViewChild('summaryContentMobile', { read: ViewContainerRef, static: true })
  summaryContentMobile: ViewContainerRef;
  @ViewChild('mainContent', { read: ViewContainerRef, static: true })
  mainContent: ViewContainerRef;
  @ViewChild('asideContent', { read: ViewContainerRef, static: true })
  asideContent: ViewContainerRef;
  @ViewChild('bottomContent', { read: ViewContainerRef, static: true })
  bottomContent: ViewContainerRef;
  @ViewChild('beforeFooterContent', { read: ViewContainerRef, static: true })
  beforeFooterContent: ViewContainerRef;
  bigViewPort: boolean;

  constructor(
    protected readonly deviceManagerService: DeviceManagerService,
    protected readonly logger: LoggerService
  ) {
    super(logger, deviceManagerService);
  }

  ngOnInit() {
    this.getDeviceType().subscribe();
  }

  buildPage(): void {
    const isDesktop = this.deviceManagerService.isDesktop();
    // clear page
    this.clearPage();

    if (isDesktop) {
      super.appendDynamicComponentData(this.breadcrumbContent, [this.getBreadcrumbContent()]);
    }

    // related articles desktop
    const articleRelatedComponentData = {
      type: ArticleRelatedComponent,
      content: this.data.relateds,
      typename: this.data.__typename,
    };

    // Aside contents
    if (this.bigViewPort) {
      const asideDynamicComponentsData: DynamicComponentData[] = [];
      if (this.data.relateds) {
        asideDynamicComponentsData.push(articleRelatedComponentData);
      }
      asideDynamicComponentsData.push(this.getThemeContent());
      super.appendDynamicComponentData(this.asideContent, asideDynamicComponentsData);
    }

    const mainDynamicComponentsData: DynamicComponentData[] = this.data.taxonomy.map(
      v =>
        <DynamicComponentData>{
          type: ThemesHubComponent,
          content: { ...v, multithemes: true },
          typename: TypeNameBlockHub,
        }
    );

    // then append them to the ViewContainerRef
    super.appendDynamicComponentData(this.mainContent, mainDynamicComponentsData);

    const bottomContentDynamicComponentsData: DynamicComponentData[] = [];
    if (!this.bigViewPort) {
      bottomContentDynamicComponentsData.push(this.getThemeContent());
      super.appendDynamicComponentData(this.bottomContent, bottomContentDynamicComponentsData);
      // related articles mobile
      if (this.data.relateds) {
        super.appendDynamicComponentData(this.beforeFooterContent, [articleRelatedComponentData]);
      }
    }
    if (!isDesktop) {
      super.appendDynamicComponentData(this.bottomContent, [this.getBreadcrumbContent()]);
    }
  }

  getBreadcrumbContent(): DynamicComponentData {
    return {
      type: BreadcrumbComponent,
      content: { ...this.data.breadcrumb },
      typename: IBreadCrumbName,
    };
  }

  getThemeContent(): DynamicComponentData {
    return {
      type: ArticleThemeComponent,
      content: { ...this.data.taxonomy, extra: {} },
      typename: this.data.__typename,
    };
  }

  clearPage(): void {
    this.topContent.clear();
    this.breadcrumbContent.clear();
    this.summaryContentMobile.clear();
    this.mainContent.clear();
    this.asideContent.clear();
    this.bottomContent.clear();
    this.beforeFooterContent.clear();
  }

  ngOnDestroy() {
    this.doUnsubscribe();
  }
}
