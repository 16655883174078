import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/acquisition-core/shared';
import { IPosition } from '../../../interfaces/position';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { MapComponent } from '../../map/map.component';
import { RteComponent } from '../../rte/rte.component';
import { TitleComponent } from '../../title/title.component';
import { WidgetCalculatorComponent } from '../../widget-calculator/widget-calculator.component';
import { ISubBlockTexts } from './texts';
import { VideoTextComponent } from './video-text/video-text.component';

@Component({
  selector: 'nscf-texts',
  templateUrl: 'texts.component.html',
  styleUrls: ['texts.component.scss'],
  standalone: true,
  imports: [NgClass, MapComponent, VideoTextComponent, TitleComponent, RteComponent, CtaButtonComponent, WidgetCalculatorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextsComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() element: ISubBlockTexts;
  @Input() isOverMobile: boolean;
  @Input() isBrowser: boolean;
  @Input() isFirst: boolean;
  isDesktop: boolean;
  mediaLocation: IPosition;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.mediaLocation = this.element.image ? this.element.imageLocation : null;
    if (this.element.map?.isActive) {
      this.mediaLocation = this.element.map.locationMap;
    } else if (this.element.video?.video.length && this.element.videoLocation) {
      this.mediaLocation = this.element.videoLocation;
    }
  }
}
