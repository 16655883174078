<h3 class="carousel__title text-center">
  {{ topPage.carouselTitle | uppercase }}
</h3>

<owl-carousel-o
  ngSkipHydration
  class="carousel"
  [options]="customOptions"
  (translated)="getPassedData($event)"
  (change)="getChangeData($event)"
  (changed)="getChangedData($event)"
  #owlCar
>
  <ng-container *ngFor="let slide of topPage.carousel">
    <ng-template carouselSlide [id]="'slide-' + slide.stepNumber">
      <div class="carousel__size-content-desktop">
        <div class="carousel__step-icon-bloc">
          <p class="carousel__step-number text-center">
            {{ slide.stepNumber }}
          </p>
          <img loading="lazy" *ngIf="slide.stepImage" [src]="slide.stepImage.url" class="carousel__step-icon" alt="" />
        </div>
        <div>
          <h5 class="carousel__step-title text-center">
            {{ slide.stepTitle }}
          </h5>
          <nscf-rte *ngIf="slide.stepDescription" class="carousel__step-description text-left" [data]="slide.stepDescription"> </nscf-rte>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>

<div class="carousel__navigation">
  <span>
    <button (click)="owlCar.prev()">
      <img loading="lazy" src="assets/utils/arrow-left.svg" alt="flèche des étapes gauche" />
    </button>
  </span>
  <span class="dots">
    <span
      class="dot"
      *ngFor="let slide of topPage.carousel"
      (click)="owlCar.to('slide-' + slide.stepNumber)"
      [ngClass]="{ current: 'slide-' + slide.stepNumber === currentSlideId }"
    >
      &nbsp;
    </span>
  </span>
  <span>
    <button (click)="owlCar.next()">
      <img loading="lazy" src="assets/utils/arrow-right.svg" alt="flèche des étapes droite" />
    </button>
  </span>
</div>
