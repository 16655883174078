import { LowerCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EffyHashtagComponent } from '@effy-tech/angular-common';

@Component({
  selector: 'nscf-article-card-small',
  templateUrl: './article-card-small.component.html',
  styleUrls: ['./article-card-small.component.scss'],
  standalone: true,
  imports: [LowerCasePipe, EffyHashtagComponent],
})
export class ArticleCardSmallComponent {
  @Input() url = '';
  @Input() img = '';
  @Input() imgAlt = '';
  @Input() title = '';
  @Input() taxonomies: string[] = [];
}
