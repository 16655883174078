import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit, Optional, StateKey, TransferState, makeStateKey } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { HeaderComponent, IHeader } from '@effy-tech/acquisition-core/header';
import { BasicThemedComponentDirective } from '@effy-tech/acquisition-core/shared';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../modules/config/config.service';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LoggerService } from '../../services/loggers';
import { MetaTagsService } from '../../services/meta-tags';
import { REQUEST, RESPONSE } from '../../tokens/express.tokens';
import { FooterComponent } from '../footer/footer.component';
import { ErrorData, ErrorsCode, ErrorsDataMap, MetaDataError, getErrorsMap } from './error-model';
import { EffyButtonComponent, EffyIcons } from '@effy-tech/angular-common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'nscf-not-found',
  templateUrl: 'not-found.component.html',
  styleUrls: ['not-found.component.scss'],
  standalone: true,
  imports: [NgIf, HeaderComponent, NgFor, FooterComponent, AsyncPipe, EffyButtonComponent, MatIcon],
})
export class NotFoundComponent extends BasicThemedComponentDirective implements OnInit {
  readonly chevronIcon = EffyIcons.CHEVRON_RIGHT;

  currentErrorData: ErrorData;
  headerDataStatic$ = this.getHeaderData();
  data: {
    statusCode;
    from;
    messageError: string;
  };
  isEffy: boolean;

  constructor(
    @Optional() @Inject(RESPONSE) private readonly response: any,
    @Optional() @Inject(REQUEST) private readonly request: any,
    private readonly title: Title,
    private readonly meta: MetaTagsService,
    private readonly route: ActivatedRoute,
    private readonly configService: ConfigService,
    private readonly transferState: TransferState,
    private readonly logger: LoggerService,
    private readonly deviceManagerService: DeviceManagerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.theme = this.configService.getTheme();
    const statusCode = this.getStatusCode();
    const errorsData: ErrorsDataMap = getErrorsMap(this.configService.getErrorsData());
    const messageError: string = this.data?.messageError;
    this.currentErrorData = errorsData?.get(`${statusCode}`);
    if (this.currentErrorData) {
      const { metaData, message }: { metaData: MetaDataError; message: string } = this.currentErrorData;
      this.title.setTitle(metaData?.title);
      this.meta.updateTag({ name: 'description', content: metaData?.description });
      if (this?.response) {
        this.response.statusCode = statusCode;
        this.response.statusMessage = message;
        this.logCustomError(statusCode, messageError ?? message);
      }
    }
  }

  logCustomError(statusCode: ErrorsCode, message: string): void {
    const { headers, method, ip, connection } = this.request || {};
    const requestedUrl = this.route?.snapshot['_routerState']?.url;
    const urlFromPath = this.data.from || '';
    const errorMessageLogged = `${statusCode} ${message} ${urlFromPath}`;
    const log = {
      url: urlFromPath ?? requestedUrl,
      req: {
        headers,
        method,
        ip: ip || connection?.remoteAddress,
      },
    };

    if (statusCode == ErrorsCode.NOT_FOUND_CODE) {
      this.logger.warn(errorMessageLogged, log);
    } else {
      this.logger.error(errorMessageLogged, log);
    }
  }

  private getStatusCode(): ErrorsCode {
    const STATUS_CODE_KEY: StateKey<ErrorsCode> = makeStateKey<ErrorsCode>('statusCode');
    let statusCodeServer: ErrorsCode;
    const statusCode: ErrorsCode = this.data?.statusCode || ErrorsCode.NOT_FOUND_CODE;
    if (!this.deviceManagerService.isBrowser()) {
      this.transferState.set<ErrorsCode>(STATUS_CODE_KEY, statusCode);
    } else {
      statusCodeServer = this.transferState.get<ErrorsCode>(STATUS_CODE_KEY, statusCode);
    }
    return statusCodeServer ?? statusCode;
  }

  private getHeaderData(): Observable<IHeader> {
    return of({
      ...this.configService.getHeaderData(),
      isDesktop$: this.deviceManagerService.deviceType$().pipe(map(deviceType => deviceType.bigViewPort)),
    });
  }
}
