@if (cardsActu$ | async; as cardsSummary) {
  <div class="actuality">
    @if (cardsSummary?.length) {
      <div class="container">
        <div class="actuality__wrapper-title">
          <h2>{{ data.title }}</h2>
          <nscf-liseret class="actuality__liseret"></nscf-liseret>
        </div>
        <p class="actuality__description">{{ data.chapo }}</p>
        <div class="actuality__cards-wrapper">
          <nscf-news-card [isDesktop]="isDesktop" [data]="cardsSummary[0]" [isSticky]="true"></nscf-news-card>
          <div class="actuality__cards-wrapper-secondary">
            @for (card of cardsSummary.slice(1, 4); track card) {
              <nscf-news-card [isDesktop]="isDesktop" [data]="card" [isSticky]="false"></nscf-news-card>
            }
          </div>
        </div>
      </div>
    }
  </div>
}
