import { WebsiteThemes } from "@effy-tech/acquisition-core/shared";

export enum SchemaTypes {
  FAQ_PAGE = 'FAQPage',
  BREADCRUMB_LIST = 'BreadcrumbList',
  LIST_ITEM = 'ListItem',
  ITEM_LIST = 'ItemList',
  PRODUCT = 'Product',
  AGGREGATE_RATING = 'AggregateRating',
  SOFTWARE_APPLICATION = 'SoftwareApplication',
  ORGANIZATION = 'Organization',
  WEB_PAGE = 'WebPage',
  IMAGE_OBJECT = 'ImageObject',
  PERSON = 'Person',
  OFFER = 'Offer',
  POSTAL_ADDRESS = 'PostalAddress',
}

export interface IRating {
  ratingValue: number;
  ratingCount: number;
  bestRating: number;
  worstRating: number;
  nameWebSite?: string;
}

export interface IWebPage {
  name: string;
  headling: string;
  url: string;
  author?: {
    name: string;
    description: string;
    imageUrl: string;
    sameAs: string;
    isEditorialTeam: boolean;
  };
  site: WebsiteThemes;
}

export interface IPublisher {
  name;
  logo: IPublisherLogo;
}

export interface IPublisherLogo {
  url: string;
  height: number;
  width: number;
}
