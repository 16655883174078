<effy-progress-bar
  class="article__progress-bar tw-mt-0 tw-w-full tw-fixed tw-top-[60px] smd:tw-top-[80px] tw-z-[92]"
  [value]="progress$ | async"
/>
<ng-container #breadcrumbContent>
  <!-- breadcrumb content zone -->
</ng-container>
<ng-container #topContent>
  <!-- top content zone -->
</ng-container>
<div class="container p-0 article-wrapper">
  <div class="article__container">
    <div class="article__content" [ngClass]="{ 'has-cta-content': hasCtaContent }">
      <nscf-title
        *ngIf="data.title"
        [id]="'anchor_' + data.nid"
        [selector]="'h1'"
        [title]="data.title"
        class="article__content-title"
      ></nscf-title>
      <nscf-meta-info
        class="article__content-meta-info"
        [data]="data?.metaInfo"
        [shouldDisplayDates]="data.shouldDisplayAuthorDates"
      ></nscf-meta-info>
      <nscf-article-summary-details *ngIf="data.showSummary" [data]="summary" class="article__summary"></nscf-article-summary-details>
      <div class="article__main-content">
        <ng-container #mainContent>
          <!-- main content zone -->
        </ng-container>
      </div>
    </div>
    <div
      class="article__sidebar"
      [ngClass]="{
        'has-cta-content': hasCtaContent,
      }"
    >
      <div class="article__sidebar-sticky">
        <ng-container #ctaDesktopContent>
          <!-- cta desktop content zone -->
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container #bottomContent>
    <!-- bottom content zone -->
  </ng-container>
  <ng-container #ctaMobileContent>
    <!-- cta mobile content zone -->
  </ng-container>
</div>
<ng-container #beforeFooterContent>
  <!-- cardification content zone -->
</ng-container>
