<div class="my-prime-renov">
  <p class="widget-title">J’estime MaPrimeRenov' en quelques clics !</p>

  <calc-work-estimate *ngIf="workSelectedValue; else noWorkTypeSelected" [workSelectedValue]="workSelectedValue"
    [isPreselectedWork]="isPreselectedWork" (workTypeReset)="onWorkTypeReset()"/>

  <ng-template #noWorkTypeSelected>
    <div class="widget-body">
      <p class="step-title">Quels travaux souhaitez-vous réaliser ?</p>
      <calc-work-selection [(workType)]="workSelectedValue"/>
    </div>
  </ng-template>

  <div *ngIf="workSelectedValue" class="my-prime-renov__warning">
    @defer (on viewport) {
      <mat-icon class="tw-text-white" svgIcon="info"></mat-icon>
    } @placeholder {
      <span class="tw-size-6">icône</span>
    }
    <p class="my-prime-renov__wrapper-text">
      Attention, <span> MaPrimeRenov’ n’est disponible que pour </span> les logements construits depuis plus de 15 ans.
    </p>
  </div>
</div>