import { NgClass } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, HostBinding, input, Input } from '@angular/core';
import { EffyButtonComponent, EffyButtonSize, EffyButtonStyle } from '@effy-tech/angular-common';
import { ILink } from '../../interfaces/link';

@Component({
  selector: 'nscf-cta-button',
  templateUrl: './cta-button.component.html',
  styleUrls: ['./cta-button.component.scss'],
  standalone: true,
  imports: [NgClass, EffyButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.is-centered]': 'centered()',
    ngSkipHydration: 'true',
  },
})
export class CtaButtonComponent {
  @Input() text: string;
  @Input() redirection = true;
  @Input() @HostBinding('class.full-width') fullWidth: boolean = false;
  protected readonly withArrow = input(false);
  protected readonly centered = input(false, { transform: booleanAttribute });
  btnClasses: Array<string> = [];
  href: string;

  effyStyle = input<EffyButtonStyle>(EffyButtonStyle.PRIMARY);
  effySize = input<EffyButtonSize>(EffyButtonSize.LARGE);

  private _ctaUrl: ILink;

  get ctaUrl(): ILink {
    return this._ctaUrl;
  }

  @Input() set ctaUrl(value: ILink) {
    this._ctaUrl = value;
    this.href = this.redirection ? this._ctaUrl?.url : 'javascript:void(0)';
  }
}
