import { AsyncPipe, DOCUMENT, NgClass, isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  computed,
  effect,
  inject,
  output,
  signal,
} from '@angular/core';
import { BasicThemedComponentDirective, WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { EffyButtonStyle } from '@effy-tech/angular-common';
import { LoadImagesService } from '../../services/load-images.service.ts/load-images.service';
import { ConnectionComponent } from '../connection/connection.component';
import { CtaButtonComponent } from '../cta/cta-button/cta-button.component';
import { LogoComponent } from '../logo/logo.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { PhoneBlockComponent } from '../phone-block/phone-block.component';
import { HEADER_HEIGHT, IHeader, PhoneType, throttleScroll } from './header';

@Component({
  selector: 'nscf-new-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [LogoComponent, PhoneBlockComponent, NavbarComponent, ConnectionComponent, CtaButtonComponent, AsyncPipe, NgClass],
  providers: [LoadImagesService],
  host: {
    ngSkipHydration: 'true',
  },
})
export class HeaderComponent extends BasicThemedComponentDirective implements OnInit {
  private readonly platformId = inject(PLATFORM_ID);
  private readonly document = inject(DOCUMENT);
  @ViewChild('menuMobile', { static: true }) menuMobile: ElementRef<HTMLInputElement>;
  @Input() data: IHeader;
  scrollOverHeightHeader: boolean;
  lastScrollPosition = 0;
  scrollBack = false;
  connected = signal(false);
  isRegulatory: boolean;
  isBrowser = isPlatformBrowser(this.platformId);
  readonly isMobileMenuOpen = signal(false);
  readonly bodyOverflow = computed<'auto' | 'hidden'>(() => (this.isMobileMenuOpen() ? 'hidden' : 'auto'));
  secondButtonStyle = EffyButtonStyle.QUATERNARY;
  
  readonly mobileMenuOpened = output<boolean>();

  constructor() {
    super();
    effect(() => (this.document.body.style.overflow = this.bodyOverflow()));
    effect(() => this.mobileMenuOpened.emit(this.isMobileMenuOpen()));
  }

  ngOnInit(): void {
    this.isRegulatory = this.data.phoneInsert?.phoneType === PhoneType.Reglementaire;
    this.secondButtonStyle = this.theme === WebsiteThemes.effy ? EffyButtonStyle.QUATERNARY : EffyButtonStyle.TERTIARY;

    if (this.data?.shouldDisplayPhoneInsert === false) {
      this.data.phoneInsert = null;
    }
  }

  @HostListener('window:scroll', [])
  @throttleScroll()
  onWindowScroll(): void {
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.scrollOverHeightHeader = verticalOffset > HEADER_HEIGHT;
    this.scrollBack = verticalOffset < this.lastScrollPosition;
    this.lastScrollPosition = verticalOffset;
  }

  onMenuMobileChange($event: Event) {
    const element = $event.target as HTMLInputElement;
    this.isMobileMenuOpen.set(element.checked);
  }

  onConnected(connected: boolean) {
    this.connected.set(connected);
  }
}
