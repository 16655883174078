import { Injectable } from '@angular/core';

import { LogLevelEnum } from './log-level.enum';

export interface LoggerMessage {
  level: LogLevelEnum;
  message: string;
  data?: any;
}

@Injectable()
export abstract class LoggerService {
  abstract send(messages: Array<LoggerMessage>): void;
  abstract log(level: LogLevelEnum, message: string, data?: any): void;

  abstract error(message: string, data?: any): void;
  abstract warn(message: string, data?: any): void;
  abstract info(message: string, data?: any): void;
  abstract debug(message: string, data?: any): void;
}
