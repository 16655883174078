import { Component } from '@angular/core';

@Component({
  selector: 'nscf-pre-footer',
  standalone: true,
  imports: [],
  templateUrl: './pre-footer.component.html',
  styleUrls: ['./pre-footer.component.scss'],
})
export class PreFooterComponent {
}
