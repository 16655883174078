@if (bigViewPort && data.mediaCover) {
  <img
    fetchPriority="high"
    [attr.width]="imageWidth"
    [src]="data.mediaCover.url"
    class="tw-absolute tw-top-0 smd:tw-max-h-[calc(100%+80px+52px)] tw-max-w-[45%] smd:tw-mt-[-80px] tw-right-0 tw-bottom-0 tw-object-cover"
  />
}
<div class="container">
  <div class="tw-relative smd:tw-w-[50%]">
    <nscf-title class="title" selector="h1" [title]="data.title" [id]="'anchor_' + data.id" />
    @if (data.enableTrustPilot) {
      <nscf-trustpilot-micro-star class="tw-inline-block tw-mb-[24px] tw-max-w-[273px]" />
    }
    <nscf-rte class="tw-block tw-mb-[48px]" [data]="!bigViewPort && data.descriptionMobile ? data.descriptionMobile : data.description" />
    @if (data.ctaUrl) {
      <nscf-cta-button centered class="cta-button tw-w-auto tw-mb-[32px]" [text]="data.buttonText" [ctaUrl]="data.ctaUrl" />
    }
    @if (data.termsTitle) {
      <p class="tw-text-xs tw-font-300 tw-text-center tw-mt-[-24px] tw-mb-[24px]">{{ data.termsTitle }}</p>
    }
    @if (data.textUnderCta) {
      <nscf-rte class="tw-mb-[12px]" [data]="data.textUnderCta" />
    }
  </div>
  @if (data.cardsArguments) {
    <nscf-cards class="tw-relative tw-pt-[24px]" [data]="data.cardsArguments" />
  }
</div>
