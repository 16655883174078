<div class="container tw-text-white">
  <div class="tw-py-[64px] slg:tw-py-[220px] slg:tw-max-w-max smd:tw-max-w-[400px] md:tw-max-w-[550px] tw-mx-auto slg:tw-mx-0">
    <h1 class="tw-text-3xl smd:tw-text-4xl xl:tw-text-5xl tw-font-700 tw-mb-6 smd:tw-mb-8" data-test="top-page-categorie__title">
      {{ data.title }}
    </h1>
    <span class="tw-block tw-w-[60px] slg:tw-w-[100px] tw-h-[8px] slg:tw-h-[12px] tw-bg-secondary-color tw-mb-8"></span>
    <nscf-rte class="tw-font-300 tw-text-lg" [data]="data.description" />
    @if (data.ctaUrl && data.ctaLabel) {
      <nscf-cta-button centered class="cta-button tw-w-full" [text]="data.ctaLabel" [ctaUrl]="data.ctaUrl" />
    }
  </div>
</div>
@if (isMobileOrTablet() === false) {
  <div class="tw-absolute tw-top-0 tw-bottom-0 tw-py-[160px] tw-right-0 tw-max-h-full tw-rounded-tl-xl tw-rounded-bl-xl">
    <img
      [src]="data.image?.url"
      [attr.width]="imageWidth"
      fetchPriority="high"
      [alt]="data.image?.alt"
      class="tw-h-full tw-object-cover slg:tw-rounded-l-md"
    />
  </div>
}
