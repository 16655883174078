import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IArticleMetaInfo } from '../../../interfaces/article-meta-info';
import { FrenchFullDatePipe } from '../../../pipes/french-full-date/french-full-date.pipe';
import { ReadingTimeComponent } from '../reading-time/reading-time.component';

@Component({
  selector: 'nscf-meta-info',
  standalone: true,
  templateUrl: './meta-info.component.html',
  styleUrls: ['./meta-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReadingTimeComponent, FrenchFullDatePipe, NgTemplateOutlet],
})
export class MetaInfoComponent {
  @Input({ required: true }) data: IArticleMetaInfo;
  @Input() shouldDisplayDates: boolean;
  protected readonly imgSrcFallback = 'assets/author-editorial-effy.svg';
}
