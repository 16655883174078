import { TypeNameBlockCtaStickyWithQuestions } from '../../../components/cta-sticky-with-questions/cta-sticky-with-questions';
import { TypeNameBlockCtaSticky } from '../../../components/cta-sticky/cta-sticky';
import { IBlockPresenter } from '../../presenter';

export enum ParagraphCtaStickyColorBO {
  Green = 'vert',
  Blue = 'blue',
}

export interface CtaStickyBaseBO {
  cta: {
    uri: string;
    title: string;
  };
  mentions: string | null;
  title: string | null;
  enableTrustPilot: boolean;
}

export interface IParagraphCtaStickyBO extends CtaStickyBaseBO {
  __typename: 'ParagraphBlocCtaSticky';
  id: number;
  barre_1: boolean;
  barre_2: boolean;
  barre_3: boolean;
  text_1: string | null;
  text_2: string | null;
  text_3: string | null;
  color: ParagraphCtaStickyColorBO;
}

export interface IParagraphCtaStickyWithQuestionsBO extends CtaStickyBaseBO {
  __typename: 'ParagraphCtaStickyInteractif';
}

interface IParagraphCtaStickyText {
  value: string;
  strikethrough: boolean;
}

export enum IParagraphCtaStickyColor {
  Green = 'green',
  Blue = 'blue',
}

export interface IParagraphCtaStickyBase {
  title: string;
  cta: {
    uri: string;
    title: string;
  };
  mentions: string;
  enableTrustPilot: boolean;
  shouldDisplayCtaWithQuestions: boolean;
}

export interface IParagraphCtaSticky extends IParagraphCtaStickyBase {
  __typename: 'ParagraphBlocCtaSticky';
  text1: IParagraphCtaStickyText;
  text2: IParagraphCtaStickyText;
  text3: IParagraphCtaStickyText;
  color: IParagraphCtaStickyColor;
}

export interface IParagraphCtaStickyWithQuestions extends IParagraphCtaStickyBase {
  __typename: 'ParagraphCtaStickyInteractif';
}

export type ParagraphCtaStickyType = IParagraphCtaSticky | IParagraphCtaStickyWithQuestions;

export class BlockCtaStickyPresenter implements IBlockPresenter {
  parse(data: IParagraphCtaStickyBO | IParagraphCtaStickyWithQuestionsBO): ParagraphCtaStickyType {
    if (!data) {
      return null;
    }

    const baseFields: CtaStickyBaseBO = {
      title: data.title ?? '',
      cta: data.cta,
      mentions: data.mentions,
      enableTrustPilot: data.enableTrustPilot,
    };

    if (data.__typename === TypeNameBlockCtaSticky) {
      return {
        __typename: TypeNameBlockCtaSticky,
        ...baseFields,
        text1: { value: data.text_1, strikethrough: data.barre_1 },
        text2: { value: data.text_2, strikethrough: data.barre_2 },
        text3: { value: data.text_3, strikethrough: data.barre_3 },
        color: data.color === ParagraphCtaStickyColorBO.Green ? IParagraphCtaStickyColor.Green : IParagraphCtaStickyColor.Blue,
        shouldDisplayCtaWithQuestions: false,
      };
    } else {
      return {
        __typename: TypeNameBlockCtaStickyWithQuestions,
        ...baseFields,
        shouldDisplayCtaWithQuestions: true,
      };
    }
  }
}
