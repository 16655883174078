import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2, inject } from '@angular/core';
import { WebsiteThemes } from '@effy-tech/acquisition-core/shared';
import { take } from 'rxjs';
import { IBreadCrumbItem } from '../../components/breadcrumb/breadcrumb';
import { IFaqContent } from '../../components/faq/faq';
import { TrustpilotRates } from '../../interfaces';
import { ISummary, ISummaryElement } from '../../interfaces/summary';
import { AggregateRatingUtil } from '../../utils/aggregate-rating/aggregate-rating.utils';
import { DeviceManagerService } from '../device-manager/device-manager.service';
import { TrustpilotRatesService } from '../trustpilot-rates/trustpilot-rates.service';
import { IPublisher, IPublisherLogo, IRating, IWebPage, SchemaTypes } from './rich-results.values';

type IDataType = IFaqContent[] | IBreadCrumbItem[] | ISummary | IWebPage | IRating | null;

interface Schema {
  '@context': string;
  '@type': SchemaTypes;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class RichResultsService {
  private readonly trustpilotRatesService = inject(TrustpilotRatesService);
  private readonly renderer: Renderer2;
  private readonly platformId = inject(PLATFORM_ID);

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly deviceManagerService: DeviceManagerService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setJsonLd(type: SchemaTypes, data: IDataType): void {
    if (isPlatformServer(this.platformId)) {
      const schema = this.getSchema(type, data);
      this.setTagJsonLd(type, schema);
    }
  }

  setTrustpilotRatesJsonLd(nameWebSite: string): void {
    if (isPlatformServer(this.platformId)) {
      this.trustpilotRatesService
        .getTrustpilotRates()
        .pipe(take(1))
        .subscribe((trustpilotRates: TrustpilotRates) => {
          const rates = AggregateRatingUtil.mapTrustpilotRatesToAggregateRatings(trustpilotRates, nameWebSite);
          this.setJsonLd(SchemaTypes.SOFTWARE_APPLICATION, rates);
        });
    }
  }

  private getSchema(type: SchemaTypes, data: IDataType) {
    let schema: Schema = {
      '@context': 'https://schema.org',
      '@type': type,
    };
    switch (type) {
      case SchemaTypes.FAQ_PAGE:
        schema['mainEntity'] = this.getQuestionsAnswers(data as IFaqContent[]);
        break;
      case SchemaTypes.BREADCRUMB_LIST:
        schema['itemListElement'] = this.getBreadcrumbList(data as IBreadCrumbItem[]);
        break;
      case SchemaTypes.ITEM_LIST:
        const summaryData = data as ISummary;
        schema = {
          ...schema,
          itemListElement: this.getSummaryList(summaryData.elements),
          numberOfItems: summaryData.elements.length,
          itemListOrder: 'https://schema.org/ItemListOrderDescending',
          name: 'Table Of Content',
          url: summaryData.url,
        };
        break;
      case SchemaTypes.SOFTWARE_APPLICATION:
        const { nameWebSite, ...rating } = data as IRating;
        schema = {
          ...schema,
          name: nameWebSite,
          operatingSystem: 'Web-based',
          applicationCategory: 'Energy',
          aggregateRating: this.getAggregateRating(rating),
          offers: {
            '@type': SchemaTypes.OFFER,
            price: 0.0,
            priceCurrency: 'EUR',
          },
        };
        break;
      case SchemaTypes.ORGANIZATION:
        schema = {
          ...schema,
          name: 'Effy',
          logo: 'https://bo.effy.fr/sites/default/files/2019-10/Effy_logo_blue.svg',
          url: 'https://www.effy.fr/',
          description:
            "Depuis 15 ans, Effy accompagne les particuliers de A à Z dans leur projet de rénovation énergétique. Analyse des besoins, recommandations adaptées à votre logement, réalisation des travaux par des artisans sélectionnés avec soin, obtention d'aides et accompagnement... Effy facilite vos travaux grâce à une équipe de conseillers à votre écoute et de plus de 3 600 partenaires Effy qualifiés. Chaque année, c'est plus de 100 000 familles accompagnées dans leurs travaux !",
          address: {
            '@type': SchemaTypes.POSTAL_ADDRESS,
            addressCountry: 'France',
            addressLocality: 'Paris',
            addressRegion: 'Île-de-France',
            postalCode: '75015',
            streetAddress: '33 avenue du Maine',
          },
          telephone: '3456',
        };
        break;
      case SchemaTypes.WEB_PAGE:
        const dataObject = data as IWebPage;
        const publisher = this.getPublisher(dataObject.site);
        schema = {
          ...schema,
          name: dataObject.name,
          headline: dataObject.headling,
          url: dataObject.url,
          ...publisher,
          ...(dataObject.author && !dataObject.author.isEditorialTeam
            ? {
                author: {
                  '@type': SchemaTypes.PERSON,
                  name: dataObject.author.name,
                  description: dataObject.author.description,
                  image: {
                    '@type': SchemaTypes.IMAGE_OBJECT,
                    url: dataObject.author.imageUrl,
                    height: 65,
                    width: 65,
                  },
                  jobTitle: 'Expert(e) en rénovation énergétique',
                  sameAs: dataObject.author.sameAs,
                },
              }
            : {}),
        };
      default:
        break;
    }
    return schema;
  }

  private getPublisher(site: WebsiteThemes) {
    const publisher = this.getPublisherObject(site);

    return {
      publisher: {
        '@type': SchemaTypes.ORGANIZATION,
        name: publisher.name,
        logo: {
          '@type': SchemaTypes.IMAGE_OBJECT,
          url: publisher.logo.url,
          height: publisher.logo.height,
          width: publisher.logo.width,
        },
      },
    };
  }

  private getPublisherObject(site: WebsiteThemes): IPublisher {
    return {
      name: this.getPublisherName(site),
      logo: this.getPublisherLogo(site),
    };
  }

  private getPublisherName(site: WebsiteThemes): string {
    const publisherName: { [key in WebsiteThemes]: string } = {
      [WebsiteThemes.effy]: 'Effy',
      [WebsiteThemes.calculeo]: 'Calculeo',
      [WebsiteThemes.lpe]: 'LaPrimeRenov',
      [WebsiteThemes.qe]: 'QuelleEnergie',
    };
    return publisherName[site];
  }

  private getPublisherLogo(site: WebsiteThemes): IPublisherLogo {
    const publisherLogoUrl: { [key in WebsiteThemes]: string } = {
      [WebsiteThemes.effy]: 'https://bo.rct.effy.quelleenergie.net/sites/default/files/2019-10/Effy_logo_blue.svg',
      [WebsiteThemes.calculeo]: 'https://bo.calculeo.fr/sites/default/files/2020-10/logo_calculeo.svg',
      [WebsiteThemes.lpe]: 'https://bo.laprimeenergie.fr/sites/default/files/2020-10/logo_lpe.svg',
      [WebsiteThemes.qe]: 'https://bo.quelleenergie.fr/sites/default/files/2021-08/logo-qe-.svg',
    };

    const publisherLogoWidth: { [key in WebsiteThemes]: number } = {
      [WebsiteThemes.effy]: 71.03,
      [WebsiteThemes.calculeo]: 101.94,
      [WebsiteThemes.lpe]: 120,
      [WebsiteThemes.qe]: 113.27,
    };

    const publisherLogoHeight: { [key in WebsiteThemes]: number } = {
      [WebsiteThemes.effy]: 45,
      [WebsiteThemes.calculeo]: 45,
      [WebsiteThemes.lpe]: 50.31,
      [WebsiteThemes.qe]: 45,
    };

    return {
      url: publisherLogoUrl[site],
      height: publisherLogoHeight[site],
      width: publisherLogoWidth[site],
    };
  }

  /**
   * Set JSON-LD Microdata on the Document Body.
   * @param type
   * @param data                  The data for the JSON-LD script
   * @returns                     Void
   */
  private setTagJsonLd(type: SchemaTypes, data: any): void {
    const idTag = type + 'Id';
    const richResult = this.document?.getElementById(idTag);
    const script = this.renderer.createElement('script');
    script.setAttribute('id', idTag);
    script.type = 'application/ld+json';
    script.text = `${JSON.stringify(data)}`;
    if (!this.document?.contains(richResult)) {
      this.renderer.appendChild(this.document?.head, script);
    }
  }

  private getQuestionsAnswers(content) {
    return content.reduce((accumulator, currentValue) => {
      accumulator.push({
        '@type': 'Question',
        name: currentValue.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: currentValue.answer.value,
        },
      });
      return accumulator;
    }, []);
  }

  private getSummaryList(list: ISummaryElement[]) {
    if (!list) {
      return [];
    }

    const baseUrl = this.deviceManagerService.getFullUrlSSr();

    return list.map((item, index) => ({
      '@type': SchemaTypes.LIST_ITEM,
      position: index + 1,
      name: item.title,
      url: `${baseUrl}#${item.url}`,
    }));
  }

  private getBreadcrumbList(list: IBreadCrumbItem[]) {
    if (!list) {
      return [];
    }

    const firstUrl = this.deviceManagerService.getBaseUrlSSr();
    const lastUrl = this.deviceManagerService.getFullUrlSSr();

    return list
      .filter(item => item.url)
      .map((item, index) => ({
        '@type': SchemaTypes.LIST_ITEM,
        position: index + 1,
        name: item.label,
        item: list.length - 1 === index ? lastUrl : index === 0 ? firstUrl : item.url,
      }));
  }

  private getAggregateRating(rating: IRating) {
    return {
      '@type': SchemaTypes.AGGREGATE_RATING,
      ...rating,
    };
  }
}
