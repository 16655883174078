@if (articles$ | async; as articles) {
  @if (articles?.length) {
    <p class="magazine-block__title">Les articles les + récents</p>
    <div class="magazine-block__cards-container">
      @for (article of articles; track article) {
        <nscf-article-card-small
          [url]="article.url"
          [img]="article.mediaCover"
          [imgAlt]="'image de l\'article'"
          [title]="article.title"
          [taxonomies]="article.taxonomies"
        ></nscf-article-card-small>
      }
    </div>
    <a effy-link [size]="linkSize.MEDIUM" class="magazine-block__to-magazine" [href]="urlMagazine"
      >Le magazine de la rénovation
      @defer (on viewport) {
        <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
      } @placeholder {
        <span>></span>
      }
    </a>
  }
}
