import { MetaTagEntities } from '../../interfaces/pages';
import { UrlUtils } from '../url/url.utils';

export class PageArticleUtils {
  static shouldDisplayEffyAdsBasedOnPageUrl(pathname: string, metaTags: MetaTagEntities): boolean {
    if (UrlUtils.isPreviewUrl(pathname)) {
      pathname = UrlUtils.getPathnameInPreview(metaTags);
    }

    const keywords = ['pro', 'magazine', 'flash-info', 'communique-presse'];
    for (let i = 0; i < keywords.length; i++) {
      if (pathname.startsWith(`/${keywords[i]}/`) || pathname === `/${keywords[i]}`) return false;
    }

    return true;
  }
}
