import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BasicThemedComponentDirective } from '@effy-tech/acquisition-core/shared';
import { SafeResourceUrlPipe } from '../../pipes/safe-resource-url/safe-resource-url.pipe';
import { LiseretComponent } from '../liseret/liseret.component';
import { IChapter } from './chapter';

@Component({
  selector: 'nscf-guide-chapter',
  templateUrl: 'chapter.component.html',
  styleUrls: ['chapter.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, LiseretComponent, NgFor, SafeResourceUrlPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideChapterComponent extends BasicThemedComponentDirective implements OnInit {
  @Input() chapter: IChapter;
  isSvg = false;

  constructor() {
    super();
  }

  ngOnInit() {
    const filename = this.chapter && this.chapter.cover ? this.chapter.cover.url : '';
    this.isSvg = filename.split('.').pop().toLowerCase() === 'svg';
  }
}
