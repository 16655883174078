import { MetaTagEntities } from '../../interfaces/pages';

export class UrlUtils {
  static getPathnameInPreview(metaTags: MetaTagEntities): string {
    try {
      const url = metaTags?.find(el => el.name === 'og:url')?.content;
      return new URL(url).pathname;
    } catch {
      return '';
    }
  }

  static isPreviewUrl(pathname: string): boolean {
    return pathname.startsWith('/previewbo');
  }

  static generateUrl(baseUri: string, params: Record<string, unknown>): string | null {
    try {
      const url = new URL(baseUri);
      Object.entries(params).forEach(([key, value]) => url.searchParams.append(key, value.toString()));
      return url.toString();
    } catch {
      return null;
    }
  }
}
