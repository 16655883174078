@if (question) {
  @if (question.title) {
    <h3 class="form-parcours-question__title">{{ question.title }}</h3>
  }
  <div class="form-parcours-question__form" [class.form-parcours-question__form--bigger-gap]="question.hasBiggerGap">
    @for (option of question.options; track option; let index = $index) {
      <div class="form-parcours-question__item">
        <label
          class="form-parcours-question__label"
          [ngClass]="{
            'form-parcours-question__label--without-icon': !option.iconUrl,
            'form-parcours-question__label--is-active': option.key === question.answer,
          }"
          [for]="option.key"
        >
          @if (option.iconUrl) {
            <img class="form-parcours-question__option-icon" [src]="'../../../../assets/icons/' + warm + option.iconUrl" alt="" />
          }
          <span
            class="form-parcours-question__option-text"
            [class.form-parcours-question__option-text--small]="question.isSmallOptinText"
            [innerHTML]="option.label"
          ></span>
          <input
            type="radio"
            [id]="option.key"
            [value]="option.key"
            [name]="option.key"
            [(ngModel)]="question.answer"
            [ngModelOptions]="{ standalone: true }"
            (change)="onChange(question)"
          />
        </label>
      </div>
    }
  </div>
}
