@if (data()) {
  @if (data().title) {
    <h3 class="cta-sticky__title">{{ data().title }}</h3>
  }
  <p class="cta-sticky__questions">Vos travaux concernent :</p>
  <nscf-form-parcours-question [question]="firstQuestion" isWarm="true" (selectAnswer)="onHousingTypeChange($any($event.answer))" />
  <p class="cta-sticky__questions">Votre logement a été construit :</p>
  <mat-form-field nscfLazyLoadMaterial class="form-field" appearance="outline">
    <mat-select [(value)]="defaultHousingAge" (selectionChange)="onHousingAgeChange($event.value)">
      @for (option of housingAges; track option; let last = $last) {
        <mat-option [value]="option.value">{{ option.label }}</mat-option>
        @if (!last) {
          <mat-divider class="tw-w-11/12 mx-auto"></mat-divider>
        }
      }
    </mat-select>
  </mat-form-field>

  <nscf-cta-button
    class="cta-sticky__button"
    [text]="data()?.cta?.title"
    [ctaUrl]="uri()"
    [withArrow]="true"
    [fullWidth]="true"
  ></nscf-cta-button>

  @if (data().mentions) {
    <span class="tw-text-tofu-white tw-text-xs tw-font-600 tw-mt-[-8px] tw-block">{{ data().mentions }}</span>
  }
}
