import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { AuthorContactComponent } from '../../components/author-contact/author-contact.component';
import { IBreadCrumbName } from '../../components/breadcrumb/breadcrumb';
import { BreadcrumbComponent } from '../../components/breadcrumb/breadcrumb.component';
import { RteComponent } from '../../components/rte/rte.component';
import { IThemesHub } from '../../components/themes-hub/themes-hub';
import { ThemesHubComponent } from '../../components/themes-hub/themes-hub.component';
import { TypeNameBlockHub } from '../../interfaces/cards-summary';
import { DynamicComponentData } from '../../interfaces/dynamic-types';
import { IPageHub } from '../../interfaces/pages';
import { CardificationService } from '../../services/cardification/cardification.service';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { LoggerService } from '../../services/loggers';
import { DynamicPageDirective } from '../dynamic-page';

@Component({
    selector: 'nscf-hub-page',
    templateUrl: 'hub.component.html',
    styleUrls: ['hub.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, AuthorContactComponent, RteComponent, AsyncPipe]
})
export class HubPageComponent extends DynamicPageDirective implements OnInit, OnDestroy {
  @Input() data: IPageHub;

  @ViewChild('topContent', { read: ViewContainerRef, static: true })
  topContent: ViewContainerRef;
  @ViewChild('breadcrumbContent', { read: ViewContainerRef, static: true })
  breadcrumbContent: ViewContainerRef;
  @ViewChild('mainContent', { read: ViewContainerRef, static: true })
  mainContent: ViewContainerRef;
  @ViewChild('bottomContent', { read: ViewContainerRef, static: true })
  bottomContent: ViewContainerRef;
  @ViewChild('beforeFooterContent', { read: ViewContainerRef, static: true })
  beforeFooterContent: ViewContainerRef;
  showBlocDescriptionSeo$: Observable<boolean>;

  constructor(
    protected readonly logger: LoggerService,
    protected readonly deviceManagerService: DeviceManagerService,
    private readonly cardificationService: CardificationService,
  ) {
    super(logger, deviceManagerService);
  }

  ngOnInit() {
    this.getDeviceType().subscribe();
    this.showBlocDescriptionSeo$ = this.showDescriptionBloc();
  }

  buildPage(): void {
    // clear page
    this.clearPage();

    // load BreadcrumbContent
    const breadcrumbContent = this.deviceManagerService.isDesktop() ? this.breadcrumbContent : this.beforeFooterContent;
    super.appendDynamicComponentData(breadcrumbContent, this.getBreadcrumbContent());
    // Top contents
    const topDynamicComponentsData: DynamicComponentData[] = [];
    super.appendDynamicComponentData(this.topContent, topDynamicComponentsData);
    const themeContent: IThemesHub = {
      title: this.data.title,
      chapo: this.data.chapo,
      themeId: this.data.themeId,
      blocks: this.data.blocks,
      promoted: this.data.promoted,
    };
    const mainDynamicComponentsData: DynamicComponentData[] = [
      {
        type: ThemesHubComponent,
        content: themeContent,
        typename: TypeNameBlockHub,
      },
    ];

    // then append them to the ViewContainerRef
    super.appendDynamicComponentData(this.mainContent, mainDynamicComponentsData);
  }

  clearPage(): void {
    this.topContent.clear();
    this.breadcrumbContent.clear();
    this.mainContent.clear();
    this.bottomContent.clear();
    this.beforeFooterContent.clear();
  }

  ngOnDestroy() {
    this.doUnsubscribe();
  }

  private showDescriptionBloc(): Observable<boolean> {
    return combineLatest([this.cardificationService.isFirstPage$, this.cardificationService.isLastPage$]).pipe(
      map(([isFirstPage, isLastPage]) => {
        return isFirstPage === isLastPage || (this.deviceManagerService.isBrowser() ? isLastPage : isFirstPage);
      }),
      delay(0),
    );
  }

  private getBreadcrumbContent(): DynamicComponentData[] {
    return [
      {
        type: BreadcrumbComponent,
        content: {
          ...this.data.breadcrumb,
        },
        typename: IBreadCrumbName,
      },
    ];
  }
}
