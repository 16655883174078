import { AsyncPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild, inject } from '@angular/core';
import { BehaviorSubject, combineLatest, map, takeWhile } from 'rxjs';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';

@Component({
  selector: 'nscf-top-page-mosaic-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, SlicePipe, AsyncPipe],
})
export class TopPageMosaicCarouselComponent implements AfterViewInit {
  @Input({ required: true }) imagesSrc: string[];
  @Input({ required: true }) isScrollable: boolean;
  @ViewChild('carouselWrapper') carouselWrapper: ElementRef;
  @ViewChild('carousel') carousel: ElementRef;
  @Output() openCarouselOnImageIndex = new EventEmitter<number>();
  currentPhotoIndex = 0;
  private readonly deviceManagerService = inject(DeviceManagerService);
  private readonly isTablet$ = this.deviceManagerService.isAtLeastTabletWidth();
  private readonly wantToSlide$ = new BehaviorSubject(false);
  protected readonly sliceEnd$ = combineLatest([this.isTablet$, this.wantToSlide$]).pipe(
    takeWhile(([_, wantToSlide]) => !wantToSlide, true),
    map(([isDesktop, wantToSlide]) => (isDesktop || wantToSlide ? undefined : 2))
  );

  private startX = 0;
  private currentX = 0;
  private carouselWidth = 0;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.carouselWidth = this.carouselWrapper.nativeElement.clientWidth;
  }

  onTouchStart(event: TouchEvent) {
    this.wantToSlide$.next(true);
    if (this.isScrollable) {
      this.startX = event.touches[0].clientX;
    }
  }

  onTouchMove(event: TouchEvent) {
    this.wantToSlide$.next(true);
    if (this.isScrollable) {
      event.preventDefault();
      this.currentX = event.touches[0].clientX;
      const dx = this.currentX - this.startX;
      const translateX = -this.currentPhotoIndex * this.carouselWidth + dx;
      this.renderer.setStyle(this.carousel.nativeElement, 'transform', `translateX(${translateX}px)`);
      this.renderer.setStyle(this.carousel.nativeElement, 'border-radius', `8px`);
    }
  }

  onTouchEnd() {
    const dx = this.currentX - this.startX;
    if (Math.abs(dx) > this.carouselWidth / 3) {
      if (dx > 0 && this.currentPhotoIndex > 0) {
        this.currentPhotoIndex--;
      } else if (dx < 0 && this.currentPhotoIndex < this.imagesSrc.length - 1) {
        this.currentPhotoIndex++;
      }
    }
    const translateX = -this.currentPhotoIndex * this.carouselWidth;
    this.renderer.setStyle(this.carousel.nativeElement, 'transform', `translateX(${translateX}px)`);
    this.renderer.setStyle(this.carousel.nativeElement, 'border-radius', `8px`);
  }
}
