<div class="text-table">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 scrollablecontainer">
      <nscf-title
        [id]="'anchor_' + element.id"
        [selector]="element.isFirstTitle ? 'h1' : 'h2'"
        [title]="element.title"
        class="block-title text-center"
      ></nscf-title>
      <div class="text-tableau__table">
        <nscf-rte class="text-tableau__table--rte" [data]="element.text" />
      </div>
      @if (element.ctaLabel) {
        <nscf-cta-button class="tw-mt-4" [ctaUrl]="element.ctaUrl" [text]="element.ctaLabel" />
      }
    </div>
  </div>
</div>
