import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { TruncatePipe } from '../../../pipes/truncate/truncate.pipe';
import { IBreadCrumbItem } from '../breadcrumb';

@Component({
  selector: 'nscf-dropdown',
  templateUrl: 'dropdown.component.html',
  styleUrls: ['dropdown.component.scss'],
  standalone: true,
  imports: [TruncatePipe],
})
export class DropdownComponent {
  @Input() links: IBreadCrumbItem[];
  text: String;
  isVisible = false;

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    this.isVisible = this.eRef.nativeElement.contains(event.target) ? !this.isVisible : false;
  }

  constructor(private readonly eRef: ElementRef) {}
}
