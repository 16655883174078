<div class="container p-0">
  <div class="cardification">
    <div class="cardification__filters_wrapper">
      <ng-container *ngIf="isBigDesktop && filters.length > 1">
        <mat-tab-group (selectedTabChange)="changeFilterTab($event.tab.textLabel)" mat-align-tabs="start" mat-stretch-tabs="false">
          @for (filter of filters; track $index) {
            <mat-tab [label]="filter.title"></mat-tab>
          }
        </mat-tab-group>
      </ng-container>
      <div *ngIf="isBrowser && !isBigDesktop && filters.length > 1" class="cardification__select">
        <mat-form-field appearance="outline">
          <mat-select [value]="filters[0].id" (selectionChange)="changeFilterSelect($event.value)">
            @for (filter of filters; track $index; let last = $last) {
              <ng-container>
                <mat-option [value]="filter.id">{{ filter.title }}</mat-option>
                @if (!last) {
                  <mat-divider class="tw-w-11/12 mx-auto"></mat-divider>
                }
              </ng-container>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div *ngIf="animate" [@fadeInCardsAnimation]="'in'">
      <div class="cardification__wrapper-cards">
        <div class="cardification__wrapper-card" *ngFor="let card of visibleCards; index as stepNumber">
          <nscf-medias-card [stepNumber]="stepNumber" [slide]="card"></nscf-medias-card>
        </div>
      </div>
      <div
        class="cardification__cta-button"
        *ngIf="currentFilter.id === 'tous' && cards.length > pageSize && visibleCards.length < cards.length"
      >
        <nscf-cta-button [redirection]="false" (click)="loadMore()" [text]="'Voir Plus'" [ctaUrl]="null"></nscf-cta-button>
      </div>
    </div>
  </div>
</div>
