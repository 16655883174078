<ng-container #breadcrumbContent>
  <!-- breadcrumb content zone -->
</ng-container>
<div class="container p-0 hub__wrapper tw-block slg:tw-mt-[80px]">
  <ng-container #topContent>
    <!-- top content zone -->
  </ng-container>
  <div class="hub__container">
    <div class="hub__content col-12">
      <h1 class="hub__content-title tw-text-3xl slg:tw-text-4xl tw-font-700" [ngClass]="data.chapo ? '' : 'without-subtitle'">{{ data?.title }}</h1>
      <nscf-author-contact *ngIf="data.authorContact" [links]="data.authorContact"></nscf-author-contact>
      <div class="hub__main-content">
        <ng-container #mainContent>
          <!-- main content zone -->
        </ng-container>
      </div>
    </div>
  </div>
  <ng-container #bottomContent>
    <!-- bottom content zone -->
  </ng-container>
</div>
<div class="hub__description-seo" *ngIf="data.descriptionSeo && (showBlocDescriptionSeo$ | async)">
  <div class="container">
    <nscf-rte [data]="data.descriptionSeo"></nscf-rte>
  </div>
</div>
<ng-container #beforeFooterContent>
  <!-- cardification content zone -->
</ng-container>
