import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DeviceManagerService } from '../../../services/device-manager/device-manager.service';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { RteComponent } from '../../rte/rte.component';
import { ITopPageCategorie } from './top-page-categorie';

@Component({
  selector: 'nscf-top-page-categorie',
  templateUrl: './top-page-categorie.component.html',
  styleUrls: ['./top-page-categorie.component.scss'],
  standalone: true,
  imports: [RteComponent, CtaButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopPageCategorieComponent {
  @Input()
  data: ITopPageCategorie;

  private readonly deviceManagerService = inject(DeviceManagerService);
  protected isMobileOrTablet = toSignal(this.deviceManagerService.isMobileOrTablet());
  protected imageWidth = window.innerWidth / 2;

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.imageWidth = window.innerWidth / 2;
  }
}
