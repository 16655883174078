<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <nscf-title
      [id]="'anchor_' + element.id"
      [selector]="element.isFirstTitle ? 'h1' : 'h2'"
      [title]="element.title"
      class="block-title text-center"
    ></nscf-title>
    <div class="image-bloc">
      @if (element.image) {
        <img [alt]="element.image.alt" [src]="element.image.url" loading="lazy" class="w-100" />
      }
    </div>
    @if (element.legend) {
      <div class="legend">
        <p>{{ element.legend }}</p>
      </div>
    }
    @if (element.ctaLabel && element.ctaUrl) {
      <nscf-cta-button [ctaUrl]="element.ctaUrl" [text]="element.ctaLabel"> </nscf-cta-button>
    }
  </div>
</div>
