@if (carouselItems?.length) {
  <div class="card-summary__container">
    <p calSummaryScrollAction class="card-summary__title container">Ceci peut aussi vous intéresser</p>
    @if (isDesktop$ | async) {
      <div class="card-summary__wrapper" [ngClass]="{ 'card-summary__wrapper--centered': carouselItems.length < 4 }">
        <owl-carousel-o
          ngSkipHydration
          class="medias-carousel"
          [options]="customOptions"
          (initialized)="getChangedData($event)"
          (translated)="getChangedData($event)"
          (change)="getChangedData($event)"
          (changed)="getChangedData($event)"
          #owlCar
        >
          @for (slide of carouselItems; track slide; let stepNumber = $index) {
            <ng-template carouselSlide [id]="'slide-' + stepNumber">
              <nscf-card-summary-simplified [data]="slide" [id]="stepNumber"></nscf-card-summary-simplified>
            </ng-template>
          }
        </owl-carousel-o>
        <button [hidden]="!hasPrev || !isBrowser" (click)="owlCar.prev()" class="carousel-button carousel-button--prev">
          contenu précédent
        </button>
        <button [hidden]="!hasNext || !isBrowser" (click)="owlCar.next()" class="carousel-button carousel-button--next">
          contenu suivant
        </button>
      </div>
    } @else {
      <div class="container card-summary__mobile-wrapper">
        @for (slide of carouselItems | slice: 0 : 3; track slide) {
          <nscf-article-card-small
            [url]="slide.link?.url"
            [img]="slide.image"
            [imgAlt]="slide.imageAlt"
            [title]="slide.title"
            [taxonomies]="$any(slide.taxonomies)"
          ></nscf-article-card-small>
        }
      </div>
    }
    @defer (on viewport) {
      <effy-e-pattern class="tw-absolute tw-bottom-0 tw-w-full tw-z-[1]" [style]="ePatternStyle" [size]="ePatternSize"></effy-e-pattern>
    } @placeholder {
      <div></div>
    }
  </div>
}
