@if (data) {
  <div class="tw-sticky tw-top-[88px] tw-z-[99] smd:tw-z-[1]">
    @if ({ isMobileOrTablet: isMobileOrTablet$ | async }; as vm) {
      @if (isCtaStickyWithoutQuestions(data)) {
        <div class="price-container" nscfIsInViewport (isInViewport)="isInViewport($event)">
          <h3 class="price-title">{{ data.title }}</h3>
          <div class="price-amount-container">
            @if (data.text1.value) {
              <span class="price-amount-text-1" [class.strikethrough]="data.text1.strikethrough">{{ data.text1.value }}</span>
            }
            @if (data.text2.value) {
              <span class="price-amount-text-2" [class.strikethrough]="data.text2.strikethrough"> {{ data.text2.value }}</span>
            }
            @if (data.text2.value) {
              <span class="price-amount-text-3" [class.strikethrough]="data.text3.strikethrough"> {{ data.text3.value }}</span>
            }
          </div>
          <ng-container *ngTemplateOutlet="buttonAndMentions" />
        </div>
      } @else {
        <nscf-cta-sticky-with-questions
          class="tw-max-w-[350px] tw-mx-auto"
          nscfIsInViewport
          (isInViewport)="isQuestionInViewport($event)"
          [data]="data"
        />
      }

      @if (vm.isMobileOrTablet) {
        @if (
          (!data?.shouldDisplayCtaWithQuestions && !priceContainerIsInViewport) ||
          (data?.shouldDisplayCtaWithQuestions && !questionContainerIsInViewport)
        ) {
          <div class="sticky-mobile" #stickyMobile>
            <ng-container *ngTemplateOutlet="buttonAndMentions" />
          </div>
        }
      }
    }
    @if (data?.enableTrustPilot) {
      <nscf-trustpilot-micro-star class="trustpilot" />
    }
  </div>
}

<ng-template #buttonAndMentions>
  @if (data.cta) {
    <a class="tw-flex tw-justify-between tw-items-center" [attr.href]="data.cta.uri" effy-button>
      <div class="cta-text">
        <span class="cta-label">{{ data.cta.title }}</span>
        @if (data.mentions && isMobileOrTablet$ | async) {
          <span class="tw-text-[var(--cta-sticky-mentions-color)] tw-text-xs tw-font-600 tw-block">{{ data.mentions }}</span>
        }
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 7 14" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.333252 10.3333L3.66659 6.99999L0.333252 3.66666L0.333252 0.333323L6.99992 6.99999L0.333252 13.6667L0.333252 10.3333Z"
          fill="none"
        />
      </svg>
    </a>
  }
  @if (data.mentions && (isMobileOrTablet$ | async) === false) {
    <span class="tw-text-[var(--cta-sticky-mentions-color)] tw-text-xs tw-font-600 tw-mt-3 tw-block">{{ data.mentions }}</span>
  }
</ng-template>
