@if (phoneInsert?.phoneNumber || isRegulatory) {
  <div class="container">
    <div class="phone-block" [ngClass]="{ 'phone-block--regulatory': isRegulatory }">
      <div class="phone-block__phone-part">
        <p class="phone-block__help  {{ isDesktop ? 'phone-block__desktop-color' : 'phone-block__mobile-color' }}">
          {{ phoneInsert?.phoneHook }}
        </p>
        @if (isDesktop) {
          <ng-container [ngTemplateOutlet]="phoneText"></ng-container>
        }
      </div>
      <div class="phone-block__text-part">
        @if (isRegulatory) {
          <nscf-regulatory-phone></nscf-regulatory-phone>
        } @else {
          <a [href]="'tel:' + phoneInsert?.phoneNumber" class="phone-block__phone">
            {{ phoneInsert?.phoneNumber }}
          </a>
        }
      </div>
      @if (!isDesktop) {
        <ng-container [ngTemplateOutlet]="phoneText"></ng-container>
      }
    </div>
  </div>
}

<ng-template #phoneText>
  @if (phoneInsert?.accompanimentPhone?.value) {
    <div
      class="phone-block__text {{ isDesktop ? 'phone-block__desktop-color' : 'phone-block__mobile-color' }}"
      [innerHTML]="phoneInsert.accompanimentPhone.value"
    ></div>
  }
</ng-template>
