import { AsyncPipe, isPlatformBrowser, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, inject, Input, PLATFORM_ID, signal, ViewChild } from '@angular/core';
import { EffyButtonComponent } from '@effy-tech/angular-common';
import { IsInViewportDirective } from '../../directives/is-in-viewport/is-in-viewport.directive';
import {
  IParagraphCtaSticky,
  IParagraphCtaStickyColor,
  ParagraphCtaStickyType,
} from '../../presenters/blocks/cta-sticky/cta-sticky.presenter';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { CtaStickyWithQuestionsComponent } from '../cta-sticky-with-questions/cta-sticky-with-questions.component';
import { TrustpilotMicroStarComponent } from '../trustpilot-micro-star/trustpilot-micro-star.component';
import { TypeNameBlockCtaSticky } from './cta-sticky';

@Component({
  selector: 'nscf-cta-sticky',
  templateUrl: './cta-sticky.component.html',
  styleUrls: ['./cta-sticky.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    IsInViewportDirective,
    NgTemplateOutlet,
    TrustpilotMicroStarComponent,
    CtaStickyWithQuestionsComponent,
    EffyButtonComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaStickyComponent {
  @ViewChild('stickyMobile', { static: false }) stickyMobileRef!: ElementRef<HTMLDivElement>;

  get data(): ParagraphCtaStickyType {
    return this._data;
  }
  @Input() set data(value: ParagraphCtaStickyType) {
    this._data = value;
    if (this.isCtaStickyWithoutQuestions(value)) {
      this.secondary.set(value.color === IParagraphCtaStickyColor.Green);
    }
  }

  private _data: ParagraphCtaStickyType;
  readonly secondary = signal<boolean>(false);
  readonly shouldDisplayCtaWithQuestions = signal(false);
  protected readonly isMobileOrTablet$ = inject(DeviceManagerService).isMobileOrTablet();
  protected readonly isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  @HostBinding('class.secondary') get hostClasses() {
    return this.secondary();
  }

  priceContainerIsInViewport = true;
  questionContainerIsInViewport = true;

  isInViewport(isInViewport: boolean): void {
    this.priceContainerIsInViewport = isInViewport;
    this.exposeStickyHeight(isInViewport)
  }

  isQuestionInViewport(isInViewport: boolean): void {
    this.questionContainerIsInViewport = isInViewport;
    this.exposeStickyHeight(isInViewport)
  }

  exposeStickyHeight(isInViewport: boolean) {
    if (this.isBrowser) {
      if (!isInViewport) {
        requestAnimationFrame(() => {
          const componentHeight = this.stickyMobileRef.nativeElement.offsetHeight;
          document.documentElement.style.setProperty('--cta-sticky-height', `${componentHeight}px`);
        });
      } else {
        document.documentElement.style.setProperty('--cta-sticky-height', `0px`);
      }
    }
  }

  protected isCtaStickyWithoutQuestions(data: ParagraphCtaStickyType): data is IParagraphCtaSticky {
    return data.__typename === TypeNameBlockCtaSticky;
  }
}
