@if (data) {
  <div class="meta-info">
    @if (data.author) {
      <div class="meta-info--author-img">
        <img
          width="32"
          height="32"
          [src]="!!data.author.imgSrc && !data.author.isEditorialTeam ? data.author.imgSrc : imgSrcFallback"
          [alt]="data.author.imgAlt"
          loading="lazy"
        />
      </div>
    }
    <div class="meta-info--text">
      <div class="meta-info--author">
        @if (data.author?.link) {
          <a [href]="data.author.link">
            <ng-container *ngTemplateOutlet="authorTpl"></ng-container>
          </a>
        } @else {
          <p>
            par <span class="meta-info--author-name">{{ data.author?.name ?? "L'équipe de rédaction" }}</span>
          </p>
          <span class="separator meta-info--author-separator"></span>
        }
      </div>
      @if (shouldDisplayDates) {
        @if (data.date; as date) {
          <p class="meta-info--date">
            @if (date.created) {
              <span class="meta-info--date-created">Publié le {{ date.created | frenchFullDate }}</span>
            }
            @if (date.created && date.showChangedDate) {
              <span class="separator"></span>
            }
            @if (date.showChangedDate) {
              <span class="meta-info--date-changed">Mis à jour le {{ date.changed | frenchFullDate }}</span>
            }
          </p>
        }
      }
      @if (data.readingTime) {
        <nscf-reading-time class="meta-info--reading-time" [time]="data.readingTime"></nscf-reading-time>
      }
    </div>
  </div>
}

<ng-template #authorTpl>
  <p>
    par <span class="meta-info--author-name">{{ data.author?.name ?? "L'équipe de rédaction" }}</span>
  </p>
  <span class="separator meta-info--author-separator"></span>
</ng-template>
