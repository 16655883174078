import { IHeader } from '@effy-tech/acquisition-core/header';
import { IBreadCrumbItems, IBreadCrumbName } from '../../components/breadcrumb/breadcrumb';
import { BOTemplate } from '../../enums/bo-template.enum';
import { IPageDefault } from '../../interfaces/pages';
import { PreHeaderPresenter } from '../commons/pre-header.presenter';
import { IPagePresenter } from '../presenter';

export class PageDefaultPresenter implements IPagePresenter {
  defaultPage: IPageDefault;

  parse(data: any): IPageDefault {
    this.defaultPage = {
      __typename: data['__typename'],
      title: data['title'] || '',
      breadcrumb: null,
      template: data['fieldTemplate'] ? data['fieldTemplate'] : BOTemplate.PARTS,
      header: {} as IHeader,
      metatags: data['entityMetatags'] && data['entityMetatags'].length ? data['entityMetatags'] : [],
      actualiteThemeId: data['actualiteThemeId']?.entities[0]?.id,
      preHeader: PreHeaderPresenter.parse(data['fieldPreHeader']),
    };

    // Le fil d'ariane doit être créé après les metatags.
    this.defaultPage.breadcrumb = this.getBreadCrumb(data);
    return this.defaultPage;
  }

  private getBreadCrumb(data: any): IBreadCrumbItems {
    if (this.defaultPage.metatags.find(v => v.name === 'robots' && v.content === 'noindex')) {
      return null;
    }

    return <IBreadCrumbItems>{ items: data['breadcrumb'], __typename: IBreadCrumbName };
  }
}
