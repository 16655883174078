import { TaxonomyPresenter } from '../commons/taxonomy.presenter';
import { ICardBo, ICardsActuBo } from '../../components/categorie/actu-theme';
import { ICardSummary } from '../../interfaces/cards-summary';
import { ImagesService } from '../../services/images-service/images.service';

export class CardificationActuThemePresenter {
  static mapDataToSummaryCards(cardsActuBo: ICardsActuBo): ICardSummary[] {
    return cardsActuBo?.data?.articleActuGraphqlView?.results.map((card: ICardBo) => ({
      title: card?.title,
      description: '',
      imageAlt: card.cover?.entity?.fieldMediaImage?.alt,
      image: card.cover?.entity?.fieldMediaImage?.url ? ImagesService.rewriteImagesUrl(card.cover?.entity?.fieldMediaImage?.url) : '',
      created: new Date(+card.creatinDate * 1000),
      link: {
        url: card?.url.link,
      },
      taxonomies: card?.associatedThemes
        .map(taxonomy => TaxonomyPresenter.getTaxonomy(taxonomy))
        .filter(theme => theme.isVisible)
        .map(theme => theme.nameFront)
        .slice(0, 1),
    }));
  }
  
  static mapCreatedToString(cards: ICardSummary[]): ICardSummary[] {
    return cards.map(card => ({ ...card, created: typeof card.created === 'string' ? card.created : card.created.toLocaleDateString() }))
  }
}
