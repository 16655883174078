<owl-carousel-o
  ngSkipHydration
  class="medias-carousel"
  [options]="customOptions"
  (initialized)="getChangedData($event)"
  (translated)="getChangedData($event)"
  (change)="getChangedData($event)"
  (changed)="getChangedData($event)"
  #owlCar
>
  @for (slide of mediasCarouselItems; track slide; let stepNumber = $index) {
    <ng-template [dataHash]="'slide-' + stepNumber" carouselSlide [dataMerge]="$any('slide-' + stepNumber)" [id]="'slide-' + stepNumber">
      <template [ngTemplateOutlet]="cardVariable" [ngTemplateOutletContext]="{ slide: slide, stepNumber: stepNumber }"></template>
    </ng-template>
  }
</owl-carousel-o>

<button
  [hidden]="!hasPrev"
  [ngClass]="'carousel-button--h_' + cardHeight"
  (click)="owlCar.prev()"
  class="carousel-button carousel-button--prev"
  aria-label="précédent"
>
  @defer (on viewport) {
    <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
  } @placeholder {
    <span class="tw-size-6">précédent</span>
  }
</button>
<button
  [hidden]="!hasNext"
  [ngClass]="'carousel-button--h_' + cardHeight"
  (click)="owlCar.next()"
  class="carousel-button carousel-button--next"
  aria-label="suivant"
>
  @defer (on viewport) {
    <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
  } @placeholder {
    <span class="tw-size-6">suivant</span>
  }
</button>
