import { Injectable } from '@angular/core';

import { LogLevelEnum } from './log-level.enum';
import { LoggerMessage, LoggerService } from './logger.service';

const noop = (): any => undefined;

@Injectable()
export class ConsoleLoggerService extends LoggerService {
  send(messages: Array<LoggerMessage>): void {
    messages.forEach(msg => {
      const logFn: Function = console[msg.level] || console.log || noop;
      logFn.apply(console, [JSON.stringify(msg)]);
    });
  }

  log(level: LogLevelEnum, message: string, data?: any): void {
    this.send([
      {
        level,
        message,
        data,
      },
    ]);
  }

  error(message: string, data?: any): void {
    this.log(LogLevelEnum.error, message, data);
  }

  warn(message: string, data?: any): void {
    this.log(LogLevelEnum.warn, message, data);
  }

  info(message: string, data?: any): void {
    this.log(LogLevelEnum.info, message, data);
  }

  debug(message: string, data?: any): void {
    this.log(LogLevelEnum.debug, message, data);
  }
}
