import { Component, Input } from '@angular/core';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { TitleComponent } from '../../title/title.component';
import { ISubBlockImage } from './images';

@Component({
  selector: 'nscf-images',
  templateUrl: 'images.component.html',
  styleUrls: ['images.component.scss'],
  standalone: true,
  imports: [TitleComponent, CtaButtonComponent],
  host: {
    class: 'container',
  },
})
export class ImagesComponent {
  @Input() element: ISubBlockImage;
}
