<div class="row">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    @if (element.title) {
      <nscf-title
        [id]="'anchor_' + element.id"
        [selector]="element.isFirstTitle ? 'h1' : 'h2'"
        [title]="element.title"
        class="block-title text-center"
      ></nscf-title>
    }
    <!-- Player wrapper / image call -->
    @if (element.video) {
      <div
        (click)="loadMedia($event, stepNumber)"
        [ngClass]="{
          loaded: isMediaLoaded(stepNumber) || !element.cover,
        }"
        class="tw-relative load-video"
      >
        @if (!isMediaLoaded(stepNumber) && element.cover) {
          <nscf-video-play-background />
        }
        <!-- Image call -->
        @if (!isMediaLoaded(stepNumber) || !element.cover) {
          @if (element.cover) {
            <img [alt]="element.cover?.alt || ''" [src]="element.cover.url | safeResourceUrl" loading="lazy" class="w-100" />
          }
        }
        <!-- Iframe media -->
        @if (isMediaLoaded(stepNumber) || !element.cover) {
          @if (element.cover) {
            <iframe
              [id]="'player-' + stepNumber"
              [src]="generateEmbedUrl(element.video, true) | safeResourceUrl"
              [title]="element?.title || ''"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="0"
            ></iframe>
          }
          @if (!element.cover) {
            <iframe
              [id]="'player-' + stepNumber"
              [src]="generateEmbedUrl(element.video) | safeResourceUrl"
              [title]="element?.title || ''"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen="0"
              loading="lazy"
            ></iframe>
          }
        }
      </div>
    }

    @if (element.legend) {
      <div class="legend">
        <p>{{ element.legend }}</p>
      </div>
    }
    @if (element.ctaLabel) {
      <a
        effy-button
        [size]="buttonSize.LARGE"
        [color]="buttonStyle.PRIMARY"
        [attr.href]="element?.ctaUrl?.url"
        [attr.rel]="element?.ctaUrl?.options?.attributes?.rel"
        [attr.target]="element?.ctaUrl?.options?.attributes?.target"
        [attr.id]="element?.ctaUrl?.options?.attributes?.id"
        [ngClass]="element?.ctaUrl?.options?.attributes?.class"
        class="tw-mt-8 tw-mx-auto"
        [innerHTML]="element?.ctaLabel"
      ></a>
    }
  </div>
</div>
