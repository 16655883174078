import { NgClass } from '@angular/common';
import { Component, computed, inject, Input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { RteComponent } from '../rte/rte.component';
import { TitleComponent } from '../title/title.component';
import { BlocNumberSize, IBlockNumber } from './numbers';

@Component({
  selector: 'nscf-numbers',
  templateUrl: 'numbers.component.html',
  styleUrls: ['numbers.component.scss'],
  standalone: true,
  imports: [TitleComponent, RteComponent, NgClass],
})
export class NumbersComponent {
  @Input() data: IBlockNumber;
  readonly isMobileOrTablet = toSignal(inject(DeviceManagerService).isMobileOrTablet());
  readonly size = computed(() => (this.isMobileOrTablet() ? BlocNumberSize.SMALL : BlocNumberSize.MEDIUM));

  constructor() {
  }
}
