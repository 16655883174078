import { NgFor, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { EffyLinkComponent } from '@effy-tech/angular-common';
import { HEADING_QUESTIONS_NB_MAX_QUESTIONS_DISPLAYED, IHeadingQuestionsQuestions } from '../heading-questions';

@Component({
  selector: 'nscf-ui-heading-questions',
  templateUrl: './ui-heading-questions.component.html',
  styleUrls: ['./ui-heading-questions.component.scss'],
  standalone: true,
  imports: [NgFor, SlicePipe, EffyLinkComponent, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    ngSkipHydration: 'true',
  },
})
export class UiHeadingQuestionsComponent {
  title = input<string>(null);
  questions = input<IHeadingQuestionsQuestions>([]);
  protected readonly shouldDisplayBlock = computed(() => this.title() && this.questions()?.length);

  protected readonly nbMaxQuestionsDisplayed = HEADING_QUESTIONS_NB_MAX_QUESTIONS_DISPLAYED;
}
