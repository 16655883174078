import { IBlocTopPageForm, TypeNameBlocTopPageForm } from '../../../components/top-page-parcours/top-page-parcours';
import { ILinkWithPath } from '../../../interfaces/link';
import { Parcours } from '../../../utils/parcours/parcours.utils';
import { FormParcoursPresenter } from '../../commons/form-parcours.presenter';
import { MediaImagePresenter } from '../../commons/media-image.presenter';
import { RTEPresenter } from '../../commons/rte.presenter';
import { IBlockPresenter } from '../../presenter';
import { IImageBo } from '../top-page-categorie/top-page-categorie';

export enum IParcoursBO {
  Heating = 'heating',
  HelpsAndWorks = 'helpsAndWorks',
  Insulation = 'insulation',
}

export interface IBlocTopPageFormBO {
  __typename: string;
  topPageTitle: string;
  enableTrustPilot: boolean;
  desktopDescription: {
    value: string;
    format: string;
    processed: string;
  };
  mobileDescription: {
    value: string;
    format: string;
    processed: string;
  };
  image: {
    entity: {
      fieldMediaImage: IImageBo;
    };
  };
  parcours: IParcoursBO;
  subFormTitle: string;
  showSecondQuestion: boolean;
  enableCTA: boolean;
  linkCTA: ILinkWithPath | null;
}

export class BlocTopPageParcoursPresenter implements IBlockPresenter {
  parse(data: IBlocTopPageFormBO): IBlocTopPageForm {
    if (!data) {
      return null;
    }
    return {
      __typename: TypeNameBlocTopPageForm,
      title: data.topPageTitle,
      desktopDescription: RTEPresenter.getRTEText(data.desktopDescription),
      mobileDescription: RTEPresenter.getRTEText(data.mobileDescription),
      image: MediaImagePresenter.getImageWebp(data.image),
      parcours: mapParcoursBoToParcours(data.parcours),
      enableTrustPilot: data.enableTrustPilot,
      formParcours: FormParcoursPresenter.parse(data.subFormTitle, data.showSecondQuestion),
      hasTwoQuestions: data.showSecondQuestion,
      cta: data.enableCTA ? { ...data.linkCTA, url: data.linkCTA?.url?.path } : null,
    };
  }
}

export function mapParcoursBoToParcours(parcoursBo: IParcoursBO): Parcours {
  const mappingParcours = {
    [IParcoursBO.Heating]: Parcours.Chauffage,
    [IParcoursBO.HelpsAndWorks]: Parcours.TravauxAides,
    [IParcoursBO.Insulation]: Parcours.Isolation,
  };
  return mappingParcours[parcoursBo];
}
