import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IParagraphInBrief } from '../../presenters/blocks/in-brief/in-brief.presenter';

@Component({
  selector: 'nscf-in-brief',
  templateUrl: './in-brief.component.html',
  styleUrls: ['./in-brief.component.scss'],
  standalone: true,
  imports: [NgIf],
  host: {
    ngSkipHydration: 'true',
  },
})
export class InBriefComponent {
  @Input() data: IParagraphInBrief;
}
