import { Component, Input } from '@angular/core';

import { RegionPipe } from '../../../pipes/region/region.pipe';
import { IMap } from '../../map/map';
import { MapComponent } from '../../map/map.component';

export const TypeNameMapBlock = 'mapBlockComponent';

@Component({
  selector: 'nscf-map-block',
  standalone: true,
  imports: [MapComponent, RegionPipe],
  templateUrl: './map-block.component.html',
  styleUrls: ['./map-block.component.scss'],
})
export class MapBlockComponent {
  @Input() map: IMap;
}
