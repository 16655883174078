/* eslint-disable */
import { AfterViewInit, Directive, ViewChild } from '@angular/core';
import { IFieldMediaImage } from '../../interfaces/field-media-image';
import { ImagesService } from '../../services/images-service/images.service';

@Directive()
export class CoverImageLazyLoadDirective implements AfterViewInit {
  fieldImage: IFieldMediaImage;
  urlCover: any;
  @ViewChild('cover', { static: false }) cover;

  constructor() {}

  ngAfterViewInit(): void {
    try {
      if ('IntersectionObserver' in window && this.cover) {
        const obs = new IntersectionObserver(entries => {
          entries.forEach(({ isIntersecting }) => {
            if (isIntersecting) {
              this.getUrlCover();
              obs.unobserve(this.cover.nativeElement);
            }
          });
        });
        obs.observe(this.cover.nativeElement);
      }
    } catch (e) {}
  }

  setFieldImage(fieldImage: IFieldMediaImage) {
    this.fieldImage = fieldImage;
  }

  getUrlCover(): any {
    this.urlCover = ImagesService.setBackGroundCoverUrl(this.fieldImage);
    return this.urlCover;
  }
}
