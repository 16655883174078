import { ModuleWithProviders, NgModule } from '@angular/core';
import { IHeader } from '@effy-tech/acquisition-core/header';
import { THEME } from '@effy-tech/acquisition-core/shared';
import { WIDGET_CALCULATOR_API_KEY, WIDGET_CALCULATOR_ENV } from '@effy-tech/widget-calculator';
import { ERRORS_DATA, ErrorsData } from '../../components/not-found/error-model';
import { IQueriesId, QUERIES_ID } from '../../graphql/queries-id';
import { EnvironmentModel } from '../../interfaces/environment.interface';
import { ConfigService, ENVIRONMENT_DATA, HEADER_DATA } from './config.service';

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class ConfigModule {
  public static forRoot(
    environments: EnvironmentModel,
    headerData: IHeader,
    errors_data: ErrorsData,
    theme: any,
    queriesId: IQueriesId
  ): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        ConfigService,
        {
          provide: ENVIRONMENT_DATA,
          useValue: environments,
        },
        {
          provide: HEADER_DATA,
          useValue: headerData,
        },
        {
          provide: ERRORS_DATA,
          useValue: errors_data,
        },
        {
          provide: THEME,
          useValue: theme,
        },
        {
          provide: QUERIES_ID,
          useValue: queriesId,
        },
        {
          provide: WIDGET_CALCULATOR_API_KEY,
          useFactory: (environment: EnvironmentModel) => environment.apiKey,
          deps: [ENVIRONMENT_DATA]
        },
        {
          provide: WIDGET_CALCULATOR_ENV,
          useFactory: (environment: EnvironmentModel) => environment.env,
          deps: [ENVIRONMENT_DATA]
        }
      ],
    };
  }
}
