import { Location, NgClass, SlicePipe } from '@angular/common';
import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { forEach } from 'lodash-es';
import { ConfigService } from '../../modules/config/config.service';
import { TruncatePipe } from '../../pipes/truncate/truncate.pipe';
import { DeviceManagerService } from '../../services/device-manager/device-manager.service';
import { RichResultsService } from '../../services/rich-results/rich-results.service';
import { SchemaTypes } from '../../services/rich-results/rich-results.values';
import { IBreadCrumbItem, IBreadCrumbItems } from './breadcrumb';
import { DropdownComponent } from './dropdown/dropdown.component';

@Component({
  selector: 'nscf-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss'],
  standalone: true,
  imports: [NgClass, DropdownComponent, SlicePipe, TruncatePipe, MatIcon],
  host: {
    class: 'container article-breadcrumb tw-flex',
    ngSkipHydration: 'true',
  },
})
export class BreadcrumbComponent implements OnInit {
  private readonly location = inject(Location);
  private readonly deviceManagerService = inject(DeviceManagerService);
  private readonly configService = inject(ConfigService);
  private readonly richResultsService = inject(RichResultsService);
  @Input() data: IBreadCrumbItems;
  @Input() isDesktop: Boolean;
  @Input() items: IBreadCrumbItem[];
  @HostBinding('class.relative-position') relativePosition = false;
  isBrowser: boolean;

  ngOnInit(): void {
    this.relativePosition = this.data.isInArticlePage;
    this.isBrowser = this.deviceManagerService.isBrowser();
    const path = this.location.path();
    if (
      path.split('/').length === 2 &&
      this.getStaticElement()
        .map(data => data.url)
        .indexOf(path.split('/')[1]) > -1
    ) {
      this.items = this.getStaticElement().find(v => v.url === path.split('/')[1]).items;
    } else {
      // compute from Api.
      if (this.data) {
        this.items = this.data.items;
      }
    }
    this.replaceUrl();

    if (this.items) {
      this.richResultsService.setJsonLd(SchemaTypes.BREADCRUMB_LIST, this.items);
    }
  }

  replaceUrl(): void {
    const baseUrl = this.isBrowser ? window.location.origin : this.deviceManagerService.getBaseUrlSSr();
    forEach(this.items, i => {
      if (i.url) {
        i.url = i.url.replace(this.configService.getEnvironment()?.url_base_bo_local, baseUrl + '/');
      }
    });
  }

  private getStaticElement(): IBreadCrumbItems[] {
    return [
      {
        url: 'mentions-legales',
        items: [
          { label: 'Accueil', url: '/' },
          { label: 'Mentions légales', url: null },
        ],
      },
    ];
  }
}
