@if (items && items.length) {
  <div class="col-lg-8">
    <div class="breadcrumb-effy" [ngClass]="{ 'breadcrumb-effy--mobile': !isDesktop }">
      @if (!isDesktop) {
        <p class="breadcrumb-guide">Vous êtes ici :</p>
      }
      <ol class="breadcrumb-list">
        @if (isBrowser) {
          <li class="breadcrumb-list-item">
            <a [href]="items[0].url">{{ items[0].label | truncate: 45 }}</a>
            @defer (on viewport) {
              <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
            } @placeholder {
              <span class="tw-size-6">></span>
            }
          </li>
          @if (items.length > 3) {
            <li class="breadcrumb-list-item">
              <nscf-dropdown [links]="items | slice: 1 : -1" class="bc-drop"></nscf-dropdown>
              <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
            </li>
          }
          @if (items.length > 2) {
            @for (item of items | slice: -2 | slice: (items.length > 3 ? -1 : 0); track item; let index = $index; let lastIndex = $last) {
              <li class="breadcrumb-list-item" [class.active]="lastIndex">
                @if (item.url) {
                  <a [href]="item.url">{{ item.label | truncate: 45 }}</a>
                }
                @if (!item.url) {
                  <a hidden href="/">{{ item.label | truncate: 45 }}</a>
                }
                @if (!item.url) {
                  <span>{{ item.label | truncate: 45 }}</span>
                }
                @if (!lastIndex) {
                  <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
                }
              </li>
            }
          }
          @if (items.length === 2) {
            @for (item of items | slice: -1; track item; let index = $index; let lastIndex = $last) {
              <li class="breadcrumb-list-item" [class.active]="lastIndex">
                @if (item.url) {
                  <a [href]="item.url">{{ item.label | truncate: 45 }}</a>
                }
                @if (!item.url) {
                  <a hidden href="/">{{ item.label | truncate: 45 }}</a>
                }
                @if (!item.url) {
                  <span>{{ item.label | truncate: 45 }}</span>
                }
                @if (!lastIndex) {
                  <mat-icon [svgIcon]="'chevron-right'"></mat-icon>
                }
              </li>
            }
          }
        } @else {
          @for (item of items; track item; let index = $index; let lastIndex = $last) {
            <li class="breadcrumb-list-item" [class.active]="lastIndex">
              @if (item.url) {
                <a [href]="item.url">{{ item.label | truncate: 45 }}</a>
              }
              @if (!item.url) {
                <a hidden href="/">{{ item.label | truncate: 45 }}</a>
              }
              @if (!item.url) {
                <span>{{ item.label | truncate: 45 }}</span>
              }
            </li>
          }
        }
      </ol>
    </div>
  </div>
}
