import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CarouselContainer } from '../../classes/carousel-container/carousel-container';
import { CardsHeight, IMediasCarouselType } from './carousel';

@Component({
  selector: 'nscf-carousel',
  templateUrl: 'carousel.component.html',
  styleUrls: ['carousel.component.scss'],
  standalone: true,
  imports: [CarouselModule, NgTemplateOutlet, NgClass, MatIconModule],
})
export class CarouselComponent extends CarouselContainer implements OnInit {
  @Input() mediasCarouselItems: Array<IMediasCarouselType>;
  @Input() cardHeight: CardsHeight;
  @ContentChild(TemplateRef) cardVariable: TemplateRef<any>;

  ngOnInit(): void {
    this.setCarouselOptions();
    this.setCarouselItems(this.mediasCarouselItems);
  }
}
