import { DestroyRef, Directive, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs/operators';
import { LazyLoadStyleService } from '../../services/lazy-load-style/lazy-load-style.service';

@Directive({
    selector: '[nscfLazyLoadMaterial]',
    standalone: true
})
export class LazyLoadMaterialDirective implements OnInit {
  private readonly lazyLoadStyleService = inject(LazyLoadStyleService);
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.lazyLoadStyleService.loadMaterial$().pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
