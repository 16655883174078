<a class="dropdown__wrapper active" id="bc_dropdown">
  <span> . . . </span>
  <div class="dropdown" [hidden]="!isVisible">
    @for (link of links; track link) {
      <div>
        @if (link.url) {
          <a [href]="link.url">
            <p>{{ link.label | truncate: 45 }}</p>
          </a>
        }
        @if (!link.url) {
          <p>{{ link.label | truncate: 45 }}</p>
        }
      </div>
    }
  </div>
</a>
