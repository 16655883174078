<div class="container tw-flex tw-justify-between smd:tw-justify-center tw-items-center smd:tw-gap-12 tw-text-blue-power">
  @if (data.firstHref || true) {
    <a [href]="data.firstHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
      @defer (on viewport) {
        <mat-icon [svgIcon]="'double-chevron-right'" class="icon rotate-icon"></mat-icon>
      } @placeholder {
        <span>début</span>
      }
    </a>
  }
  @if (data.prevHref) {
    <a [href]="data.prevHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
      @defer (on viewport) {
        <mat-icon [svgIcon]="'chevron-right'" class="icon rotate-icon"></mat-icon>
      } @placeholder {
        <span>précédent</span>
      }
    </a>
  }
  @for (link of data.links; track $index) {
    @if (link.isCurrent) {
      <span class="tw-w-6 tw-text-center tw-text-lg tw-font-600 tw-border-b-[1px] tw-border-blue-power">
        {{ link.label }}
      </span>
    } @else {
      <a [href]="link.href" class="tw-w-6 tw-text-center tw-text-lg tw-font-300 hover:tw-text-blue-power-dark">
        {{ link.label }}
      </a>
    }
  }
  @if (data.nextHref) {
    <a [href]="data.nextHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
      <mat-icon [svgIcon]="'chevron-right'" class="icon"></mat-icon>
    </a>
  }
  @if (data.lastHref) {
    <a [href]="data.lastHref" class="tw-flex tw-w-fit hover:tw-text-blue-power-dark">
      @defer (on viewport) {
        <mat-icon [svgIcon]="'double-chevron-right'" class="icon"></mat-icon>
      } @placeholder {
        <span>fin</span>
      }
    </a>
  }
</div>
